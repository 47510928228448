import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BootstrapTooltip } from '../../../../Commoncomponet/common'
import { UpdateGsmWebConfigDetails, getAll_Active_alarm_template } from '../../../../Commoncomponet/Utils'
import { Switch } from 'antd'

export default function WebConfig(props) {

   const [config, setConfig] = useState({ ALERT_DISABLE: {}, EVC_DATA: { hourlydata: 1 } ,VIRCHUAL_MECHANICAL_NO:'',IS_USER_DEFINE_MC_NUMBER:0})
   const [disable, setDisable] = useState(true)
   const [btnFlag, setbtnFlag] = useState(false)
   const [AlarmList, setAlarmList] = useState(['I2CSTATUS', 'INT_DOOR_STATUS', 'EXT_DOOR_STATUS', 'RTC_DRIFT', 'WAKE_UP_REASON', 'TAMPER'])
   useEffect(() => {
      setDisable(false)
      const disable_alert = JSON.parse(props?.GSMdata?.ALERT_DISABLE || '{"I2CSTATUS":1,"INT_DOOR_STATUS":1,"EXT_DOOR_STATUS":1,"RTC_DRIFT":1,"WAKE_UP_REASON":1,"TAMPER":1}')
      setConfig({VIRCHUAL_MECHANICAL_NO:props?.GSMdata?.VIRCHUAL_MECHANICAL_NO, ALERT_DISABLE: disable_alert?.TAMPER != undefined ? disable_alert : { ...disable_alert, TAMPER: 1 }, EVC_DATA: JSON.parse(props?.GSMdata?.EVC_MC_METER_DATA_EN_DIS || '{"hourlydata":1}') ,IS_USER_DEFINE_MC_NUMBER:props?.GSMdata?.IS_USER_DEFINE_MC_NUMBER})
   }, [])

   const onHandalUpdate = async () => {
      setbtnFlag(true)
      var new_json = {}
      if (props?.GSMdata?.ALERT_DISABLE != JSON.stringify(config?.ALERT_DISABLE)) {
         new_json['ALERT_DISABLE'] = JSON.stringify(config?.ALERT_DISABLE)
      }
      if (props?.GSMdata?.VIRCHUAL_MECHANICAL_NO !=config?.VIRCHUAL_MECHANICAL_NO) {
         new_json['VIRCHUAL_MECHANICAL_NO'] = config?.VIRCHUAL_MECHANICAL_NO
      }
      if (props?.GSMdata?.IS_USER_DEFINE_MC_NUMBER !=config?.IS_USER_DEFINE_MC_NUMBER) {
         new_json['IS_USER_DEFINE_MC_NUMBER'] = config?.IS_USER_DEFINE_MC_NUMBER
      }
      const data = await UpdateGsmWebConfigDetails({ ...config, old_json: JSON.stringify({ ALERT_DISABLE: props?.GSMdata?.ALERT_DISABLE }), new_json: JSON.stringify(new_json) }, props?.GSMdata?.UNIQUE_ID)
      if (data.success === true) {
         toast.success('Successfully Updated Details')
         setDisable(false)
         setbtnFlag(false)
         props.onRefreshData()
      } else {
         toast.error('Something Went Wrong')
         setbtnFlag(false)
      }
   }

   const onHandalswitch = (e, id) => {
      if (e == true) {
         setConfig({ ...config, ALERT_DISABLE: { ...config.ALERT_DISABLE, [id]: 1 } });
      } else {
         setConfig({ ...config, ALERT_DISABLE: { ...config.ALERT_DISABLE, [id]: 0 } });

      }
   }
   const onHandalSwitchEVCData = (e, id) => {
      if (e == true) {
         setConfig({ ...config, EVC_DATA: { ...config.EVC_DATA, [id]: 1,hourly_data_limit:1 } });
      } else {
         setConfig({ ...config, EVC_DATA: { ...config.EVC_DATA, [id]: 0 } });

      }
   }
   return (
      <div>
         <section class="card-diff-section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>Web Configuration</h4>
                           <div class="card-header-action">
                              <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div>
                           </div>
                        </div>
                        <div className='card-body'>
                           <div class="d-flex mt-4">
                              <div className=' ' style={{ fontSize: '15px', width: '15%' }}><b>1] Alerts :- </b></div>
                              <div >
                                 {AlarmList && AlarmList.map((item) => (
                                    <>
                                       <div class='d-flex '>
                                          <Switch size="small" checked={config?.ALERT_DISABLE[item] == 1 ? true : false} onChange={(e) => (onHandalswitch(e, item))} disabled={!disable} />
                                          <h6 class='ml-2'>{item}</h6>
                                       </div>
                                    </>
                                 ))}
                              </div>
                           </div>
                           <div class="d-flex mt-4">
                              <div className=' ' style={{ fontSize: '15px', width: '15%' }}><b>2] User Define Mechanical Meter Serial No   :- </b></div>
                              <div>
                                 <div class='d-flex '>
                                 <div className="custom-control custom-checkbox  mb-4 d-flex tw-items-center"  style={{paddingBottom:'22px'}}>
                                 <input type="checkbox" id="IS_USER_DEFINE_MC_NUMBER" name="EVC_SRNO_ALLOW" className="custom-control-input" onChange={(e)=>{setConfig({...config,IS_USER_DEFINE_MC_NUMBER:e.target.checked?1:0,VIRCHUAL_MECHANICAL_NO:''})}} checked={config?.IS_USER_DEFINE_MC_NUMBER==1?true:false} disabled={!disable}/>
                                 <label className="custom-control-label" for="IS_USER_DEFINE_MC_NUMBER" ></label>
                                 </div>
                                {config?.IS_USER_DEFINE_MC_NUMBER==1?<input class="form-control" type="text" placeholder="" name="Installation_Person_Position" id="Installation_Person_Position" onChange={(e) => (setConfig({...config,VIRCHUAL_MECHANICAL_NO:e.target.value}))} disabled={!disable} value={config?.VIRCHUAL_MECHANICAL_NO}/>:''} 
                                 </div>
                              </div>
                           </div >
                           <div class="d-flex mt-4">
                              <div className=' ' style={{ fontSize: '15px', width: '15%' }}><b>3] EVC/MC Meter Data :- </b></div>
                              <div>
                                 <div class='d-flex '>
                                    <Switch size="small" checked={config?.EVC_DATA["hourlydata"] == 1 ? true : false} onChange={(e) => (onHandalSwitchEVCData(e, "hourlydata"))} disabled={!disable} />
                                    <h6 class='ml-2'>Hourly Data</h6>
                                 </div>
                              </div>
                           </div >
                           {config?.EVC_DATA["hourlydata"] == 1&&(props?.GSMdata?.METER_TYPE=='METEID1684236582202'||props?.GSMdata?.METER_TYPE=='METEID1684236463648'||props?.GSMdata?.METER_TYPE=='METEID1680324563157')?<div class="d-flex mt-4">
                              <div className=' ' style={{ fontSize: '15px', width: '14%' }}><b>4] Hourly Data Limit :- </b></div>
                              <div className='col-1'>
                                 <div class='d-flex '>
                                 <select name="table_id_length" aria-controls="table_id" class="" value={config?.EVC_DATA["hourly_data_limit"]} onChange={(e) => (setConfig({...config,EVC_DATA:{...config?.EVC_DATA,hourly_data_limit:e.target.value}}))} disabled={!disable} style={{width:'100%'}}>
															<option value={1}>1</option>
															<option value={24}>24</option>
											</select>
                                    {/* <Switch size="small" checked={config?.EVC_DATA["hourlydata"] == 1 ? true : false} onChange={(e) => (onHandalSwitchEVCData(e, "hourlydata"))} disabled={!disable} />
                                    <h6 class='ml-2'>Hourly Data</h6> */}
                                 </div>
                              </div>
                           </div >:''}
                           

                           {disable ? <>
                              <div class="text-left mb-3 ml-3 mt-3">
                                 <input type="submit" name="Update" class={`btn btn-danger ${btnFlag ? 'disabled btn-progress' : ''} mr-4`} value="Submit" id="submitvn" onClick={onHandalUpdate} />

                                 <Link class="btn btn-light text-dark " onClick={() => setDisable(false)}>Cancel</Link>
                              </div></> : ''}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
