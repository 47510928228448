import React, { useEffect, useMemo, useState } from 'react';
import { LuSquare } from "react-icons/lu";
import { FaRegSquareCheck } from "react-icons/fa6";
import { SquareMinus, SquarePlus } from 'lucide-react';

// Recursive TreeNode component
export const TreeNode = ({ node, onCheck, depth = 0, isAllExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Update local expansion state when global expansion changes
  useEffect(() => {
    setIsExpanded(isAllExpanded);
  }, [isAllExpanded]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCheck = () => {
    onCheck(node.value, !node.checked);
  };


  return (
    <>
      <div
        className="tw-flex tw-items-center hover:!tw-bg-gray-200 tw-cursor-pointer tw-rounded tw-overflow-hidden py-1"
        style={{ paddingLeft: `${depth * 16}px` }}
      >
        {node.children && node.children.length > 0 && (
          <button
            onClick={toggleExpand}
            className={`tw-mr-1 border-0 tw-flex tw-items-center tw-justify-center tw-min-w-[20px] tw-h-5 p-0 ${true ? '!tw-bg-transparent !tw-text-gray-500 hover:!tw-text-gray-700 ' : 'tw-bg-blue-400 !tw-text-white tw-rounded tw-overflow-hidden '}`}
          >
            {isExpanded ? <SquareMinus size={16} /> : <SquarePlus size={16} />}
          </button>
        )}

        {
          node?.children && !node.children.length && (
            <div className={`tw-mr-1 tw-min-w-[20px] tw-h-5 `} ></div>
          )
        }

        <button
          onClick={handleCheck}
          className="tw-mr-1 tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 p-0 border-0 !tw-bg-transparent"
        >
          {node.checked ? (
            <FaRegSquareCheck className="!tw-text-indigo-500 tw-w-5 tw-h-5" />
          ) : (
            <LuSquare className="!tw-text-gray-400  tw-w-5 tw-h-5" />
          )}
        </button>

        <span onClick={handleCheck} className={`tw-text-black`} >{node.title}</span>
      </div>

      {isExpanded && node.children && node.children.length > 0 && (
        <div>
          {node.children.map(childNode => (
            <TreeNode
              key={childNode.value}
              node={childNode}
              onCheck={onCheck}
              depth={depth + 1}
              isAllExpanded={isAllExpanded} 
            />
          ))}
        </div>
      )}
    </>


  );
};

export const TreeNodeView = React.memo(({ handleCheck, TreeSelection }) => {

  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const toggleExpandAll = () => {
    setIsAllExpanded(!isAllExpanded);
  };


  const allCheck = useMemo(() => {
    function areAllCheckedTrue(data) {
      function checkNode(node) {
        if (!node.checked) return false;
        if (node.children && node.children.length > 0) {
          return node.children.every(checkNode);
        }
        return true;
      }
      return data.every(checkNode);
    }
    return areAllCheckedTrue(TreeSelection);
  }, [TreeSelection]);



  return (
    <div className='tw-pl-1.5 tw-mb-1.5' >

      <div className="tw-flex tw-items-center hover:!tw-bg-gray-200 tw-cursor-pointer tw-rounded tw-overflow-hidden py-1">
        <div className={`tw-mr-1 tw-min-w-[20px] tw-h-5 `} ></div>
        <button
          onClick={toggleExpandAll}
          className="tw-mr-1 tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 p-0 border-0 !tw-bg-transparent"
        >
          {isAllExpanded ? (<SquareMinus size={16} />) : (<SquarePlus size={16} />)}

        </button>
        <span onClick={toggleExpandAll} className={`tw-text-black`}>
          {isAllExpanded ? 'Collapse All' : 'Expand All'}
        </span>
      </div>

      <div className="tw-flex tw-items-center hover:!tw-bg-gray-200 tw-cursor-pointer tw-rounded tw-overflow-hidden py-1">
        <div className={`tw-mr-1 tw-min-w-[20px] tw-h-5 `} ></div>

        <button onClick={() => handleCheck(null, !allCheck, "all")} className="tw-mr-1 tw-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 p-0 border-0 !tw-bg-transparent">
          {allCheck ? (
            <FaRegSquareCheck className="!tw-text-indigo-500 tw-w-5 tw-h-5" />
          ) : (
            <SquarePlus className="!tw-text-gray-400  tw-w-5 tw-h-5" />
          )}
        </button>

        <span onClick={() => handleCheck(null, !allCheck, "all")} className={`tw-text-black`} >All</span>
      </div>

      {
        TreeSelection.map((node, index) => (
          <TreeNode
            key={node?.id}
            node={node}
            onCheck={handleCheck}
            isAllExpanded={isAllExpanded}
          />

        ))
      }
    </div>

  )
});