import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Download, EllipsisVertical, Maximize, Plus, RefreshCcw, Trash2 } from 'lucide-react';
import { cn } from '../../../lib/utils';

export default function MoreOptionButton({
    activeTab,
    hasAddCardPermission,
    onAddCard,
    onRemoveCard,
    captureImage,
    newMenu = [],
    onExpandButton,
    onRefreshCard,
    buttonClassName,
    isbuttonSmall
}) {

    const [show, setShow] = useState(false);

    const options = [
        { value: 'png', label: 'Download in PNG' },
        { value: 'jpeg', label: 'Download in JPG' },
        ...newMenu
    ]

    const handleDownloadClick = (option) => {
        if (option?.value === 'Diagnostic Report') {
            option.event()
        } else {
            captureImage(option?.value)
        }
    }

    return (
        <Dropdown className="d-inline ">
            {!isbuttonSmall?<Dropdown.Toggle variant="primary" className={cn("!tw-py-0 !tw-px-0 tw-w-9 tw-h-7 tw-flex tw-items-center tw-justify-center tw-rounded-full",buttonClassName)} id="dropdown-basic">
                <EllipsisVertical size={14} />
            </Dropdown.Toggle>:<Dropdown.Toggle variant="secondary" className={buttonClassName} id="dropdown-basic"> <EllipsisVertical size={14} />
            </Dropdown.Toggle>}

            <Dropdown.Menu>
                {activeTab !== 'Custom Dashboard' && (hasAddCardPermission) && (
                    <Dropdown.Item className='!tw-flex !tw-items-center tw-gap-x-2' onClick={onAddCard}>
                        <Plus size={14} />Add Card
                    </Dropdown.Item>
                )}

                {activeTab === 'Custom Dashboard' && (
                    <Dropdown.Item className='!tw-flex !tw-items-center tw-gap-x-2' onClick={onRemoveCard}>
                        <Trash2 size={14} />Remove Card
                    </Dropdown.Item>
                )}

                {onRefreshCard && <Dropdown.Item className='!tw-flex !tw-items-center tw-gap-x-2' onClick={onRefreshCard}>
                    <RefreshCcw size={14} />Refresh
                </Dropdown.Item>}

                {captureImage && <Dropdown
                    drop="right"
                    responsive
                    show={show}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                >
                    <Dropdown.Toggle as={Dropdown.Item} className='!tw-flex !tw-items-center tw-gap-x-2'>
                        <Download size={14} /> Download
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                        {options?.map((option) => (
                            <Dropdown.Item onClick={() => handleDownloadClick(option)} >
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>}

                {onExpandButton && (
                    <Dropdown.Item className='!tw-flex !tw-items-center tw-gap-x-2' onClick={onExpandButton}>
                        <Maximize size={14} />Expand
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}
