import * as React from "react";
import { cva } from "class-variance-authority";
import { cn } from "../../lib/utils";

// Define the button styles using CVA
const buttonVariants = cva(
    "tw-inline-flex tw-border-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-md md:tw-text-sm tw-text-xs tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-cursor-not-allowed disabled:tw-opacity-40 tw-duration-300",
    {
        variants: {
            variant: {
                default: "tw-text-primary-foreground enabled:hover:tw-bg-primary/90",
                destructive:
                    "tw-bg-destructive tw-text-destructive-foreground enabled:hover:tw-bg-destructive/90",
                outline:
                    "tw-border tw-border-solid  tw-shadow tw-border-input tw-bg-background enabled:hover:tw-bg-accent enabled:hover:tw-text-accent-foreground",
                ghost: "hover:tw-bg-accent hover:tw-text-accent-foreground",
                link: "tw-text-blue-500 tw-underline-offset-4 enabled:hover:tw-underline",
                cancel: "tw-bg-gray-200 tw-text-gray-600 enabled:hover:tw-bg-gray-300",
                none: "tw-text-primary",
                gradient:
                    "tw-bg-gradient-to-r tw-from-indigo-500 tw-to-purple-600 tw-text-white enabled:hover:tw-from-indigo-600 enabled:hover:tw-to-purple-700 enabled:hover:tw-shadow-xl",
                grey: "tw-bg-accent tw-text-accent-foreground tw-shadow-sm enabled:hover:tw-bg-accent",
                serchBut: "tw-bg-[#f0f3ff] tw-w-full tw-border-none tw-rounded-none",
                submit:
                    "tw-bg-[#6777ef] tw-shadow-custom-pri tw-text-white hover:tw-bg-[#394eea] hover:tw-bg-[#394eea] tw-text-xs",
                submitred:
                    "tw-bg-[#fc544b] tw-shadow-custom-red tw-text-white hover:tw-bg-[#fb160a] focus:tw-bg-[#fb160a] tw-text-xs",
                cancelbtn:
                    "tw-bg-secondary/80 tw-text-secondary-foreground hover:tw-bg-secondary tw-text-xs tw-shadow-md",
                warning:
                    "tw-bg-[#ffa426] tw-shadow-custom-war tw-text-white hover:tw-bg-[#ff990d] focus:tw-bg-[#ff990d]",
                info:
                    "tw-bg-[#3abaf4] tw-shadow-custom-blue tw-text-white hover:tw-bg-[#0da8ee] focus:tw-bg-[#0da8ee]",
                danger:
                    "tw-bg-[#fc544b] tw-shadow-custom-red tw-text-white hover:tw-bg-[#fb160a] focus:tw-bg-[#fb160a]",
                success:
                    "tw-bg-[#54ca68] tw-shadow-custom-green tw-text-white hover:tw-bg-[#41c457] focus:tw-bg-[#41c457]",
                secondary:
                    "tw-bg-secondary/80 tw-font-bold tw-text-secondary-foreground hover:tw-bg-secondary tw-text-xs tw-shadow-md",
                primary:
                    "!tw-bg-indigo-500 tw-shadow-custom tw-text-white hover:tw-bg-indigo-600 focus:tw-bg-indigo-600",
                addBtn:
                    "tw-border tw-border-solid  tw-border-indigo-500 tw-text-indigo-500 hover:tw-bg-indigo-500 hover:tw-text-white tw-bg-transparent tw-font-bold tw-rounded-lg tw-whitespace-nowrap",
                iconBtn:
                    "tw-text-white tw-bg-indigo-500 hover:tw-bg-indigo-600 tw-rounded-lg tw-whitespace-nowrap",
            },
            size: {
                default: "tw-px-4 tw-py-2",
                sm: "tw-rounded-md tw-px-3 tw-py-1.5",
                lg: "tw-rounded-md tw-px-5 tw-py-3",
                iconLg: "tw-h-10 tw-w-10",
                icon: "tw-h-9 tw-w-9",
                iconXS: "tw-h-8 tw-w-8",
                xs: "tw-text-xs tw-px-2 tw-py-2",
                customIcon: "tw-px-[15px] tw-py-[7.5px] !tw-rounded-[30px]",
                searchBtn: "tw-h-[46px] tw-py-[9px] tw-px-[15px] !tw-w-auto",
                modalSubmit: "tw-h-9 tw-text-xs tw-rounded-sm tw-px-4 tw-py-2",
                customIconBtn:
                    "tw-px-[14px] tw-py-[3px] tw-rounded-[30px] tw-h-[30px] tw-flex tw-justify-center tw-items-center",
                customEditBtn:
                    "tw-size-[32px] tw-rounded-[5px] tw-flex tw-justify-center tw-items-center",
            },
            intent: {
                indigo: "tw-bg-indigo-500 enabled:hover:tw-bg-indigo-600 tw-text-white",
                green: "tw-bg-green-500 enabled:hover:tw-bg-green-600 tw-text-white",
                red: "tw-bg-red-500 enabled:hover:tw-bg-red-600 tw-text-white",
                yellow: "tw-bg-yellow-500 enabled:hover:tw-bg-yellow-600 tw-text-white",
                teal: "tw-bg-teal-500 enabled:hover:tw-bg-teal-600 tw-text-white",
                emerald:
                    "tw-bg-emerald-500 enabled:hover:tw-bg-emerald-600 tw-text-white",
            },
            backGroundColor: {
                default: 'tw-bg-indigo-500 !tw-text-white',
                bootstrapGreen : 'tw-bg-[#54ca68] !tw-text-white',
                bootstrapRed : 'tw-bg-[#fc544b] !tw-text-white',
                bootstrapInfo : 'tw-bg-[#3abaf4] !tw-text-white'
            },
            border: {
                default: "tw-border-input",
                none: "",
                indigo:
                    "!tw-border !tw-border-solid !tw-border-indigo-500 enabled:hover:!tw-border-indigo-600 hover:!tw-bg-indigo-100 !tw-text-indigo-600 enabled:!hover:tw-text-indigo-800 enabled:hover:!tw-bg-indigo-100",
                green:
                    "tw-border tw-border-solid tw-border-green-500 enabled:hover:tw-border-green-600 tw-text-green-600 enabled:hover:tw-text-green-800 enabled:hover:tw-bg-green-100",
                red: "tw-border tw-border-solid tw-border-red-500 enabled:hover:tw-border-red-600 tw-text-red-600 enabled:hover:tw-text-red-800 enabled:hover:tw-bg-red-100",
                yellow:
                    "tw-border tw-border-solid tw-border-yellow-500 enabled:hover:tw-border-yellow-600 tw-text-yellow-600 enabled:hover:tw-text-yellow-800 enabled:hover:tw-bg-yellow-100",
                teal: "tw-border tw-border-solid tw-border-teal-500 enabled:hover:tw-border-teal-600 tw-text-teal-600 enabled:hover:tw-text-teal-800 enabled:hover:tw-bg-teal-100",
                emerald:
                    "tw-border tw-border-solid tw-border-emerald-500 enabled:hover:tw-border-emerald-600 tw-text-emerald-600 enabled:hover:tw-text-emerald-800 enabled:hover:tw-bg-emerald-100",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    }
);

// Button component definition
const Button = React.forwardRef(
    (
        {
            className,
            intent,
            variant,
            border,
            size,
            disabled,
            isLoading = false,
            loadingText,
            loaderClassname,
            children,
            backGroundColor,
            ...props
        },
        ref
    ) => {
        return (
            <button
                className={cn(buttonVariants({ variant, size, intent, border,backGroundColor, className }))}
                disabled={disabled || isLoading}
                ref={ref}
                {...props}
            >
                {isLoading ? (
                    <span className="tw-pointer-events-none tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-gap-1.5">=
                        <span className="tw-sr-only">
                            {loadingText ? loadingText : "Loading..."}
                        </span>
                        {loadingText ? loadingText : children}
                    </span>
                ) : (
                    children
                )}
            </button>
        );
    }
);

Button.displayName = "Button";

export { Button, buttonVariants };
