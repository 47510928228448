import { clsx } from "clsx"
import { extendTailwindMerge } from "tailwind-merge"

const twMerge = extendTailwindMerge({
  prefix: "tw-"
})

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}


// EUMSOPS-T231 add compareAndGenerateJsonDiff for activity log
export const compareAndGenerateJsonDiff = (obj1, obj2, excludeFields = []) => {
  const old_json = {};
  const new_json = {};

  Object.keys(obj1).forEach((element) => {
    // Skip if field is in exclude list
    if (excludeFields.includes(element)) {
      return;
    }

    if (typeof obj2[element] === "object") {
      const stringifiedNew = JSON.stringify(obj2[element]);
      if (obj1[element] !== stringifiedNew && obj1[element] !== '') {
        old_json[element] = obj2[element];
        new_json[element] = JSON.parse(obj1[element]);
      }
    } else {
      if (obj1[element] !== obj2[element]) {
        old_json[element] = obj2[element];
        new_json[element] = obj1[element];
      }
    }
  });

  return { old_json, new_json };
};

export const activityLogForSingle = (oldValue, newValue, key) => {
  const old_json = {};
  const new_json = {};

  if (oldValue !== newValue) {
    old_json[key] = oldValue;
    new_json[key] = newValue;
  }

  return { old_json, new_json };
};

//  EUMSOPS-T172 use here
export const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#54ca68', "#67b7dcd9", '#191d21', '#7c5295', '#4f5ece', '#4f5ece']