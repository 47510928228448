import React, { useEffect } from 'react'
import './TabsPopover.css'
import { cn } from '../../../lib/utils'
import { Button } from '../../ui/button'
import { Edit } from 'lucide-react'

export default function TabsPopover({
    dataArray = [],
    selectedCard,
    isEdit = false,
    setSelectedCard,
    handleTabModel,
    isAddPermission=false,
    isEditPermission=false
}) {

    return (
        <div className="tw-w-[500px] tw-p-0  ">
            <div className="">
                {(isEdit && isAddPermission) && (
                    <div className='tw-flex tw-justify-end tw-items-center tw-w-full tw-mb-2 tw-pb-2 border-bottom-dahsed tw-pr-1' >
                        <Button
                            className='!tw-text-xs '
                            variant='primary'
                            size='sm'
                            onClick={(e) => {
                                e.stopPropagation()
                                handleTabModel()
                            }}
                        >
                            + Add
                        </Button>
                    </div>
                )
                }
                <div className={cn("tw-grid tw-grid-cols-2 tw-gap-4")}>
                    {dataArray.map((component) => (
                        <div
                            key={component?.value}
                            className={cn('tw-rounded-lg !tw-border tw-border-solid tw-p-2 tw-cursor-pointer tw-transition-all tw-duration-200 tw-relative  !tw-border-gray-200 hover:!tw-border-indigo-300 hover:!tw-bg-indigo-50', selectedCard === component?.value && '!tw-border-indigo-500 !tw-bg-indigo-50', isEdit && 'tw-flex tw-items-center tw-gap-x-1')}
                            onClick={() => setSelectedCard(component?.value)}
                        >
                            <h3 className={cn('tw-text-sm tw-font-semibold !tw-my-0 tw-text-gray-900 tw-flex-1', selectedCard === component?.value && 'tw-text-indigo-700')}>
                                {component?.title}
                            </h3>
                            {/* <p className="tw-text-sm tw-text-gray-500 tw-mb-0">
                                {component?.description}
                            </p> */}
                            {(isEdit && isEditPermission) && (
                                <Button
                                    border="indigo"
                                    variant='outline'
                                    size='iconXS'
                                    className='tw-bg-transparent tw-px-0'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleTabModel(component?.value)
                                    }}
                                >
                                    <Edit size={16} />
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}
