import { Popover } from 'antd'
import { CirclePlus } from 'lucide-react'
import React from 'react'
import { cn } from '../../lib/utils'

export default function DropdownSelecter({
    name,
    value,
    activeTab,
    selectedCard,
    popoverContent,
}) {
    return (
        <Popover
            placement="bottomLeft"
            content={popoverContent}
            title="Title"
            trigger="click">
            
            <div className={cn(`tw-border tw-border-dashed tw-border-gray-500 tw-rounded-md tw-py-1.5 tw-px-2 hover:tw-bg-gray-100 tw-duration-150 tw-cursor-pointer tw-flex tw-items-center tw-gap-x-2 ${(activeTab === value && selectedCard) && '!tw-border-indigo-700  tw-bg-indigo-50/25 hover:!tw-bg-indigo-100/40'}`)} >
                <button className='tw-bg-transparent !tw-border-none !tw-p-0' >
                    <h5 className={`tw-text-gray-700 tw-font-semibold tw-text-base tw-flex tw-items-center tw-gap-x-1 !tw-mb-0 ${(activeTab === value && selectedCard) ? 'tw-text-gray-800/80' : 'tw-text-gray-600'}`} >
                        <CirclePlus size={16} />
                        {name}
                    </h5>
                </button>
                {
                    (activeTab === value && selectedCard) && (
                        <>
                            <div className='tw-w-[1px] tw-bg-slate-300/80  tw-self-stretch ' ></div>
                            <p className='!tw-border-l-[2px] tw-border-slate-300/80 tw-mb-0 !tw-text-sm  tw-font-semibold tw-px-1.5 tw-py-1 tw-rounded-md tw-bg-slate-200/80 tw-text-slate-700' >
                                {selectedCard}
                            </p>
                        </>

                    )
                }
            </div>
        </Popover>
    )
}