import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import { LuChevronUp , LuChevronDown  } from "react-icons/lu";



function useHoldPress(callback, delay) {
    const [hold, setHold] = useState(false);

    useEffect(() => {
        if (hold) {
            const interval = setInterval(callback, delay);
            return () => clearInterval(interval);
        }
    }, [hold, callback, delay]);

    const start = () => setHold(true);
    const stop = () => setHold(false);

    return { start, stop };
}

const InputType = {
    HOURS: "hours",
    MINUTES: "minutes",
};

function getValidNumber(value, min = 0, max = Infinity) {
    let numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
        numericValue = Math.max(min, Math.min(numericValue, max));
        return numericValue.toString().padStart(2, "0");
    }
    return min.toString().padStart(2, "0");
}

function minutesToHHMM(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return {
        hours: hours.toString().padStart(3, "0"),
        minutes: remainingMinutes.toString().padStart(2, "0")
    };
}

function hhmmToMinutes(hours, minutes) {
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
}

const HoursMinutesInput = React.forwardRef(({ className, type = "tel", value, id, name, onChange, onKeyDown, inputType, onLeftFocus, onRightFocus, maxHours, ...props }, ref) => {
    const handleChange = useCallback(
        (newValue) => {
            let validValue = newValue;
            if (inputType === InputType.MINUTES) {
                validValue = getValidNumber(newValue, 0, 59);
            } else {
                validValue = getValidNumber(newValue, 0, maxHours);
            }
            onChange?.(validValue);
        },
        [inputType, maxHours, onChange],
    );

    const handleKeyDown = (e) => {
        if (e.key === "Tab") return;
        if (e.key === "ArrowRight") onRightFocus?.();
        if (e.key === "ArrowLeft") onLeftFocus?.();
        if (["ArrowUp", "ArrowDown"].includes(e.key)) {
            e.preventDefault();
            const step = e.key === "ArrowUp" ? 1 : -1;
            const newValue = (parseInt(value, 10) + step).toString();
            handleChange(newValue);
        }
        if (e.key >= "0" && e.key <= "9") {
            e.preventDefault();
            let newValue = value + e.key;
            if (inputType === InputType.HOURS) {
                if (newValue.length > 3) newValue = newValue.slice(-3);
            } else {
                if (newValue.length > 2) newValue = newValue.slice(-2);
            }
            handleChange(newValue);
        }
    };

    return (
        <input
            ref={ref}
            id={id || inputType}
            name={name || inputType}
            placeholder={inputType === InputType.HOURS ? "HHH" : "MM"}
            className={classNames("form-control tw-text-center", inputType === InputType.HOURS ? "tw-w-[72px]" : "tw-w-[48px]",)}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            type={type}
            inputMode="decimal"
            onKeyDown={(e) => {
                onKeyDown?.(e);
                handleKeyDown(e);
            }}
            {...props}
        />
    );
});

export function HoursMinutesInputGroup({ onChange, value, maxHours = 999 }) {
    const [hours, setHours] = useState("000");
    const [minutes, setMinutes] = useState("00");

    useEffect(() => {
        if (value !== undefined) {
            const durationInMinutes = parseInt(value, 10);
            if (!isNaN(durationInMinutes)) {
                const { hours: h, minutes: m } = minutesToHHMM(durationInMinutes);
                setHours(h);
                setMinutes(m);
            }
        }
    }, [value]);

    const handleHoursChange = (newHours) => {
        setHours(newHours);
        const totalMinutes = hhmmToMinutes(newHours, minutes);
        onChange?.(totalMinutes.toString());
    };

    const handleMinutesChange = (newMinutes) => {
        setMinutes(newMinutes);
        const totalMinutes = hhmmToMinutes(hours, newMinutes);
        onChange?.(totalMinutes.toString());
    };

    const hourRef = React.createRef(null);
    const minuteRef = React.createRef(null);


    const incrementHours = () => handleHoursChange(getValidNumber((parseInt(hours, 10) + 1).toString(), 0, maxHours));
    const decrementHours = () => handleHoursChange(getValidNumber((parseInt(hours, 10) - 1).toString(), 0, maxHours));

    const incrementMinutes = () => handleMinutesChange(getValidNumber((parseInt(minutes, 10) + 1).toString(), 0, 59));
    const decrementMinutes = () => handleMinutesChange(getValidNumber((parseInt(minutes, 10) - 1).toString(), 0, 59));

    const { start: startHourInc, stop: stopHourInc } = useHoldPress(incrementHours, 100);
    const { start: startHourDec, stop: stopHourDec } = useHoldPress(decrementHours, 100);

    const { start: startMinuteInc, stop: stopMinuteInc } = useHoldPress(incrementMinutes, 100);
    const { start: startMinuteDec, stop: stopMinuteDec } = useHoldPress(decrementMinutes, 100);


    return (
        <div className="tw-flex tw-items-center tw-space-x-2">
            <div className="tw-grid tw-gap-1 tw-text-center">
                <label className="tw-mb-0 tw-text-[14px] tw-font-[600] tw-leading-none" htmlFor="hours">Hours</label>
                <button
                    type="button"
                    className="tw-inline-flex tw-items-center tw-justify-center tw-rounded-md md:tw-text-sm tw-text-xs tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-p-0 tw-text-gray-500 hover:tw-text-gray-700 focus:tw-outline-none border-0 tw-bg-transparent"
                    onClick={incrementHours}
                    onMouseDown={startHourInc}
                    onMouseUp={stopHourInc}
                    onMouseLeave={stopHourInc}
                >
                    <LuChevronUp  className='tw-h-4 tw-w-4' />
                </button>
                <HoursMinutesInput
                    ref={hourRef}
                    inputType={InputType.HOURS}
                    value={hours}
                    onChange={handleHoursChange}
                    maxHours={maxHours}
                    onRightFocus={() => minuteRef.current.focus()}
                />
                <button
                    type="button"
                    className="tw-inline-flex tw-items-center tw-justify-center tw-rounded-md md:tw-text-sm tw-text-xs tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-p-0 tw-text-gray-500 hover:tw-text-gray-700 focus:tw-outline-none border-0 tw-bg-transparent"
                    onClick={decrementHours}
                    onMouseDown={startHourDec}
                    onMouseUp={stopHourDec}
                    onMouseLeave={stopHourDec}
                > <LuChevronDown  className='tw-h-4 tw-w-4' /></button>
            </div>
            <div className="tw-grid tw-gap-1 tw-text-center">
                <label className="tw-mb-0 tw-text-[14px] tw-font-[600] tw-leading-none" htmlFor="minutes">Minutes</label>
                <button
                    type="button"
                    className="tw-inline-flex tw-items-center tw-justify-center tw-rounded-md md:tw-text-sm tw-text-xs tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-p-0 tw-text-gray-500 hover:tw-text-gray-700 focus:tw-outline-none border-0 tw-bg-transparent"
                    onClick={incrementMinutes}
                    onMouseDown={startMinuteInc}
                    onMouseUp={stopMinuteInc}
                    onMouseLeave={stopMinuteInc}
                >
                    <LuChevronUp  className='tw-h-4 tw-w-4' />
                </button>
                <HoursMinutesInput
                    ref={minuteRef}
                    inputType={InputType.MINUTES}
                    value={minutes}
                    onChange={handleMinutesChange}
                    maxHours={maxHours}
                    onLeftFocus={() => hourRef.current.focus()}
                />
                <button
                    type="button"
                    className="tw-inline-flex tw-items-center tw-justify-center tw-rounded-md md:tw-text-sm tw-text-xs tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-p-0 tw-text-gray-500 hover:tw-text-gray-700 focus:tw-outline-none border-0 tw-bg-transparent"
                    onClick={decrementMinutes}
                    onMouseDown={startMinuteDec}
                    onMouseUp={stopMinuteDec}
                    onMouseLeave={stopMinuteDec}
                >
                    <LuChevronDown  className='tw-h-4 tw-w-4' />
                </button>
            </div>
        </div>
    );
}