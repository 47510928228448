import chroma from 'chroma-js';

export const Tab = ({ active, label, color, onClick }) => {
    return (
        <button
            style={{
                backgroundColor: active ? chroma(color).alpha(0.4).css() : '',
                color: active ? chroma(color).darken(1.5).css() : color,
                borderBottom: `1px solid ${color}`,
                boxShadow: active ? `0 2px 8px -3px ${color}` : '',
            }}
            className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0  tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${active ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
            onClick={onClick}
        >
            {label}
        </button>
    );
};