import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BootstrapTooltip } from '../../Commoncomponet/common'
// import { Cron } from 'react-js-cron'
import Select from 'react-select'
import 'react-js-cron/dist/styles.css'
import { GetReportType, InsertGSMReportScheduler, UpdateApiScheduleReportStatus, UpdateApiScheduleStatus, UpdateGSMReportScheduler, getAccount, getAreaById, getCircleById, getDivisionById, getGaById, getProfilNameByAccountId, getReportScheduleCount, getRolesById, getSubdevisionById, getUsersList, getUsersListByCategory0, getgsmReportActivityLogs, getgsmReportSchedule, triggerManualReportGenerate } from '../../Commoncomponet/Utils'
import { toast } from 'react-toastify'
import exports from '../../assets/img/export.png'
import Pagination from '../../Commoncomponet/Pagination'
import { Modal, Popover, Spin } from 'antd'
import moment from 'moment'
import SlidingTabs from '../../Commoncomponet/Full_Tabs/SlidingTabs'
import { TabPane } from 'react-bootstrap'
import classNames from 'classnames'
import swal from 'sweetalert2';
import cronstrue from 'cronstrue'
import './Report.css'
import CronIndex from '../Cron/CronIndex'
import { Button } from '../ui/button'
import { Chip } from '../ui/chip'
import { ChevronDown, Pencil, X } from 'lucide-react';
import './ScheduleReport.css'
import { Tooltip } from 'react-tooltip'
import { Separator } from '../ui/Separator'
// import 'react-cron-generator/dist/cron-builder.css'
window.Swal = swal;

export default function GsmScheduleReport() {
    const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
    const [value, setValue] = useState({ cron_value: '0 0 * * *', select_period: 'day' })
    const [isModal, setIsModal] = useState(false)
    const [ReportTypes, setReportTypes] = useState([])
    const [permision, setpermision] = useState({})
    const [Profile, setProfile] = useState({ CATEGORY0: [], CATEGORY1: [], CATEGORY2: [], CATEGORY3: [], CATEGORY4: [], CATEGORY5: [] });
    const [SubdevisionData, setSubdevisionData] = useState([]);
    const [DivisionData, setDivisionData] = useState([]);
    const [CircleData, setCircleData] = useState([]);
    const [GaData, setGaData] = useState([]);
    const [AccounData, setAccountData] = useState([]);
    const [AreaData, setAreaData] = useState([]);
    const [report, setReport] = useState({ name: '', type: '', Consumer_Type: [], Users: [], send_type: [],report_subject:'',report_description:'' })
    const [UserList, setUserList] = useState([])
    const [PageSize, setPageSize] = useState(50)
    const [numberofPage, setnumberofPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const [ReportScheduleData, setReportScheduleData] = useState([])
    const [ScheduleId, setScheduleId] = useState('')
    const [ScheduleDetails, setScheduleDetails] = useState({})
    const [isSideExapnded, setIsSideExapnded] = useState(false)
    const [Validation, setValidation] = useState({})

    const consumerTypeArray = [
        { value: "Industrial", label: 'Industrial' },
        { value: "Residential", label: 'Residential' },
        { value: "Commercial", label: 'Commercial' },
        { value: "DRS", label: 'DRS' },
    ]

    const ongetGaById = async (id) => {
        const data1 = await getGaById(id);
        if (data1.success === true) {
            const options = []
            const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter((items) => (items === data.CATEGORY1_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708'));
            optionArray.map((item) => (options.push({ value: item?.CATEGORY1_UNIQUE_ID, label: item?.CATEGORY1_NAME })))
            setGaData(options)
        }
    };

    const ongetCircle = async (id) => {
        const data1 = await getCircleById(id);
        if (data1.success === true) {
            const options = []
            const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter((items) => (items === data.CATEGORY2_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708'));
            optionArray.map((item) => (options.push({ value: item?.CATEGORY2_UNIQUE_ID, label: item?.CATEGORY2_NAME })))
            setCircleData(options);
        }
    };
    const ongetSubdevisionById = async (id) => {
        const data1 = await getSubdevisionById(id);
        if (data1.success === true) {
            const options = []
            const optionArray = data1?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter((items) => (items === data.CATEGORY4_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708'));
            optionArray.map((item) => (options.push({ value: item?.CATEGORY4_UNIQUE_ID, label: item?.CATEGORY4_NAME })))
            setSubdevisionData(options);
        }
    };
    const ongetDivisionById = async (id) => {
        const data3 = await getDivisionById(id);
        if (data3.success === true) {
            const options = []
            const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter((items) => (items === data.CATEGORY3_UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708'));
            optionArray.map((item) => (options.push({ value: item?.CATEGORY3_UNIQUE_ID, label: item?.CATEGORY3_NAME })))
            setDivisionData(options);
        }
    };
    const ongetArea = async (id) => {
        const data3 = await getAreaById(id);
        if (data3.success === true) {
            const options = []
            const optionArray = data3?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708'));
            optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY5_NAME })))
            setAreaData(options);
        }
    };

    useEffect(() => {
        if (Profile?.CATEGORY0?.length > 0) {
            ongetGaById(Profile?.CATEGORY0);
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([])
        } else {
            setGaData([]);
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);
        }
    }, [Profile.CATEGORY0]);

    useEffect(() => {
        if (Profile?.CATEGORY1?.length > 0) {
            ongetCircle(Profile?.CATEGORY1);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);

        } else {
            setCircleData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);


        }
    }, [Profile.CATEGORY1]);

    useEffect(() => {
        if (Profile?.CATEGORY2?.length > 0) {
            ongetDivisionById(Profile?.CATEGORY2);
            setAreaData([]);
            setSubdevisionData([]);

        } else {
            setAreaData([]);
            setDivisionData([]);
            setSubdevisionData([]);
        }
    }, [Profile.CATEGORY2]);

    useEffect(() => {
        if (Profile?.CATEGORY3?.length > 0) {
            ongetSubdevisionById(Profile?.CATEGORY3);
            setAreaData([]);
        } else {
            setSubdevisionData([]);
            setAreaData([]);
        }
    }, [Profile.CATEGORY3]);

    useEffect(() => {
        if (Profile?.CATEGORY4?.length > 0) {
            ongetArea(Profile?.CATEGORY4);
        } else {
            setAreaData([]);

        }
    }, [Profile.CATEGORY4]);

    const onGetReportTypeGSM = async () => {
        const data = await GetReportType({ type: 'GSM' })
        if (data.success == true) {
            var arrayReport = []
            console.log(permision?.ROLES_OTHER_PERMISSIONS);
            data?.data?.data?.map((item) => (permision?.ROLES_OTHER_PERMISSIONS?.Report?.filter((info) => (info == item?.BACK_TYPE_NAME)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708' ? arrayReport.push({ value: item?.UNIQUE_ID, label: item?.FRONT_TYPE_NAME, BACK_TYPE_NAME: item?.BACK_TYPE_NAME }) : ''))
            setReportTypes(arrayReport)

        } else {

        }
    }

    const onHandaleList = async (info) => {

        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;

        const data = await getgsmReportSchedule({ start: firstPageIndex, end: PageSize }, { user_id: userInfo?.UNIQUE_ID, ROLE: userInfo?.ROLE,category0 : permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view })
        if (data.success === true) {
            setReportScheduleData(data.data.data)
            if (Object.keys(ScheduleDetails).length > 0) {
                setScheduleDetails(data.data.data?.filter((item) => (item.unique_id === ScheduleDetails?.unique_id))[0])
            }
        } else {
            console.log('something went wrong')
        }
    }

    const onHandaleRoles = async () => {

        const data = await getRolesById(userInfo?.ROLE)
        if (data.success === true) {
            const obj = data.data.data[0]
            const Roles = { ...obj, ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0), ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5), ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2), ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3), ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1), ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4), ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS) }
            setpermision(Roles)
        } else {
            console.log('something want wrong')
        }
    }

    const onHandaleSelection = async () => {
        const data = await getAccount()
        if (data.success === true) {
            const options = []
            const optionArray = data?.data?.data.filter((data) => (permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter((items) => (items === data.UNIQUE_ID)).length > 0 || userInfo?.ROLE === 'ROLE1650614500708'))
            optionArray.map((item) => (options.push({ value: item?.UNIQUE_ID, label: item?.CATEGORY0 })))
            setAccountData(options);
        } else {
            console.log('somthing want wrong')
        }
    }

    useEffect(() => {
        // onHandaleList()
        onHandaleRoles()

    }, [])

    useEffect(() => {
        if (Object.keys(permision).length) {
            onHandaleList()
            onHandaleSelection()
            ongetUsersList()
            onGetReportTypeGSM()

        }
    }, [permision])
    const elements = document.getElementsByClassName('cron-builder-bg');
    if (elements.length > 0) {
        elements[0].style.display = 'none';
        elements[1].style.display = 'none';
    }
    const ongetUsersList = async () => {
        const data = await getUsersListByCategory0({}, userInfo?.UNIQUE_ID, userInfo?.ROLE, 'Schedule_Report',permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view)
        if (data.success === true) {
            setUserList(data.data.data)
        } else {
            console.log('something want wrong')
        }
    }

    const handleRowClick = () => {
        console.log(ScheduleDetails)
        setReport({ name: '', type: ScheduleDetails?.report_type_id, Consumer_Type: JSON.parse(ScheduleDetails?.filter_object || '{}')?.Consumer_Type || [], Users: JSON.parse(ScheduleDetails?.send_user_list || '[]') || [], send_type: JSON.parse(ScheduleDetails?.report_send_type || '[]') || [], extension: ScheduleDetails?.file_extension,report_subject:ScheduleDetails?.report_subject||'',report_description:ScheduleDetails?.report_description||'' })
        setValue({ cron_value: JSON.parse(ScheduleDetails?.schedule_time || '{}')?.cron_value || '* * * * *', select_period: JSON.parse(ScheduleDetails?.schedule_time || '{}')?.select_period || 'Daily' })
        setProfile({ CATEGORY0: JSON.parse(ScheduleDetails?.filter_object || '{}')?.CATEGORY0, CATEGORY1: JSON.parse(ScheduleDetails?.filter_object || '{}')?.CATEGORY1, CATEGORY2: JSON.parse(ScheduleDetails?.filter_object || '{}')?.CATEGORY2, CATEGORY3: JSON.parse(ScheduleDetails?.filter_object || '{}')?.CATEGORY3, CATEGORY4: JSON.parse(ScheduleDetails?.filter_object || '{}')?.CATEGORY4, CATEGORY5: JSON.parse(ScheduleDetails?.filter_object || '{}')?.CATEGORY5 })
        setScheduleId(ScheduleDetails?.unique_id)
        setIsModal(true)

        const classCron = document.querySelectorAll('.cron_builder .nav-link')
        console.log(classCron);
        //    classCron.forEach((events)=>{
        //     if(events.innerText==(JSON.parse(ScheduleDetails?.schedule_time || '{}')?.select_period || 'Daily')){
        //         events.classList.add('active')
        //     }else{
        //         events.classList.remove('active')
        //     }
        //    })
    }

    const handleSliderClick = (info) => {
        setScheduleDetails(info)
        setIsSideExapnded(true)
    }

    const validation = (name, value) => {
        switch (name) {
            case "report_type_id":
                if (!value) {
                    return "Please Select Report Type!";
                } else {
                    return "";
                }
            case "filter_object":
                if (value?.Profile?.CATEGORY0?.length == 0) {
                    return "Please input IMEI!";
                } else {
                    return "";
                }
            case "report_send_type":
                if (JSON.parse(value||'[]').length == 0) {
                    return "Please Select Sent Type";
                } else {
                    return "";
                }
            case "send_user_list":
                if (JSON.parse(value||'[]').length == 0) {
                    return "Please Select Sent To Users";
                } else {
                    return "";
                }
            case "file_extension":
                if (!value) {
                    return "Please Select File Extension";
                } else {
                    return "";
                }
            default: {
                return null;
            }
        }
    };

    const onHandalSubmit = async () => {
        const obj = { report_type_id: report?.type, filter_object: JSON.stringify({ ...Profile, Consumer_Type: report?.Consumer_Type }), schedule_time: JSON.stringify(value), report_send_type: JSON.stringify(report?.send_type), send_user_list: JSON.stringify(report?.Users), add_by: userInfo?.UNIQUE_ID, file_extension: report?.extension ,report_subject:report?.report_subject,report_description:report?.report_description}

        var allErrors = {};
        Object.keys(obj).forEach((key) => {
            const error = validation(key, obj[key]);
            if (error && error.length) {
                allErrors[key] = error;
            }
        });


        console.log(allErrors)
        if (Object.keys(allErrors).length) {
            return setValidation(allErrors);
        } else {
            if (ScheduleId == '') {
                const data = await InsertGSMReportScheduler(obj)
                if (data.success === true) {
                    toast.success('Successfully Added')
                    onCancel()
                    onHandaleList()
                } else {
                    toast.error('Server Issue')
                    console.log('Server Issue')
                }
            } else {

                const new_json = {}
                const old_json = {}
                Object.keys(obj).map((item) => {
                    if (ScheduleDetails[item] != obj[item]) {
                        old_json[item] = ScheduleDetails[item]
                        new_json[item] = obj[item]
                    }
                })
                const data = await UpdateGSMReportScheduler({ ...obj, new_json, old_json }, ScheduleId)
                if (data.success === true) {
                    toast.success('Successfully Updated')
                    onCancel()
                    onHandaleList()
                } else {
                    toast.error('Server Issue')
                    console.log('Server Issue')
                }
            }
        }
    }

    const onCancel = () => {
        setIsModal(false)
        setReport({ name: '', type: '', Consumer_Type: [], Users: [], send_type: [],report_description:'',report_subject:'' })
        setProfile({ CATEGORY0: [], CATEGORY1: [], CATEGORY2: [], CATEGORY3: [], CATEGORY4: [], CATEGORY5: [] })
        setValue({ cron_value: '* * * * *', select_period: 'day' })
        setValidation({})
    }

    const ontriggerManualReportGenerate = async (info) => {
        swal.fire({
            title: "!Are you Sure,Run Schedule (" + info?.unique_id + ")",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const report_type = ReportTypes.filter((data) => (data?.value === info?.report_type_id))[0] || {}
                const data = await triggerManualReportGenerate({ ...info, BACK_TYPE_NAME: report_type?.BACK_TYPE_NAME })
                if (data.success == true) {
                    toast.success('successfully run the schedule')
                } else {
                    toast.error('Server issue')
                }
            }
        })
    }

    const onActive = async () => {
        swal.fire({
            title: "!Are you Sure,Schedule Status Change In Active",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await UpdateApiScheduleReportStatus({ SCHEDULE_STATUS: 1, tableName: 'gsm_report_schedule_index', old_json: JSON.stringify({ SCHEDULE_STATUS: 0 }), new_json: JSON.stringify({ SCHEDULE_STATUS: 1 }), CREATED_BY: userInfo?.UNIQUE_ID }, ScheduleDetails?.unique_id)
                if (data.success === true) {
                    toast.success('Schedule is Active')
                    onHandaleList()

                } else {
                    toast.error('something want wrong')
                }
            }
        })
    }

    const onInActive = async () => {
        swal.fire({
            title: "!Are you Sure,Schedule Status Change In InActive",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await UpdateApiScheduleReportStatus({ SCHEDULE_STATUS: 0, tableName: 'gsm_report_schedule_index', old_json: JSON.stringify({ SCHEDULE_STATUS: 1 }), new_json: JSON.stringify({ SCHEDULE_STATUS: 0 }), CREATED_BY: userInfo?.UNIQUE_ID }, ScheduleDetails?.unique_id)
                if (data.success === true) {
                    toast.success('Schedule is InActive')
                    onHandaleList()

                } else {
                    toast.error('something want wrong')
                }
            }
        })
    }

    const userColumnConfig = [
        { label: 'Name', name: 'label' },
        { label: 'Email', name: 'EMAIL' },
        { label: 'Mobile', name: 'MOBILE' },
    ]
   
    const columnConfig = [
        {
            name: 'u_sr_no',
            label: 'SR NO',
            toggleDisabled: true,
            bodyClassName: "GSM_Link",
            headerStyle: { minWidth: "80px" },
            renderBody: (item) => (
                <td className="tw-px-2 tw-py-3 tw-text-center tw-text-blue-600 tw-cursor-pointer tw-group" onClick={() => handleSliderClick(item)}>
                    <div className='tw-flex tw-items-center  tw-gap-2 tw-mx-2'>
                        <span>{item?.u_sr_no}</span>
                        <div className="tw-opacity-0 tw-transition-all group-hover:tw-opacity-100" >
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" id="right-arrow"><g data-name="Layer 2"><g data-name="arrowhead-right"><path fill='currentColor' d="m18.78 11.37-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"></path><path fill='currentColor' d="M7 5.37a1 1 0 0 0-1.61 1.26L9.71 12l-4.48 5.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 6 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 0-1.27z"></path></g></g></svg>
                        </div>
                    </div>
                </td>
            ),
        },
        {
            name: 'report_subject',
            label: 'Report Subject',
            toggleDisabled: true,
        },
        {
            name: 'report_description',
            label: 'Report Description',
            headerStyle: { minWidth: "250px" },
            toggleDisabled: true,
        },
        {
            name: 'schedule_time',
            label: 'Schedule Time',
            headerStyle: { minWidth: "120px" },
            toggleDisabled: true,
            renderBody: (item) => (
                <td className="text-center !tw-px-2 !tw-py-3">{JSON.parse(item.schedule_time || '{}')?.cron_value ? cronstrue.toString(JSON.parse(item.schedule_time || '{}')?.cron_value) : ''}</td>
            ),
        },
        {
            name: 'file_extension',
            label: 'File Extention',
            headerStyle: { minWidth: "75px" },
        },
        {
            name: 'send_user_list',
            label: 'Sent To Users',
            headerStyle: { minWidth: "120px" },
            toggleDisabled: true,
            renderBody: (item) => (
                <td className="text-center tw-relative !tw-px-2 !tw-py-3">
                    <Popover overlayClassName="user-popover" placement="bottom" title={""} content={
                        <div className="tw-max-h-72 tw-overflow-auto tw-py-1.5 tw-px-2 tw-flex tw-flex-col tw-gap-y-2">

                            <div className="table-responsive">

                                <table id="table_id" className="tw-min-w-full tw-table-auto">
                                    <thead className='tw-text-xs tw-sticky tw-z-10 tw-top-0 tw-uppercase tw-bg-gray-100 border-bottom  !tw-border-b-gray-300 tw-rounded-lg tw-h-10'>
                                        <tr className=' tw-text-left !tw-font-bold !tw-text-gray-700' >
                                            {userColumnConfig.map((column) => (
                                                <th className='tw-px-2 tw-py-3' key={column.name} style={column?.headerStyle ? column?.headerStyle : {}} >
                                                    {column?.renderHeader ? column.renderHeader() : column.label}
                                                </th>

                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {UserList.filter((users) => (JSON.parse(item?.send_user_list || '[]').includes(users?.value)))?.length > 0 ? UserList.filter((users) => (JSON.parse(item?.send_user_list || '[]').includes(users?.value)))?.map((item, id) => (
                                            <tr className="tw-group border-bottom" >
                                                {userColumnConfig.map((column) => (
                                                    column?.renderBody ?
                                                        column.renderBody(item) :
                                                        <td className='tw-px-2 tw-py-3' >{item?.[column?.name] ?? '-'}</td>
                                                )
                                                )}
                                            </tr>
                                        )) :
                                        <td colSpan={Object.keys(userColumnConfig)?.length} className='tw-px-2 tw-py-3' >
                                            <div className='text-center tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full'>
                                                <h4 >No Data Found</h4>
                                            </div>
                                            </td>}
                                    </tbody>
                                </table>
                                <>
                                </>
                            </div>
                        </div>
                    } trigger="click" className=''>
                        <Button
                            variant='outline'
                            border='indigo'
                            size='sm'
                            type="button"
                            className='tw-gap-1 tw-bg-white !tw-text-sm tw-px-1.5 tw-py-0.5'
                            id="columnDropdown"

                        >
                            {UserList.filter((users) => (JSON.parse(item?.send_user_list || '[]').includes(users?.value)))?.length} Users
                            <ChevronDown size={16} />
                        </Button>
                    </Popover>
                </td>
            ),
        },
        {
            name: 'schedule_time',
            label: 'Created At',
            toggleDisabled: true,
            headerStyle: { minWidth: "100px" },
            renderBody: (item) => (
                <td className="text-center !tw-px-2 !tw-py-3">{moment.utc(item.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
            ),
        },
        {
            name: 'created_by_name',
            label: 'Created By',
            toggleDisabled: true,
            headerStyle: { minWidth: "100px" },
        },
        {
            name: 'schedule_time',
            label: 'Updated At',
            toggleDisabled: true,
            headerStyle: { minWidth: "100px" },
            renderBody: (item) => (
                <td className="text-center !tw-px-2 !tw-py-3" >{item.updated_at ? moment.utc(item.updated_at).format('DD-MM-YYYY hh:mm:ss A') : " - "}</td>
            ),
        },
        {
            name: 'updated_by_name',
            label: 'Updated By',
            toggleDisabled: true,
            headerStyle: { minWidth: "100px" },
        },

        {
            name: 'status',
            label: 'Status',
            toggleDisabled: true,
            headerStyle: { maxWidth: "90px" },
            renderBody: (item) => (
                <td className="text-center !tw-px-2 !tw-py-3">
                    {item?.status == 0 ? (
                        <Chip className="tw-gap-1" variant="light" color="red" radius="md" size="sm" border="none"><span className="tw-capitalize">Inactive</span></Chip>
                    ) : (
                        <Chip className="tw-gap-1" variant="light" color="green" radius="md" size="sm" border="none"><span className="tw-capitalize">Active</span></Chip>
                    )}
                </td>
            ),
        },
        {
            name: 'Action',
            label: 'Action',
            headerStyle: { minWidth: "80px" },
            toggleDisabled: true,
            renderBody: (item) => (
                <td className="text-center !tw-px-2 !tw-py-3">
                    <Button variant='primary' size='sm' type='button' className='!tw-text-xs !tw-font-bold mr-2' onClick={() => ontriggerManualReportGenerate(item)}>
                        Run
                    </Button>
                </td>
            ),
        },
    ]

    return (
        <>
            <Modal title={ScheduleId ? "Updated Schedule Report" : "Add Schedule Report"} visible={isModal} footer="" onCancel={() => onCancel()} width={1500}>
            <div className="form-row" style={{ marginLeft: "10px" }}>
                <div className="form-group col-md-3"  id="grpeui">
                            <label for="inputPassword4 ">Report Subject :<span className='text-danger'>*</span> </label>
                            <input class="form-control"  id="Subject" name="Report_Subject" type="text" placeholder="" onChange={(e)=>{setReport({...report,report_subject:e.target.value})}} value={report?.report_subject}/>
                            <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }} > {Validation?.report_subject} </span>
                </div >
                <div className="form-group col-md-3"  id="grpeui">
                    <label for="inputPassword4 ">Report Description :<span className='text-danger'>*</span> </label>
                    <textarea class="form-control" id="description" name="report_description" type="text" placeholder="" onChange={(e)=>{setReport({...report,report_description:e.target.value})}} value={report?.report_description}/>
                    <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }} > {Validation?.report_description} </span>
                </div>
                </div>
                <fieldset className="customLegend">
                    <legend >Report Details</legend>
                    <div className="form-row" style={{ marginLeft: "10px" }}>
                        <div className="form-group col-md-3" id="grpeui">
                            <label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
                            <select className="form-control form-select" name="reporttype" required="required" onChange={(e) => { setReport({ ...report, name: ReportTypes.filter((item) => (item?.value === e.target.value))[0]?.label || '', type: e.target.value }) }} value={report?.type}>
                                <option value="">Report Type</option>
                                {ReportTypes?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
                            </select>
                            <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }} > {Validation?.report_type_id} </span>

                        </div>
                        <div className="form-group col-md-3" id="grpeui">
                            <label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
                            <select className="form-control form-select" name="reportextension" onChange={(e) => { setReport({ ...report, extension: e.target.value }) }} value={report?.extension}>
                                <option value="">Select Extension</option>
                                <option value="xls">XLS</option>
                                <option value="csv">CSV</option>
                            </select>
                            <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }} > {Validation?.file_extension} </span>

                        </div>

                    </div>
                </fieldset>
                <fieldset className="customLegend">
                    <legend >Schedule Details</legend>
                    <div className="form-row" style={{ marginLeft: "10px" }}>
                        <div className="form-group col-md-12" id="grpeui">
                            {/* <Cron value={value.cron_value} setValue={((e, value) => {
                                console.log(e, value)
                                setValue({ cron_value: e, select_period: value?.selectedPeriod })
                            })} allowedPeriods={['month', 'week', 'day']} showResultText={true}/> */}
                            {value.cron_value != null && <CronIndex
                                onChange={(e, text,type) => {
                                    console.log({ value: e, text ,type}, document.querySelector('.cron_builder .nav-link.active')?.innerText || '');
                                    if (e) {
                                        setValue({ cron_value: e, select_period: type })
                                    }
                                }}
                                value={value.cron_value}
                                showResultText={true}
                                showResultCron={true}

                            />}
                        </div>
                    </div>
                </fieldset>
                <fieldset className="customLegend">
                    <legend >Filters</legend>
                    <div class="form-row "
                        style={{ marginLeft: "10px" }} >
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label htmlFor="inputPassword4">
                                    Account<span class="text-danger">*</span>
                                </label>

                                <Select value={AccounData.filter((item) => Profile?.CATEGORY0?.includes(item.value)) || []} placeholder='Select account...' name='account' isMulti options={AccounData} onChange={(e) => {
                                    setProfile({ ...Profile, CATEGORY0: e.map((item) => (item.value)) })
                                }} />
                                <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }} > {Validation?.filter_object} </span>

                            </div>
                        </div>
                        {Profile.CATEGORY0.length > 0 && (
                            <div class="col-md-4 gagrp">
                                <div class="form-group">
                                    <label for="inputEmail4">CATEGORY1:<span class="text-danger">*</span></label>
                                    <Select value={GaData.filter((item) => Profile?.CATEGORY1?.includes(item.value)) || []} name='account' isMulti options={GaData} onChange={(e) => {
                                        setProfile({ ...Profile, CATEGORY1: e.map((item) => (item.value)) })
                                    }} />
                                </div>
                            </div>
                        )}
                        {Profile.CATEGORY1.length > 0 && Profile.CATEGORY0.length > 0 && (
                            <div class="col-md-4 circlegrp">
                                <div class="form-group">
                                    <label for="inputEmail4">CATEGORY2:<span class="text-danger">*</span></label>
                                    <Select value={CircleData.filter((item) => Profile?.CATEGORY2?.includes(item.value)) || []} name='account' isMulti options={CircleData} onChange={(e) => {
                                        setProfile({ ...Profile, CATEGORY2: e.map((item) => (item.value)) })
                                    }} />
                                </div>
                            </div>
                        )}
                        {Profile.CATEGORY2.length > 0 && Profile.CATEGORY1.length > 0 && Profile.CATEGORY0.length > 0 && (
                            <div class="col-md-4 divisiongrp">
                                <div class="form-group">
                                    <label for="inputEmail4">CATEGORY3:<span class="text-danger">*</span></label>
                                    <Select value={DivisionData.filter((item) => Profile?.CATEGORY3?.includes(item.value)) || []} name='account' isMulti options={DivisionData} onChange={(e) => {
                                        setProfile({ ...Profile, CATEGORY3: e.map((item) => (item.value)) })
                                    }} />
                                </div>
                            </div>
                        )}
                        {Profile.CATEGORY3.length > 0 && Profile.CATEGORY2.length > 0 && Profile.CATEGORY1.length > 0 && Profile.CATEGORY0.length > 0 && (
                            <div class="col-md-4 subdivisiongrp">
                                <div class="form-group">
                                    <label for="inputEmail4">CATEGORY4:<span class="text-danger">*</span></label>
                                    <Select value={SubdevisionData.filter((item) => Profile?.CATEGORY4?.includes(item.value)) || []} name='account' isMulti options={SubdevisionData} onChange={(e) => {
                                        setProfile({ ...Profile, CATEGORY4: e.map((item) => (item.value)) })
                                    }} />
                                </div>
                            </div>
                        )}
                        {Profile.CATEGORY4.length > 0 &&
                            Profile.CATEGORY3.length > 0 &&
                            Profile.CATEGORY2.length > 0 &&
                            Profile.CATEGORY1.length > 0 &&
                            Profile.CATEGORY0.length > 0 && (
                                <div class="col-md-4 areagrp">
                                    <div class="form-group">
                                        <label for="inputEmail4">CATEGORY5:<span class="text-danger">*</span></label>
                                        <Select value={AreaData.filter((item) => Profile?.CATEGORY5?.includes(item.value)) || []} name='account' isMulti options={AreaData} onChange={(e) => {
                                            setProfile({ ...Profile, CATEGORY5: e.map((item) => (item.value)) })
                                        }} />
                                    </div>
                                </div>
                            )}
                        <div class="col-md-4 areagrp">
                            <div class="form-group" style={{ width: '100%' }}>
                                <label htmlFor="inputPassword4">Consumer Type</label>
                                <Select name='Consumer_Type' value={report?.Consumer_Type?.length > 0 ? consumerTypeArray.filter((item) => report?.Consumer_Type?.includes(item?.value)) : []} isMulti options={consumerTypeArray} onChange={(e) => {
                                    setReport({ ...report, Consumer_Type: e.map((item) => (item.value)) })
                                }} />
                            </div>
                        </div>
                    </div>

                </fieldset>
                <fieldset className="customLegend">
                    <legend >Other Details</legend>
                        <div className='d-flex'>
                        <div className="form-group col-md-3" id="grpeui">
                            <label for="inputPassword4">Send via :<span className='text-danger'>*</span> </label>
                            <Select name='Table' isMulti options={[{ value: 'Email', label: 'Email' }]} onChange={(e) => setReport({ ...report, send_type: e.map((item) => (item.value)) })} value={report?.send_type?.length > 0 ? report?.send_type.map((item) => ({ value: item, label: item })) : []} />
                            <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }} > {Validation?.report_send_type} </span>

                        </div>
                        <div className="form-group col-md-3"  id="grpeui">
                            <label for="inputPassword4">Send To :<span className='text-danger'>*</span> </label>
                            <Select name='Table' isMulti options={UserList} onChange={(e) => setReport({ ...report, Users: e.map((item) => (item.value)) })} value={report?.Users.length > 0 ? UserList.filter((item) => report?.Users.includes(item?.value)) : []} />
                            <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }} > {Validation?.send_user_list} </span>

                        </div>
                       
                    </div>
                </fieldset>
                <div className='d-flex ml-2 mt-3 '>
                    <div className='btn btn-danger mr-2' onClick={onHandalSubmit}>Submit</div>
                    <div className='btn btn-secondary' onClick={onCancel}>Cancel</div>
                </div>
            </Modal>
            <section class="section">
                <section class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header p-2" style={{ justifyContent: "space-between" }}>
                                    <h4></h4>
                                    <div className="card-header-action">
                                        <a className="btn btn-info text-white mr-2" onClick={() => setIsModal(true)}>
                                            <BootstrapTooltip title="Add">
                                                <span>
                                                    <i
                                                        className="fas fa-plus  text-white"
                                                        style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
                                                    ></i>
                                                </span>
                                            </BootstrapTooltip>
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                <div className='tw-flex tw-relative tw-w-full tw-max-h-[65dvh] tw-h-screen tw-overflow-y-auto tw-grow px-4'>
                                        {ReportScheduleData.length > 0 ? <div className="table-responsive">

                                            <table id="table_id" className="table text-center font-12 border mb-1">
                                                    <thead className='!tw-text-xs tw-sticky tw-z-10 tw-top-0 tw-uppercase tw-bg-gray-100 border-bottom  !tw-border-b-gray-300 tw-rounded-lg tw-h-10'>
                                                        <tr className='!tw-font-bold !tw-text-gray-700' >
                                                            {columnConfig.map((column) => (
                                                                <th className='tw-px-2 tw-py-3' key={column.name} style={column?.headerStyle ? column?.headerStyle : {}} >
                                                                    {column?.renderHeader ? column.renderHeader() : column.label}
                                                                </th>

                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ReportScheduleData.length > 0 && ReportScheduleData.map((item, id) => (
                                                            <tr className={`  ${ScheduleDetails?.unique_id === item?.unique_id ? ' tw-bg-indigo-100/80' : "hover:tw-bg-gray-50"}`} key={id}>
                                                                {columnConfig.map((column) => (
                                                                    column?.renderBody ?
                                                                        column.renderBody(item) :
                                                                        <td className='!tw-px-2 !tw-py-3' >{item?.[column?.name] ?? '-'}</td>
                                                                )
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            
                                        </div> : <div className='text-center tw-w-[100%]'><h4 >No Data Found</h4></div>}


                                        {isSideExapnded && <div className='tw-flex !tw-relative  tw-w-[80%] tw-overflow-auto tw-grow tw-bg-white task-details'>
                                            <div className={classNames('!tw-border-r-gray-300 tw-h-screen', isSideExapnded ? '  border-end tw-w-[100%]' : 'animate__fadeInLeft tw-flex-grow')}>
                                                <div className='tw-flex tw-flex-col tw-w-full'>
                                                    <div className='tw-w-full tw-flex tw-items-center border-bottom tw-justify-between !tw-border-b-gray-300 lg:tw-pl-5 tw-pr-2 tw-py-3 md:tw-px-0'>
                                                    <div className='tw-flex tw-items-center tw-gap-1'>
                                                            <b className='tw-text-base 2xl:tw-text-lg'>SRNO : {ScheduleDetails?.u_sr_no}</b>
                                                        </div>
                                                        <div className='tw-flex tw-gap-2 tw-items-center tw-mr-2'>

                                                            <div class="dropdown">
                                                                <button class={`btn ${ScheduleDetails?.status == 1 ? 'btn-success' : ScheduleDetails?.status == 0 ? 'btn-danger' : ScheduleDetails?.status == 2 ? 'btn-info' : ScheduleDetails?.status == 3 ? 'btn-dark' : 'btn-warning'} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                                                    {ScheduleDetails?.status == 1 ? 'Active' : ScheduleDetails?.status == 0 ? 'InActive' : ScheduleDetails?.status == 2 ? 'Under Maintenance' : ScheduleDetails?.status == 3 ? 'Stand By' : 'Pending Configuration'}
                                                                </button>
                                                                <div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
                                                                    {ScheduleDetails?.status != 1 ? <a class="dropdown-item text-success" onClick={onActive}>Active</a> : ''}
                                                                    {ScheduleDetails?.status != 0 ? <a class="dropdown-item text-danger" onClick={onInActive}>InActive</a> : ''}
                                                                </div>
                                                            </div>

                                                            <Separator type="vertical" className="tw-h-8 tw-w-[0.5px] tw-bg-gray-300" />

                                                            <div className='tw-inline-flex border !tw-border-indigo-500 tw-rounded-md tw-shadow-sm tw-relative tw-overflow-hidden'>

                                                                <Button className='tw-rounded-none tw-bg-transparent !tw-border-transparent hover:tw-bg-indigo-100 tw-px-2' variant='outline' size='sm' onClick={() => { handleRowClick() }}  >
                                                                    <Pencil size={16} />
                                                                </Button>

                                                            </div>

                                                            <Separator type="vertical" className="tw-h-8 tw-w-[0.5px] tw-bg-gray-300" />

                                                            <Button size='sm' variant='ghost' className='tw-p-1 !tw-border-transparent tw-bg-transparent tw-text-red-400 hover:tw-text-red-600 hover:tw-bg-red-100' onClick={() => {
                                                                setIsSideExapnded(false)
                                                                setScheduleDetails({})
                                                            }}>
                                                                <X size={20} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
                                                    {/* <div className='tw-float-right mr-2 mt-2'>
                                                        <button style={{ marginLeft: '5px' }} className="tw-border tw-py-1 tw-px-[0.4rem] tw-text-[#212529] tw-border-solid tw-border-[#ddd] tw-rounded " onClick={() => { handleRowClick() }} >
                                                            <i className='fas fa-edit'></i>
                                                        </button>
                                                    </div> */}
                                                    <div className='tw-px-4'>
                                                    <div className="tw-my-4 task-details__info tw-"  >

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Schedule Unique Id :- </span>
                                                            <b className='task-detail__info-value'>{ScheduleDetails?.unique_id}</b>
                                                        </div>

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Schedule Time :- </span>
                                                            <b className='task-detail__info-value'>{JSON.parse(ScheduleDetails.schedule_time || '{}')?.cron_value ? cronstrue.toString(JSON.parse(ScheduleDetails.schedule_time || '{}')?.cron_value) : ''}</b>
                                                        </div>

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Report Type :-</span>
                                                            <b className='task-detail__info-value'>{ScheduleDetails?.FRONT_TYPE_NAME}</b>
                                                        </div>

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Report Subject :- </span>
                                                            <b className='task-detail__info-value'>{ScheduleDetails?.report_subject}</b>
                                                        </div>
                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Report Description :-</span>
                                                            <b className='task-detail__info-value'>{ScheduleDetails?.report_description}</b>
                                                        </div>
                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">File Extention :- </span>
                                                            <b className='task-detail__info-value'>{ScheduleDetails?.file_extension}</b>
                                                        </div>

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Sent to Users :-</span>
                                                            <b className='task-detail__info-value tw-flex tw-items-center tw-gap-2 tw-flex-wrap'>
                                                                {UserList.filter((users) => (JSON.parse(ScheduleDetails?.send_user_list || '[]').includes(users?.value)))?.map((item, id) => (
                                                                    <>
                                                                        <Chip data-tooltip-id={`tag-tooltip-${id}`}
                                                                            data-tooltip-content={`Email: ${item?.EMAIL || ''}\nMobile: ${item?.MOBILE || ''}`}
                                                                            className="tw-gap-1" variant="light" color="gray" radius="md" size="sm" border="none">{item?.label}</Chip>
                                                                        <Tooltip id={`tag-tooltip-${id}`} style={{ fontSize: '12px', padding: '4px 12px', whiteSpace: 'pre-line' }} />
                                                                    </>
                                                                ))}
                                                            </b>
                                                        </div>

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Created At :-</span>
                                                            <b className='task-detail__info-value'>{moment.utc(ScheduleDetails.created_at).format('DD-MM-YYYY hh:mm:ss A')}</b>
                                                        </div>

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Created By :-</span>
                                                            <b className='task-detail__info-value'>{ScheduleDetails.updated_by_name}</b>
                                                        </div>

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Updated At :-</span>
                                                            <b className='task-detail__info-value'>{moment.utc(ScheduleDetails.updated_at).format('DD-MM-YYYY hh:mm:ss A')}</b>
                                                        </div>

                                                        <div className="task-detail__info">
                                                            <span className="task-detail__info-label">Updated By :-</span>
                                                            <b className='task-detail__info-value'>{ScheduleDetails.created_by_name}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                    {/* <div className='tw-px-4 mt-5'>
                                                        <div className='tw-grid tw-grid-cols-5 tw-gap-4 tw-mt-4'>

                                                            <div className='tw-col-span-2'>
                                                                Schedule Unique Id:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.unique_id}</b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                Schedule Time:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {JSON.parse(ScheduleDetails.schedule_time || '{}')?.cron_value ? cronstrue.toString(JSON.parse(ScheduleDetails.schedule_time || '{}')?.cron_value) : ''}</b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                Report Type:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.FRONT_TYPE_NAME}</b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                Report Subject:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.report_subject||'-'}</b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                Report Description:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.report_description||'-'}</b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                File Extention:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {ScheduleDetails?.file_extension}</b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                Sent to Users:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                            <b className='task-detail__info-value tw-flex tw-items-center tw-gap-2 tw-flex-wrap'>
                                                                {UserList.filter((users) => (JSON.parse(ScheduleDetails?.send_user_list || '[]').includes(users?.value)))?.map((item, id) => (
                                                                    <>
                                                                        <Chip data-tooltip-id={`tag-tooltip-${id}`}
                                                                            data-tooltip-content={`Email: ${item?.EMAIL || ''}\nMobile: ${item?.MOBILE || ''}`}
                                                                            className="tw-gap-1" variant="light" color="gray" radius="md" size="sm" border="none">{item?.label}</Chip>
                                                                        <Tooltip id={`tag-tooltip-${id}`} style={{ fontSize: '12px', padding: '4px 12px', whiteSpace: 'pre-line' }} />
                                                                    </>
                                                                ))}
                                                            </b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                Updated At:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {moment.utc(ScheduleDetails.updated_at).format('DD-MM-YYYY hh:mm:ss A')}</b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                Updated By:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {ScheduleDetails.updated_by_name}</b>
                                                            </div><div className='tw-col-span-2'>
                                                                Created At:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {moment.utc(ScheduleDetails.created_at).format('DD-MM-YYYY hh:mm:ss A')}</b>
                                                            </div>
                                                            <div className='tw-col-span-2'>
                                                                Created By:-
                                                            </div>
                                                            <div className='tw-col-span-3'>
                                                                <b className='tw-text-base tw-mr-2'> {ScheduleDetails.created_by_name}</b>
                                                            </div>

                                                        </div>
                                                    </div> */}
                                                </div>

                                                <SlidingTabs onTabChange={(e) => { console.log(e) }}>

                                                    <TabPane id="1" title="Schedule Run History">
                                                        <div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
                                                            <ScheduleRunHistory ScheduleDetails={ScheduleDetails} />
                                                        </div>
                                                    </TabPane>
                                                    <TabPane id="2" title="Activity Logs">
                                                        <div className='tw-overflow-y-auto tw-pb-10 m-3' style={{ backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 10px 0px' }}>
                                                            <ActivityLogs ScheduleDetails={ScheduleDetails} />
                                                        </div>
                                                    </TabPane>
                                                </SlidingTabs>
                                            </div>
                                        </div>}
                                        
                                    </div>
                                    {
                                        ReportScheduleData.length > 0 && (
                                            //  {/* // EUMSOPS-T632   change className  */}
                                            <div className="d-flex justify-content-between tw-mt-2 tw-pt-2 md:tw-px-8 tw-px-4 tw-py-3">
                                                <div>Total Number Of Row {ReportScheduleData?.length ?? 0}</div>
                                                <div className="dataTables_length" id="table_id_length">
                                                    <label>
                                                        Show{" "}
                                                        <select
                                                            name="table_id_length"
                                                            aria-controls="table_id"
                                                            className=""
                                                            onChange={(e) => setPageSize(e.target.value)}
                                                            value={PageSize}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>{" "}
                                                        entries
                                                    </label>
                                                </div>
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={currentPage}
                                                    totalCount={ReportScheduleData?.length || 1}
                                                    pageSize={PageSize}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}


const ActivityLogs = (props) => {
    const [ActivityData, setActivityData] = useState([])
    const [Loader, setLoader] = useState(false)

    const ongetActivityData = async () => {
        setLoader(true)
        const data = await getgsmReportActivityLogs({ log_type: 'Schedule Report Activity', schedule_report_id: props?.ScheduleDetails?.unique_id })
        if (data.success === true) {
            const groupByDate = data.data.data?.reduce((acc, log) => {
                const date = moment.utc(log.created_at).format('DD-MM-YYYY');
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(log);
                return acc;
            }, {});
            const groupedLogs = Object.keys(groupByDate).map(date => {
                return {
                    date,
                    logs: groupByDate[date]
                }
            })
            setActivityData(groupedLogs)
            setLoader(false)
        } else {
            console.log('something want wrong')
            setLoader(false)
        }
    }

    useEffect(() => {
        ongetActivityData()
    }, [props])
    return (
        <>
            {Loader ? <div className="example">
                <Spin size="large" />
            </div> : <>
                <div className="row tw-overflow-y-auto tw-w-[100%]">
                    <div className="timeline-card">

                        {ActivityData.length > 0 && ActivityData.map((logP, index) => (
                            <div className="timeline">
                                <div className="date-section" key={index}>
                                    <div className="date">{logP.date}</div>
                                </div>
                                {logP?.logs.map((log, index) => (
                                    <div className="timeline-item" key={index}>
                                        <div className="timeline-icon">{logP?.logs.length - index}</div>
                                        <div className="timeline-data">
                                            <div className="activity-log-avatar">
                                                <span>{log?.created_by_name[0]}</span>
                                            </div>
                                            <div className="timeline-content">
                                                <div className="activity-log-activity">
                                                    <p className="activity-log-activity-creator">{log?.created_by_name} has </p>
                                                    <p className="activity-log-activity-type">{log?.activity_type}</p>
                                                    <p className="activity-log-activity-static">Updated <b>({Object.keys(JSON.parse(log?.new_changed_json || '{}')).join(', ')})</b></p>
                                                    <p className="activity-log-activity-column">{log?.updated_columns && log?.updated_columns?.length > 0 ? log?.updated_columns?.join(", ") : ""}</p>
                                                </div>
                                                <div className="activity-log-changes">
                                                    <div key={index}>
                                                        <pre style={{ width: '300px' }}>{JSON.stringify(JSON.parse(log?.old_json || '{}'), null, 2)}</pre>
                                                        <span className="arrow">→</span>
                                                        <pre style={{ width: '300px' }}>{JSON.stringify(JSON.parse(log?.new_changed_json || '{}'), null, 2)}</pre>
                                                    </div>
                                                </div>

                                                <p className="activity-log-date">at {moment.utc(log.created_at).format('hh:mm:ss A')}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                {ActivityData.length == 0 && <h4 className='tw-text-center mt-2'>No Activity</h4>}
            </>}
        </>
    )
}

const ScheduleRunHistory = (props) => {
    const [LogDetails, setLogDetails] = useState([])
    const [LogModal, setLogModal] = useState(false)
    const [LogLoader, setLogLoader] = useState(false)
    const [Info, setInfo] = useState({})
    const [InfoModel, setInfoModel] = useState(false)
    const userInfo = JSON.parse(window.localStorage.getItem('user_info'))

    const onHandaleScheduleLogs = async (id, title) => {
        setLogModal(true)
        setLogLoader(true)
        const data = await getReportScheduleCount(userInfo?.UNIQUE_ID, userInfo?.ROLE, props?.ScheduleDetails?.unique_id)
        if (data.success == true) {
            setLogDetails(data?.data?.data)
            setLogLoader(false)

        } else {
            setLogLoader(false)

        }
    }

    useEffect(() => {
        onHandaleScheduleLogs()
    }, [props])

    const onChangeInfo = (item) => {
        setInfo(item)
        setInfoModel(true)
    }
    return (
        <>
            <Modal title="Info" visible={InfoModel} footer="" onCancel={() => {
                setInfoModel(false)
                setInfo([])
            }} width={800}>
                <div class="table-responsive">
                    <table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "50%" }}>Fields</th>
                                <th style={{ width: "50%" }}>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(Info).map(([keys, value]) => (
                                <>
                                    <tr>
                                        <td style={{ width: "50%" }}>{keys}</td>
                                        <td style={{ width: "50%" }}>{value}</td>
                                    </tr>

                                </>
                            ))}


                        </tbody>
                    </table>
                </div>
            </Modal>
            {LogLoader ? <div className="example">
                <Spin size="large" />
            </div> : <><div className=''>
                <table class="table table-striped border tw-text-xs tw-w-full tw-text-center ">
                    <thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
                        <tr>
                            <th>SRNO</th>
                            <th>Execute Time</th>
                            <th>Status</th>
                            <th>Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LogDetails.length > 0 && LogDetails.map((info, id) => (
                            <tr>
                                <td>{info?.SRNO}</td>
                                <td>{moment.utc(info?.CREATED_AT).format('DD-MM-YYYY hh:mm:ss A')}</td>
                                <td>{info?.STATUS === -1 ? moment(moment.utc().add(moment().utcOffset(), "m").format()).diff(moment(moment.utc(info?.CREATED_AT).format()), 'minutes') > 5 ? <span class="badge badge-secondary">Time Out</span> : <span class="badge badge-primary">Process Start</span> : info?.STATUS === 2 ? <span class="badge badge-danger">Failed</span> : info?.STATUS === 3 ? <span class="badge badge-secondary">Time Out</span> : <span class="badge badge-success">Success</span>}</td>
                                <td className="text-center">
                                    <a
                                        onClick={() =>
                                            onChangeInfo(
                                                info
                                            )
                                        }
                                        class="btn-sm btn-primary btn-sm font-14 text-center"
                                    >
                                        <i
                                            class="fa fa-info-circle "
                                            aria-hidden="true"
                                            style={{ color: "white" }}
                                        ></i>
                                    </a>
                                </td>
                            </tr>
                        ))}

                    </tbody>

                </table>
            </div></>}
        </>
    )
}