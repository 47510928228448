import React, { useEffect, useState } from "react";
import { Users } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import GeoLocation from "../../Commoncomponet/GecodeLocation";
import {
  AddMeterDataINGSM,
  AddModemDataINGSM,
  getAccount,
  getAllMeter,
  getAllModem,
  getArea,
  getAreaById,
  getAttachmentBymechanicalid,
  getAttachmentBymeterid,
  getCircle,
  getCircleById,
  getCountUseToConsumerInIMEI,
  getDivision,
  getDivisionById,
  getGa,
  getGaById,
  GetIMEIWiseModemData,
  getMechanicalByID,
  getMechanicalByMultipalID,
  getMechanicalMeterManufacturer,
  getMeterByID,
  getMeterByMultipalID,
  getModemByID,
  GetModemTypeWiseIMEI,
  getProfilNameByAccountId,
  getRolesById,
  getSubdevision,
  getSubdevisionById,
  geturls3,
  InsertGsmData,
  InsertModemDetails,
  MeterConfigTableCreate,
  Table_Get_All_City_by_State,
  Table_Get_All_Country,
  Table_Get_All_State_by_Country,
  UpdateModemDetails,
  uploadsFiles,
} from "../../Commoncomponet/Utils";
import { Modal, Space, DatePicker } from "antd";
import moment from "moment";
import MapPicker from "react-google-map-picker";
import { ContryCodeData } from "../Login/Contrycode";
import $ from "jquery";
import swal from "sweetalert2";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MeterUrl } from "../../Commoncomponet/common";
import { socket } from "../../Commoncomponet/common";
import clockPending from "../../assets/img/icons8-clock.gif";
import TimeZonelist from "../../Commoncomponet/TimezoneList.json";

const DefaultLocation = { lat: null, lng: null };
const DefaultZoom = 10;
window.Swal = swal;

export default function AddGsm() {
  const [isModalMeter, setisModalMeter] = useState(false);
  const [isModalHeader, setisModalHeader] = useState("");
  const [MeterDataAttach, setMeterDataAttach] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [SubdevisionData, setSubdevisionData] = useState([]);
  const [DivisionData, setDivisionData] = useState([]);
  const [CircleData, setCircleData] = useState([]);
  const [GaData, setGaData] = useState([]);
  const [AccounData, setAccountData] = useState([]);
  const [AreaData, setAreaData] = useState([]);
  const [ModemData, setModemData] = useState([]);
  const [MeterData, setMeterData] = useState([]);
  const [MechanicalData, setMechanicalData] = useState([]);
  const [MechanicalFilterData, setMechanicalFilterData] = useState([]);
  const [Profile, setProfile] = useState({
    account: "",
    ga: "",
    circle: "",
    division: "",
    subdivision: "",
    area: "",
  });
  const [MeterInputs, setMeterInputs] = useState({});
  const [ModemInputs, setModemInputs] = useState({});
  const [remark, setRemark] = useState("");
  const [Consumer, setConsumer] = useState({
    cunsumerId: "",
    Installation: "",
    BPN: "",
  });
  const [ConsumerType, setConsumerType] = useState({
    MeterId: "",
    ModemId: "",
    IMEI: "",
    MSRNO: "",
    PSNO: "",
    CF: "1",
    METERSRNO: "",
    CONSUMER_TYPE: "",
    CONVSERNUM: "",
    mechanicalId: "",
    TIMEZONE: "",
    mechanical_make:''
  });
  const [AccountInput, serAccountInput] = useState({
    address_compony_name: "",
    address_building_no: "",
    address_street_area: "",
    address_landmark_colony: "",
    address_longitude: "",
    address_lattitude: "",
    address_pin_code: "",
    address_city_id: "",
    address_state_id: "",
    address_country_id: "",
  });
  const [contactDetails, setcontactDetails] = useState([]);
  const [Attachment, setAttachment] = useState([]);
  const [installDetails, setinstallDetails] = useState({
    Installation_Person_First_Name: "",
    Installation_Person_Last_Name: "",
    Installation_Person_Email: "",
    Installation_Person_Work_Number: "",
    Installation_Person_Mobile_Number: "",
    Installation_Person_Position: null,
    Installation_Date_And_Time: "",
  });
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [conCode, setConCode] = useState("+91");
  const [ContactconCode, setContactconCode] = useState("+91");
  const [scripcodeid, setScripcodeid] = useState("");
  const [permision, setpermision] = useState({});
  const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
  const [btnFlag, setBtnFlag] = useState(false);
  const [IMEIList, setIMEIList] = useState([]);
  const [isModalModem, setisModalModem] = useState(false);
  const [options, setoptions] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [Citydata, setCitydata] = useState([]);
  const [MCMAKEData, setMCMAKEData] = useState([]);
  const [MechanicalInputs, setMechanicalInputs] = useState({});

  const getCountry = async () => {
    const data = await Table_Get_All_Country();
    if (data.success == true) {
      setoptions(data.data.data);
    } else {
      console.log("something want wrong");
    }
  };

  const getState = async (id) => {
    const data = await Table_Get_All_State_by_Country(id);
    if (data.success == true) {
      setstateData(data.data.data);
    } else {
      console.log("something want wrong");
    }
  };

  const getCity = async (id) => {
    const data = await Table_Get_All_City_by_State(id);
    if (data.success == true) {
      setCitydata(data.data.data);
    } else {
      console.log("something want wrong");
    }
  };

  const ongetMechanicalMeterManufacturer=async()=>{
    const data=await getMechanicalMeterManufacturer()
    if(data.success==true){
      setMCMAKEData(data.data.data)
    }else{
      console.log("something want wrong");
    }
  }

  useEffect(() => {
    getCountry();
    ongetMechanicalMeterManufacturer()
  }, []);
  const history = useHistory();
  useEffect(() => {
    if (ConsumerType.MeterId !== "") {
      onHandaleMeter();
      setScripcodeid(ConsumerType.ModemId + "_" + ConsumerType.MeterId);
    } else {
      setMeterInputs({});
      setScripcodeid(ConsumerType.ModemId + "_" + ConsumerType.MeterId);
    }
  }, [ConsumerType.MeterId]);

  useEffect(() => {
    if (ConsumerType.mechanicalId !== "") {
      onHandaleMechanical();
    } else {
      setMechanicalInputs({});
    }
  }, [ConsumerType.mechanicalId]);

  const onHandaleMeter = async () => {
    const data = await getMeterByID(ConsumerType.MeterId);
    if (data.success === true) {
      data.data.data.length > 0
        ? setMeterInputs(data.data.data[0])
        : setMeterInputs({});
    } else {
      console.log("something want wrong");
    }
  };

  const onHandaleMechanical = async () => {
    const data = await getMechanicalByID(ConsumerType.mechanicalId);
    if (data.success === true) {
      data.data.data.length > 0
        ? setMechanicalInputs(data.data.data[0])
        : setMechanicalInputs({});
    } else {
      console.log("something want wrong");
    }
  };

  const onHandaleRoles = async () => {
    const data = await getRolesById(userInfo?.ROLE);
    if (data.success === true) {
      const obj = data.data.data[0];
      setpermision({
        ...obj,
        ROLES_PERMISSIONS_CATEGORY0: JSON.parse(
          obj.ROLES_PERMISSIONS_CATEGORY0
        ),
        ROLES_PERMISSIONS_CATEGORY5: JSON.parse(
          obj.ROLES_PERMISSIONS_CATEGORY5
        ),
        ROLES_PERMISSIONS_CATEGORY2: JSON.parse(
          obj.ROLES_PERMISSIONS_CATEGORY2
        ),
        ROLES_PERMISSIONS_CATEGORY3: JSON.parse(
          obj.ROLES_PERMISSIONS_CATEGORY3
        ),
        ROLES_PERMISSIONS_CATEGORY1: JSON.parse(
          obj.ROLES_PERMISSIONS_CATEGORY1
        ),
        ROLES_PERMISSIONS_CATEGORY4: JSON.parse(
          obj.ROLES_PERMISSIONS_CATEGORY4
        ),
      });
    } else {
      console.log("something want wrong");
    }
  };

  useEffect(() => {
    if (ConsumerType.ModemId !== "") {
      onHandaleModem();
      setScripcodeid(ConsumerType.ModemId + "_" + ConsumerType.MeterId);
    } else {
      setModemInputs({});
      setScripcodeid(ConsumerType.ModemId + "_" + ConsumerType.MeterId);
    }
  }, [ConsumerType.ModemId]);

  const onHandaleModem = async () => {
    const data = await getModemByID(ConsumerType.ModemId);
    if (data.success === true) {
      data.data.data.length > 0
        ? setModemInputs(data.data.data[0])
        : setModemInputs({});
    } else {
      console.log("something want wrong");
    }
  };

  const ongetGaById = async (id) => {
    const data1 = await getGaById(id);
    if (data1.success === true) {
      setGaData(data1.data.data);
    }
  };

  const ongetCircle = async (id) => {
    const data1 = await getCircleById(id);
    if (data1.success === true) {
      setCircleData(data1.data.data);
    }
  };
  const ongetSubdevisionById = async (id) => {
    const data1 = await getSubdevisionById(id);
    if (data1.success === true) {
      setSubdevisionData(data1.data.data);
    }
  };
  const ongetDivisionById = async (id) => {
    const data3 = await getDivisionById(id);
    if (data3.success === true) {
      setDivisionData(data3.data.data);
    }
  };
  const ongetArea = async (id) => {
    const data3 = await getAreaById(id);
    if (data3.success === true) {
      setAreaData(data3.data.data);
    }
  };
  const [NameObj, setNameObj] = useState({});
  const ongetProfilNameByAccountId = async (id) => {
    const data = await getProfilNameByAccountId({ ID: id });
    if (data.success === true) {
      setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || "{}"));
    } else {
      console.log("something want wrong");
    }
  };
  useEffect(() => {
    if (Profile.account !== "") {
      ongetGaById(Profile.account);
      setProfile({
        ...Profile,
        ga: "",
        circle: "",
        division: "",
        subdivision: "",
        area: "",
      });
      setCircleData([]);
      setSubdevisionData([]);
      setDivisionData([]);
      setAreaData([]);
      ongetProfilNameByAccountId(Profile.account);
    } else {
      setProfile({
        ...Profile,
        ga: "",
        circle: "",
        division: "",
        subdivision: "",
        area: "",
      });
      setGaData([]);
      setCircleData([]);
      setSubdevisionData([]);
      setDivisionData([]);
      setAreaData([]);
      setNameObj({});
    }
  }, [Profile.account]);

  useEffect(() => {
    if (Profile.ga !== "") {
      ongetCircle(Profile.ga);
      setProfile({
        ...Profile,
        circle: "",
        division: "",
        subdivision: "",
        area: "",
      });
      setSubdevisionData([]);
      setDivisionData([]);
      setAreaData([]);
    } else {
      setProfile({
        ...Profile,
        circle: "",
        division: "",
        subdivision: "",
        area: "",
      });
      setCircleData([]);
      setDivisionData([]);
      setSubdevisionData([]);
      setAreaData([]);
    }
  }, [Profile.ga]);

  useEffect(() => {
    if (Profile.circle !== "") {
      ongetDivisionById(Profile.circle);
      setProfile({ ...Profile, division: "", subdivision: "", area: "" });
      setAreaData([]);
      setSubdevisionData([]);
    } else {
      setProfile({ ...Profile, division: "", subdivision: "", area: "" });
      setAreaData([]);
      setDivisionData([]);
      setSubdevisionData([]);
    }
  }, [Profile.circle]);

  useEffect(() => {
    if (Profile.division !== "") {
      ongetSubdevisionById(Profile.division);
      setAreaData([]);
      setProfile({ ...Profile, subdivision: "", area: "" });
    } else {
      setProfile({ ...Profile, subdivision: "", area: "" });
      setSubdevisionData([]);
      setAreaData([]);
    }
  }, [Profile.division]);

  useEffect(() => {
    if (Profile.subdivision !== "") {
      ongetArea(Profile.subdivision);
      setProfile({ ...Profile, area: "" });
    } else {
      setProfile({ ...Profile, area: "" });
      setAreaData([]);
    }
  }, [Profile.subdivision]);
  const onHandaleSelection = async () => {
    const data = await getAccount();
    if (data.success === true) {
      setAccountData(data.data.data);
      const data6 = await getAllModem(true);
      if (data6.success === true) {
        setModemData(
          data6?.data?.data?.filter(
            (item) => item?.MODEM_TYPE == "MODTYID1655982592591"
          )
        );
      }
    } else {
      console.log("somthing want wrong");
    }
  };

  useEffect(() => {
    onHandaleRoles();
    onHandaleSelection();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
    setLocation({ ...DefaultLocation });
  };
  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
    serAccountInput({
      ...AccountInput,
      address_lattitude: lat,
      address_longitude: lng,
    });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  const handleChangeTask = async (e) => {
    const { name, value, id } = e.target;
    let updatedTask = [...contactDetails];
    if (name === "Work_no" || name === "Mobile") {
      updatedTask[id][name] = value.target.value;
    } else {
      console.log(updatedTask, id, name);
      updatedTask[id][name] = value.target.value.replace(/[\\"`\t\n]/g,"");
    }
    setcontactDetails([...updatedTask]);
  };

  const handleChangeAttachment = async (e) => {
    const { name, value, id } = e.target;
    let updatedTask = [...Attachment];
    if (name === "files") {
      var totalSizeMB = value.target.files[0].size / Math.pow(1024, 2);
      if (totalSizeMB >= 50) {
        swal.fire("", "File size should be under 50 MB", "error");
      } else {
        updatedTask[id][name] = value.target.files[0];
      }
    } else {
      updatedTask[id][name] = value.target.value.replace(/[\/\\"`\t\n]/g, "");
    }
    setAttachment([...updatedTask]);
  };
  const onAdd = () => {
    const initial = {
      Sr_No: contactDetails.length,
      salutation: " ",
      First_Name: "",
      Last_Name: "",
      Email: "",
      Mobile: "",
      Work_no: "",
      Designation: "",
      UserType: " ",
    };
    // if(contactDetails.length>0){
    const task = contactDetails[contactDetails.length - 1];
    contactDetails[contactDetails.length] = initial;
    setcontactDetails([...contactDetails]);
    // }
  };

  const onDelete = (id) => {
    const test = [...contactDetails];
    test.splice(id, 1);
    setcontactDetails([...test]);
  };

  const onAddAttachment = () => {
    const initial = {
      SRNO: Attachment.length,
      title: "",
      description: "",
      files: "",
      create_at: moment().format(),
      update_at: "",
    };
    // if(contactDetails.length>0){
    const task = Attachment[Attachment.length - 1];
    Attachment[Attachment.length] = initial;
    setAttachment([...Attachment]);
    // }
  };
  const onDeleteAttachment = (id) => {
    const test = [...Attachment];
    const data = test.filter((item, ids) => ids !== id);
    setAttachment([...data]);
  };
  const [CreateTableStatus, setCreateTableStatus] = useState({});
  const [tableDetails, settableDetails] = useState({});
  const [tableMeDetails, settableMeDetails] = useState({});
  const [ModemResult, setModemResult] = useState(false);
  const [MeterResult, setMeterResult] = useState({});

  useEffect(() => {
    socket.on("mqttcreatetable", (massges) => {
      if (massges?.MODEM_TYPE) {
        if (
          tableDetails?.UNIQUE_ID == massges?.UNIQUE_ID &&
          tableDetails?.MSGID == massges?.MSGID
        ) {
          setCreateTableStatus(massges);
          onhandalModemTableCreate(massges);
        }
      }
      if (massges?.METER_TYPE) {
        if (
          tableMeDetails?.UNIQUE_ID == massges?.UNIQUE_ID &&
          tableMeDetails?.MSGID == massges?.MSGID
        ) {
          setMeterResult(massges);
          onhandalModemTableCreate(massges);
        }
      }
    });
  }, [tableDetails]);

  const onhandalModemTableCreate = async (massges) => {
    if (
      massges?.MODEM_TYPE &&
      !massges?.METER_TYPE &&
      massges?.RESULT === "SUCCESS"
    ) {
      const objMod = {
        MODEM_TYPE: ConsumerType?.ModemId,
        MODEM_DATA: JSON.stringify({
          modem_make: ModemInputs?.MODEM_MAKE_NAME,
          modem_sku: ModemInputs?.SKU_NAME,
          modem_model: ModemInputs?.MODEM_MODEL_NAME,
          modem_firmware: ModemInputs?.MODEM_FIRMWARE_NAME,
          modem_hardware: ModemInputs?.MODEM_HARDWARE_NAME,
          modem_profile_name: ModemInputs?.MODEM_PROFILE_NAME,
        }),
        IMEI: ConsumerType?.IMEI,
        DEVICE_SERIAL_NO: ConsumerType?.MSRNO,
        PANEL_SERIAL_NO: ConsumerType?.PSNO,
        UNIQUE_TABLE_NAME_JSON: massges?.UNIQUE_TABLE_NAME,
        METER_DATA: JSON.stringify({
          meter_make: MeterInputs?.METER_MAKE_NAME,
          meter_protocol: "",
          meter_software_protocol: MeterInputs?.METER_SOFTWARE_PROTOCOL_NAME,
          meter_class: MeterInputs?.METER_CLASS_NAME,
          meter_hardware_protocol: MeterInputs?.METER_HARDWARE_PROTOCOL_NAME,
          meter_firmware: MeterInputs?.METER_FIRMWARE_NAME,
          meter_type: MeterInputs?.METER_TYPE_NAME,
          meter_categoy: MeterInputs?.METER_CATEGORY_NAME,
          meter_modem: MeterInputs?.MODEM_TYPE_NAME,
          MEMORY_STORAGE_IN_DAYS: MeterInputs?.MEMORY_STORAGE_IN_DAYS,
          modem_model: MeterInputs?.METER_MODEL_NUMBER_NAME,
        }),
        METER_TYPE: ConsumerType?.MeterId,
        SCRIPTDECODE_ID: scripcodeid,
      };
      await AddModemDataINGSM(massges?.UNIQUE_ID, objMod);
      if (ConsumerType?.ModemId) {
        history.push("/All-GSM");
      }
    }
    console.log(ConsumerType, massges);
    // if(ConsumerType?.ModemId&&ConsumerType?.MeterId){
    // 	if(massges?.MODEM_TYPE&&!massges?.METER_TYPE&&massges?.RESULT==='SUCCESS'){
    // 		await MeterConfigTableCreate(tableMeDetails)
    // 	}
    // 	if(massges?.METER_TYPE&&massges?.RESULT==='SUCCESS'){
    // 		const objMet={METER_DATA:JSON.stringify({meter_make: MeterInputs?.METER_MAKE_NAME,meter_protocol: "",meter_software_protocol: MeterInputs?.METER_SOFTWARE_PROTOCOL_NAME,meter_class: MeterInputs?.METER_CLASS_NAME,meter_hardware_protocol: MeterInputs?.METER_HARDWARE_PROTOCOL_NAME,meter_firmware: MeterInputs?.METER_FIRMWARE_NAME,meter_type: MeterInputs?.METER_TYPE_NAME,meter_categoy: MeterInputs?.METER_CATEGORY_NAME,meter_modem: MeterInputs?.MODEM_TYPE_NAME,MEMORY_STORAGE_IN_DAYS: MeterInputs?.MEMORY_STORAGE_IN_DAYS,modem_model: MeterInputs?.METER_MODEL_NUMBER_NAME}),METER_TYPE:ConsumerType?.MeterId,SCRIPTDECODE_ID:scripcodeid,UNIQUE_TABLE_NAME_JSON:massges?.UNIQUE_TABLE_NAME,MECHENICAL_SERIAL_NUMBER:ConsumerType?.METERSRNO||'',CONVSERNUM:ConsumerType?.CONVSERNUM||'',EVC:MeterInputs?.EVC}
    // 		await AddMeterDataINGSM(massges?.UNIQUE_ID,objMet)
    // 		if(ConsumerType?.ModemId&&ConsumerType?.MeterId){
    // 			history.push("/All-GSM");
    // 		}
    // 	}
    // }
  };

  const onSubmitGsmDate = async (e) => {
    e.preventDefault();
    setBtnFlag(true);

    if (Profile.account !== "") {
      if (ConsumerType?.ModemId) {
        var ErrorStr = "";
        if (ConsumerType?.IMEI == "" && ConsumerType?.MSRNO == "") {
          setBtnFlag(false);
          return toast.error("Please Input IMEI And Modem Serial No");
        } else if (ConsumerType?.MSRNO == "") {
          setBtnFlag(false);
          return toast.error("Please Input Modem Serial No");
        } else if (ConsumerType?.IMEI == "") {
          setBtnFlag(false);
          return toast.error("Please Input IMEI");
        }
      }
      if(ConsumerType?.MeterId!=''){
      	if(MeterInputs?.EVC_SRNO_ALLOW==1){
      		if(ConsumerType?.CONVSERNUM==""){
      			setBtnFlag(false)
      		   return toast.error('Please Input EVC Serial No')
      		}
      	 }else if(MeterInputs?.MECHANICAL_SRNO_ALLOW==1){
      	 if(ConsumerType?.METERSRNO==""){
      		setBtnFlag(false)
      		return toast.error('Please Input Mechanical Meter Serial No')
      	 }
      	}
      }
      const attechmentsobj = [];
      if (Attachment.length > 0) {
        await new Promise(
          async (resolve) =>
            Attachment.length > 0 &&
            Attachment.map(async (item) => {
              if (item.files !== "") {
                const formData = new FormData();
                formData.append("Images", item.files);
                const uploadImges = await uploadsFiles(formData);

                if (uploadImges.success === true) {
                  attechmentsobj.push({
                    ...item,
                    files: uploadImges.data.originalname,
                  });
                } else {
                  attechmentsobj.push({ ...item, files: "" });
                }
              } else {
                attechmentsobj.push({ ...item, files: "" });
              }
              if (attechmentsobj.length === Attachment.length) {
                resolve();
              }
            })
        );
      }
      if (attechmentsobj.length === Attachment.length) {
        const obj = {
          DEVICE_SERIAL_NO: ConsumerType.MSRNO,
          METER_DATA: JSON.stringify({
            meter_make: MeterInputs?.METER_MAKE_NAME,
            meter_protocol: "",
            meter_software_protocol: MeterInputs?.METER_SOFTWARE_PROTOCOL_NAME,
            meter_class: MeterInputs?.METER_CLASS_NAME,
            meter_hardware_protocol: MeterInputs?.METER_HARDWARE_PROTOCOL_NAME,
            meter_firmware: MeterInputs?.METER_FIRMWARE_NAME,
            meter_type: MeterInputs?.METER_TYPE_NAME,
            meter_categoy: MeterInputs?.METER_CATEGORY_NAME,
            meter_modem: MeterInputs?.MODEM_TYPE_NAME,
            MEMORY_STORAGE_IN_DAYS: MeterInputs?.MEMORY_STORAGE_IN_DAYS,
            modem_model: MeterInputs?.METER_MODEL_NUMBER_NAME,
          }),
          METER_TYPE: ConsumerType.MeterId,
          IMEI: ConsumerType.IMEI,
          MODEM_TYPE: ConsumerType.ModemId,
          MODEM_DATA: JSON.stringify({
            modem_make: ModemInputs?.MODEM_MAKE_NAME,
            modem_sku: ModemInputs?.SKU_NAME,
            modem_model: ModemInputs?.MODEM_MODEL_NAME,
            modem_firmware: ModemInputs?.MODEM_FIRMWARE_NAME,
            modem_hardware: ModemInputs?.MODEM_HARDWARE_NAME,
            modem_profile_name: ModemInputs?.MODEM_PROFILE_NAME,
          }),
          CAT0_ID: Profile.account,
          CAT1_ID: Profile.ga,
          CAT2_ID: Profile.circle,
          CAT3_ID: Profile.division,
          CAT4_ID: Profile.subdivision,
          CAT5_ID: Profile.area,
          ADDRESS: JSON.stringify(AccountInput),
          REMARKS: remark,
          CONTACT_PERSON: JSON.stringify(contactDetails),
          CUSTOMER_ID: Consumer.cunsumerId,
          add_by: userInfo?.UNIQUE_ID,
          SCRIPTDECODE_ID: scripcodeid,
          PANEL_SERIAL_NO: ConsumerType.PSNO,
          LATITUDE: AccountInput.address_lattitude,
          LONGITUDE: AccountInput.address_longitude,
          INSTALLATION_NUMBER: Consumer.Installation,
          BUSINESS_PARTNER_NUMBER: Consumer.BPN,
          CF: ConsumerType.CF,
          INSTALLATION_DETAILS: JSON.stringify({
            ...installDetails,
            Installation_Person_Work_Number:
              conCode + " " + installDetails.Installation_Person_Work_Number,
            Installation_Person_Mobile_Number:
              conCode + " " + installDetails.Installation_Person_Mobile_Number,
          }),
          INSTALLATION_ATTECHMENTS: JSON.stringify(attechmentsobj),
          MECHENICAL_SERIAL_NUMBER: ConsumerType.METERSRNO,
          CONSUMER_TYPE: ConsumerType?.CONSUMER_TYPE,
          CONVSERNUM: ConsumerType?.CONVSERNUM,
          EVC: MeterInputs?.EVC,
          MECHANICAL_TYPE: ConsumerType?.mechanicalId,
          mechanical_make:ConsumerType?.mechanical_make,
          MECHANICAL_DATA: JSON.stringify(MechanicalInputs),
          TIMEZONE: ConsumerType?.TIMEZONE,
          Mechanical:MeterInputs?.MECHANICAL_SRNO_ALLOW,
          EVC_ALLOW:MeterInputs?.EVC_SRNO_ALLOW
        };
        const data = await InsertGsmData(obj);
        console.log(data);
        if (data.success === true) {
          toast.success("Add Gsm Successfully");
          setBtnFlag(false);
          if (ConsumerType?.ModemId) {
            if (ConsumerType?.ModemId && ConsumerType?.MeterId) {
              settableMeDetails(JSON.parse(data?.data?.meterdata || "{}"));
              settableDetails(JSON.parse(data?.data?.data || "{}"));
              setModemResult(true);
              setTimeout(() => {
                if (Object.keys(tableDetails).length === 0) {
                  setCreateTableStatus({ RESULT: "Time Out" });
                  settableDetails({});
                }
                if (Object.keys(tableMeDetails).length === 0) {
                  setMeterResult({ RESULT: "Time Out" });
                  settableMeDetails({});
                }
              }, 5000);
            } else {
              settableDetails(JSON.parse(data?.data?.data || "{}"));
              setModemResult(true);
              setTimeout(() => {
                if (Object.keys(tableDetails).length === 0) {
                  setCreateTableStatus({ RESULT: "Time Out" });
                  settableDetails({});
                }
              }, 5000);
            }
          } else {
            history.push("/All-GSM");
          }
        } else if (data.err.response.status === 500) {
          toast.error("Not Allow Duplicate Fill");
          setBtnFlag(false);
        }else if (data.err.response.status === 503) {
          toast.error("Not Allow Duplicat Mechanical Meter Serial No");
          setBtnFlag(false)
       }else if (data.err.response.status === 504) {
        toast.error("Not Allow Duplicat EVC Serial No");
        setBtnFlag(false)
     } else if (data.err.response.status === 502) {
          toast.error("ScriptCodeId is not Valid");
          setBtnFlag(false);
        } else {
          toast.error(
            data?.err?.response?.data?.ERROR || "Something Want Wrong"
          );
          setBtnFlag(false);
        }
      } else {
        toast.error("File Proper Not Upload");
        setBtnFlag(false);
      }
    } else {
      toast.error("Please Fill Proper Details");
      setBtnFlag(false);
    }
  };

  const onMeterAttachment = async () => {
    setisModalHeader("Meter Attchments");

    const data = await getAttachmentBymeterid(ConsumerType?.MeterId);
    if (data.success === true) {
      setMeterDataAttach(data.data.data);
      setisModalMeter(true);
    } else {
      console.log("something want wrong");
    }
  };

  const onMechanicalAttachment = async () => {
    setisModalHeader("Mechanical Attchments");
    const data = await getAttachmentBymechanicalid(ConsumerType?.mechanicalId);
    if (data.success === true) {
      setMeterDataAttach(data.data.data);
      setisModalMeter(true);
    } else {
      console.log("something want wrong");
    }
  };

  const onDownloadFilesMeter = async (name) => {
    const data1 = await geturls3({
      bucketName: "gums.memighty.com",
      objectKey:
        isModalHeader == "Mechanical Attchments"
          ? `Mechanical_Driver_Attachments/${name}`
          : `Meter_Driver_Attachments/${name}`,
      expiration: 60,
    });
    console.log(data1);
    var link = document.createElement("a");
    link.href = data1.data.data;
    link.download = name;
    link.click();
  };
  useEffect(() => {
    if (ConsumerType.ModemId && ConsumerType.IMEI && ConsumerType.MSRNO) {
    } else {
      setConsumerType({
        ...ConsumerType,
        MeterId: "",
        METERSRNO: "",
        CONVSERNUM: "",
      });
    }
  }, [ConsumerType.ModemId, ConsumerType.IMEI, ConsumerType.MSRNO]);

  const onGetMeterDetails = async (value) => {
    const EVC_ID =
      ModemData.length > 0 &&
      ModemData.filter((item) => item?.MODEM_UNIQUE_ID == value)[0];
    const data = await getMeterByMultipalID({
      meterId: JSON.parse(EVC_ID?.EVC_DRIVER || "[]"),
    });
    if (data.success) {
      setMeterData(data.data.data);
    } else {
    }
  };

  const onGetMechanicalDetails = async (value) => {
    const EVC_ID =
      ModemData.length > 0 &&
      ModemData.filter((item) => item?.MODEM_UNIQUE_ID == value)[0];
    const data = await getMechanicalByMultipalID({
      mechanicalId: JSON.parse(EVC_ID?.MECHANICAL_DRIVER || "[]"),
    });
    if (data.success) {
      setMechanicalData(data.data.data);
    } else {
    }
  };

  const onGetModemTypeWiseIMEI = async (value) => {
    if (value !== "") {
      const data = await GetModemTypeWiseIMEI(value);
      if (data.success === true) {
        setIMEIList(
          data.data.data.map((item) => ({
            IMEI: item?.C4_IMEI,
            assign_length: item?.C9_CONSUMER_ASSIGN,
          }))
        );
      } else {
        console.log("something want wrong");
      }
    } else {
      setIMEIList([]);
      setConsumerType({
        ...ConsumerType,
        ModemId: "",
        MeterId: "",
        MSRNO: "",
        PSNO: "",
        IMEI: "",
      });
    }
  };
  const [flagBtn, setflegBtn] = useState(false);
  const [inputs, setInputs] = useState({
    modemId: "",
    IMEI: "",
    DEVICE_SERIAL_NO: "",
    PANEL_SERIAL_NO: "",
    CREATED_BY: userInfo?.UNIQUE_ID,
    Consumer_assign: "",
  });
  const [disabled, setDisable] = useState(true);
  const [Validation, setValidation] = useState({});
  const onGetIMEIWiseModemData = async (value) => {
    if (value === "open") {
      setisModalModem(true);
    } else {
      if (value !== "") {
        const dataCount = await getCountUseToConsumerInIMEI(value);
        if (dataCount.success) {
          console.log(
            dataCount,
            dataCount?.data?.data >=
              IMEIList.filter((item) => item?.IMEI === value)[0]?.assign_length
          );
          if (
            IMEIList.filter((item) => item?.IMEI === value)[0]
              ?.assign_length !== null
          ) {
            if (
              dataCount?.data?.data >=
              IMEIList.filter((item) => item?.IMEI === value)[0]?.assign_length
            ) {
              swal.fire(
                "",
                `This IMEI Already Assign ${
                  IMEIList.filter((item) => item?.IMEI === value)[0]
                    ?.assign_length
                } Consumer`,
                "error"
              );
            } else {
              const data = await GetIMEIWiseModemData(value);
              if (data.success === true) {
                setConsumerType({
                  ...ConsumerType,
                  IMEI: value,
                  MSRNO: data?.data?.data[0]?.C5_DEVICE_SERIAL_NO,
                  PSNO: data?.data?.data[0]?.C6_PANEL_SERIAL_NO,
                });
              } else {
                console.log("something want wrong");
              }
            }
          } else {
            swal.fire(
              "",
              `This IMEI Not Set Max Assign Consumer Value`,
              "error"
            );
          }
        }
      } else {
        setConsumerType({ ...ConsumerType, MSRNO: "", PSNO: "", IMEI: "",mechanicalId:'',mechanical_make:'' });
      }
    }
  };

  const validation = (name, value) => {
    switch (name) {
      case "modemId":
        if (!value) {
          return "Please input modemId!";
        } else {
          return "";
        }
      case "IMEI":
        if (!value) {
          return "Please input IMEI!";
        } else {
          return "";
        }
      case "DEVICE_SERIAL_NO":
        if (!value) {
          return "Please input Last DEVICE SERIAL NO!";
        } else {
          return "";
        }
      case "FirstName":
        if (!value) {
          return "Please input First Name!";
        } else {
          return "";
        }

      default: {
        return null;
      }
    }
  };
  const onsubmitHandale = async (e) => {
    e.preventDefault();
    var allErrors = {};
    setflegBtn(true);
    Object.keys(inputs).forEach((key) => {
      const error = validation(key, inputs[key]);
      if (error && error.length) {
        allErrors[key] = error;
      }
    });
    if (Object.keys(allErrors).length) {
      setflegBtn(false);
      return setValidation(allErrors);
    } else {
      const data = await InsertModemDetails(inputs);
      if (data.success === true) {
        const data = await GetModemTypeWiseIMEI(ConsumerType?.ModemId);
        if (data.success === true) {
          setIMEIList(
            data.data.data.map((item) => ({
              IMEI: item?.C4_IMEI,
              assign_length: item?.C9_CONSUMER_ASSIGN,
            }))
          );
        } else {
          console.log("something want wrong");
        }
        toast.success("Success Add Modem Details");
        setflegBtn(false);
        setisModalModem(false);
      } else if (data.err.response.status === 409) {
        toast.error("Duplicat Value Not Allow");
        setflegBtn(false);
      } else {
        toast.error("Something Want Wrong");
        setflegBtn(false);
      }
    }
  };

  const onCloseMassg = () => {
    setModemResult(false);
    history.push("/All-GSM");
  };

  return (
    <>
      <div className="main-content" style={{ minHeight: "562px" }}>
        <Modal
          visible={ModemResult}
          footer=""
          maskClosable={false}
          onCancel={() => onCloseMassg()}
          width={500}
        >
          <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">
            Getting Response For Table Create
          </h5>
          {ConsumerType?.ModemId ? (
            <h5
              class="modal-title text-center mt-2 "
              id="meter_data_mqtt_info_txt"
            >
              {!CreateTableStatus?.RESULT &&
              CreateTableStatus?.RESULT !== "SUCCESS" ? (
                <img
                  className="mr-2"
                  src={clockPending}
                  height="20px"
                  width={"20px"}
                ></img>
              ) : CreateTableStatus?.RESULT === "SUCCESS" ? (
                <i className="fas fa-check mr-2" style={{ color: "green" }}></i>
              ) : (
                <i className="fas fa-times mr-2" style={{ color: "red" }}></i>
              )}{" "}
              Modem Tables{" "}
              {CreateTableStatus?.RESULT &&
              CreateTableStatus?.RESULT !== "SUCCESS"
                ? `(${CreateTableStatus?.RESULT})`
                : ""}
            </h5>
          ) : (
            ""
          )}
          {/* {ConsumerType?.MeterId?<h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!MeterResult?.RESULT&&MeterResult?.RESULT!=='SUCCESS'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:MeterResult?.RESULT==='SUCCESS'?<i className='fas fa-check mr-2' style={{color:'green'}}></i>:<i className='fas fa-times mr-2' style={{color:'red'}}></i>} Meter Tables {MeterResult?.RESULT&&MeterResult?.RESULT!=='SUCCESS'?`(${MeterResult?.RESULT})`:''}</h5>:''} */}
          <div class=""></div>
          <div class="text-center" style={{ marginTop: "20px" }}>
            <input
              type="button"
              class="btn btn-danger "
              value="Cancel"
              id="meter_data_mqtt_cancel_button"
              name="meter_data_mqtt_cancel_button"
              onClick={() => onCloseMassg()}
            />
          </div>
        </Modal>
        <Modal
          title="Add IMEI"
          visible={isModalModem}
          footer=""
          onCancel={() => setisModalModem(false)}
          width={1000}
        >
          <form
            method="post"
            name="main_menu_add"
            id="main_menu_add"
            enctype="multipart/form-data"
            onSubmit={onsubmitHandale}
          >
            <div class="form-row" style={{ marginLeft: "10px" }}>
              <div class="form-group col-md-3" id="grpeui">
                <label for="inputPassword4">
                  Modem Profile: <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control form-select modemtypename anothermodemname"
                  required=""
                  name="modem_profile_id"
                  id="modem_profile_id"
                  onChange={(e) => {
                    setInputs({ ...inputs, modemId: e.target.value });
                  }}
                  value={inputs.modemId}
                  disabled={!disabled}
                >
                  <option value="">Select Modem</option>
                  {ModemData &&
                    ModemData.map((item) => (
                      <option value={item?.MODEM_UNIQUE_ID}>
                        {item?.MODEM_PROFILE_NAME}
                      </option>
                    ))}
                </select>
                <span
                  class="text-danger"
                  id="imeirepeterror"
                  style={{ fontSize: "10px" }}
                >
                  {Validation?.modemId}
                </span>
              </div>
              <div class="form-group col-md-3">
                <label for="inputPassword4">
                  IMEI:<span class="text-danger">*</span>
                </label>
                <input
                  class="form-control "
                  id="deviceIMEI"
                  name="deviceIMEI"
                  required=""
                  type="text"
                  placeholder="IMEI"
                  maxlength="16"
                  onChange={(e) =>
                    setInputs({ ...inputs, IMEI: e.target.value })
                  }
                  value={inputs.IMEI}
                  disabled={!disabled}
                />
                <div
                  class="text-danger"
                  id="imeirepeterror"
                  style={{ fontSize: "10px" }}
                >
                  {Validation?.IMEI}
                </div>
                <span
                  class="text-danger"
                  id="imeirepeterror"
                  style={{ fontSize: "10px" }}
                >
                  * Not Allow Duplicate IMEI Value
                </span>
              </div>
              <div class="form-group col-md-3">
                <label for="inputPassword4">
                  Device Serial No:<span class="text-danger">*</span>
                </label>
                <input
                  class="form-control "
                  id="consmrid"
                  required=""
                  name="consmrid"
                  type="text"
                  placeholder="Modem Serial No"
                  onChange={(e) =>
                    setInputs({ ...inputs, DEVICE_SERIAL_NO: e.target.value })
                  }
                  value={inputs.DEVICE_SERIAL_NO}
                  disabled={!disabled}
                />
                <div
                  class="text-danger"
                  id="imeirepeterror"
                  style={{ fontSize: "10px" }}
                >
                  {Validation?.DEVICE_SERIAL_NO}
                </div>
                <span
                  class="text-danger"
                  id="imeirepeterror"
                  style={{ fontSize: "10px" }}
                >
                  * Not Allow Duplicate Modem Serial No
                </span>
              </div>
              <div class="form-group col-md-3">
                <label for="inputPassword4">Panel Serial No:</label>
                <input
                  class="form-control"
                  id="panelserialno"
                  name="panelserialno"
                  type="text"
                  placeholder="Panel Serial No"
                  onChange={(e) =>
                    setInputs({ ...inputs, PANEL_SERIAL_NO: e.target.value })
                  }
                  value={inputs.PANEL_SERIAL_NO}
                  disabled={!disabled}
                />
                <span
                  class="text-danger"
                  id="imeirepeterror"
                  style={{ fontSize: "10px" }}
                >
                  * Not Allow Duplicate Panel Serial No
                </span>
              </div>
              <div class="form-group col-md-3">
                <label for="inputPassword4">Max Consumer Assign:</label>
                <input
                  class="form-control "
                  id="deviceIMEI"
                  name="deviceIMEI"
                  required=""
                  type="text"
                  placeholder=""
                  maxlength="16"
                  onChange={(e) =>
                    setInputs({ ...inputs, Consumer_assign: e.target.value })
                  }
                  value={inputs.Consumer_assign}
                  disabled={!disabled}
                />
              </div>
            </div>

            <p style={{ marginLeft: "25px" }} class="text-danger">
              Note : * Indicates Mandatory Fields
            </p>
            <div class="text-left">
              <input
                type="submit"
                name="submit"
                id="submitvn"
                class={`btn btn-danger ${
                  flagBtn ? "btn-progress disabled" : ""
                } ml-4 mr-3`}
                value={"Submit"}
              />
              <Link
                class="btn btn-light text-dark"
                onClick={() => setisModalModem(false)}
              >
                Cancel
              </Link>
            </div>
          </form>
        </Modal>
        <Modal
          title="Map"
          visible={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={handleCancel}
          width={800}
        >
          <MapPicker
            defaultLocation={{
              lat: parseFloat(location?.lat || "19.506765954448635"),
              lng: parseFloat(location?.lng || "76.46875000000001"),
            }}
            zoom={zoom}
            mapTypeId="roadmap"
            style={{ height: "500px" }}
            onChangeLocation={handleChangeLocation}
            onChangeZoom={handleChangeZoom}
            apiKey="AIzaSyB2-wMpBOkkQG341P8FUk3M31MaVIUxKg4"
          />
        </Modal>
        <Modal
          title={isModalHeader}
          visible={isModalMeter}
          footer=""
          onCancel={() => setisModalMeter(false)}
          width={1000}
        >
          {
            <div className="table-responsive">
              <table id="table_id" className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center">Unique No</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>File Name</th>
                    <th style={{ width: "100px" }}>Create At</th>
                    <th style={{ width: "100px" }}>Update At</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {MeterDataAttach.length > 0 &&
                    MeterDataAttach.map((item, id) => (
                      <tr className="trheigth">
                        <td>{item?.SRNO}</td>
                        <td>{item?.title}</td>
                        <td>{item?.description}</td>
                        <td>{item?.file}</td>
                        <td>
                          {item?.create_at === ""
                            ? ""
                            : moment
                                .utc(item?.create_at)
                                .format("DD/MM/YYYY hh:mm:ss A")}
                        </td>
                        <td>
                          {item?.update_at === ""
                            ? ""
                            : moment
                                .utc(item?.update_at)
                                .format("DD/MM/YYYY hh:mm:ss A")}
                        </td>
                        <td>
                          <a
                            class="btn btn-warning text-white"
                            onClick={() => {
                              onDownloadFilesMeter(item?.file);
                            }}
                            download=""
                            target={"_blank"}
                          >
                            <i class="fa fa-download" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          }
        </Modal>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/group-dashboard">
                <i className="fas fa-tachometer-alt"></i> Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link>
                <Users style={{ height: "16px" }} /> Consumers
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/All-GSM">
                <Users style={{ height: "16px" }} /> GSM Consumers
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link>Add GSM Consumers</Link>
            </li>
          </ol>
        </nav>
        <section class="section">
          <div class="section-body">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4>Add GSM Consumers</h4>
                    <div class="card-header-action">
                      <Link to="/All-GSM" className="btn btn-primary">
                        Back
                      </Link>
                    </div>
                  </div>
                  <div class="card-body">
                    <form
                      method="post"
                      name="main_menu_add"
                      id="main_menu_add"
                      enctype="multipart/form-data"
                      onSubmit={onSubmitGsmDate}
                    >
                      <fieldset class="customLegend">
                        <legend>Group Details</legend>
                        <div
                          class="form-row p-3"
                          style={{
                            marginLeft: "10px",
                            borderColor: "#e3e6fc !important",
                            position: "relative",
                            marginTop: "5px",
                          }}
                        >
                          <div class="col-lg-4">
                            <div class="form-group">
                              <label for="inputPassword4">
                                Account<span class="text-danger">*</span>
                              </label>
                              <select
                                class="form-control form-select accountoga ga_calueacc"
                                name="accountid"
                                required=""
                                id="accountid"
                                onChange={(e) =>
                                  setProfile({
                                    ...Profile,
                                    account: e.target.value,
                                  })
                                }
                                value={Profile?.account}
                              >
                                <option value="">Select Account</option>
                                {AccounData &&
                                  AccounData.map((item) =>
                                    permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
                                      (items) => items === item.UNIQUE_ID
                                    ).length > 0 ||
                                    userInfo?.ROLE === "ROLE1650614500708" ? (
                                      <option value={item.UNIQUE_ID}>
                                        {item.CATEGORY0}
                                      </option>
                                    ) : (
                                      ""
                                    )
                                  )}
                              </select>
                            </div>
                          </div>
                          {Profile.account !== "" && (
                            <div class="col-lg-4 gagrp">
                              <div class="form-group">
                                <label for="inputPassword4">
                                  {NameObj?.CATEGORY1 || "CATEGORY1"}
                                </label>
                                <select
                                  class="form-control form-select gatocircle ga_calue"
                                  name="address_location_d"
                                  id="address_location_d"
                                  onChange={(e) =>
                                    setProfile({
                                      ...Profile,
                                      ga: e.target.value,
                                    })
                                  }
                                  value={Profile?.ga}
                                >
                                  <option value="">Select GA</option>
                                  {GaData &&
                                    GaData.map((item) =>
                                      permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
                                        (items) =>
                                          items === item.CATEGORY1_UNIQUE_ID
                                      ).length > 0||(item.CATEGORY0==Profile?.account) ||
                                      userInfo?.ROLE === "ROLE1650614500708" ? (
                                        <option
                                          value={item.CATEGORY1_UNIQUE_ID}
                                        >
                                          {item.CATEGORY1_NAME}
                                        </option>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </select>
                              </div>
                            </div>
                          )}
                          {Profile.ga !== "" && Profile.account !== "" && (
                            <div class="col-lg-4 circlegrp">
                              <div class="form-group">
                                <label for="inputPassword4">
                                  {NameObj?.CATEGORY2 || "CATEGORY2"}
                                </label>
                                <select
                                  class="form-control circletodivision form-select"
                                  name="circle_id"
                                  onChange={(e) =>
                                    setProfile({
                                      ...Profile,
                                      circle: e.target.value,
                                    })
                                  }
                                  value={Profile?.circle}
                                >
                                  <option value="">Select Circle</option>
                                  {CircleData &&
                                    CircleData.map((item) =>
                                      permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
                                        (items) =>
                                          items === item.CATEGORY2_UNIQUE_ID
                                      ).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga)||
                                      userInfo?.ROLE === "ROLE1650614500708" ? (
                                        <option
                                          value={item.CATEGORY2_UNIQUE_ID}
                                        >
                                          {item.CATEGORY2_NAME}
                                        </option>
                                      ) : (
                                        ""
                                      )
                                    )}
                                </select>
                              </div>
                            </div>
                          )}
                          {Profile.circle !== "" &&
                            Profile.ga !== "" &&
                            Profile.account !== "" && (
                              <div class="col-lg-4 divisiongrp">
                                <div class="form-group">
                                  <label for="inputPassword4">
                                    {NameObj?.CATEGORY3 || "CATEGORY3"}
                                  </label>
                                  <select
                                    class="form-control divisiontosubdivision form-select"
                                    name="division_id"
                                    id="address_location_d"
                                    onChange={(e) =>
                                      setProfile({
                                        ...Profile,
                                        division: e.target.value,
                                      })
                                    }
                                    value={Profile?.division}
                                  >
                                    <option value="">Select Division</option>
                                    {DivisionData &&
                                      DivisionData.map((item) =>
                                        permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
                                          (items) =>
                                            items === item.CATEGORY3_UNIQUE_ID
                                        ).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga&&item.CATEGORY2==Profile?.circle)||
                                        userInfo?.ROLE ===
                                          "ROLE1650614500708" ? (
                                          <option
                                            value={item.CATEGORY3_UNIQUE_ID}
                                          >
                                            {item.CATEGORY3_NAME}
                                          </option>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </select>
                                </div>
                              </div>
                            )}
                          {Profile.division !== "" &&
                            Profile.circle !== "" &&
                            Profile.ga !== "" &&
                            Profile.account !== "" && (
                              <div class="col-lg-4 subdivisiongrp">
                                <div class="form-group">
                                  <label for="inputPassword4">
                                    {NameObj?.CATEGORY4 || "CATEGORY4"}
                                  </label>
                                  <select
                                    class="form-control subdivisiontoarea form-select"
                                    name="sub_division_id"
                                    id="address_location_d"
                                    onChange={(e) =>
                                      setProfile({
                                        ...Profile,
                                        subdivision: e.target.value,
                                      })
                                    }
                                    value={Profile?.subdivision}
                                  >
                                    <option value="">
                                      Select Sub Division
                                    </option>
                                    {SubdevisionData &&
                                      SubdevisionData.map((item) =>
                                        permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
                                          (items) =>
                                            items === item.CATEGORY4_UNIQUE_ID
                                        ).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga&&item.CATEGORY2==Profile?.circle&&item.CATEGORY3==Profile?.division)||
                                        userInfo?.ROLE ===
                                          "ROLE1650614500708" ? (
                                          <option
                                            value={item.CATEGORY4_UNIQUE_ID}
                                          >
                                            {item.CATEGORY4_NAME}
                                          </option>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </select>
                                </div>
                              </div>
                            )}
                          {Profile.subdivision !== "" &&
                            Profile.division !== "" &&
                            Profile.circle !== "" &&
                            Profile.ga !== "" &&
                            Profile.account !== "" && (
                              <div class="col-lg-4 areagrp">
                                <div class="form-group">
                                  <label for="inputPassword4">
                                    {NameObj?.CATEGORY5 || "CATEGORY5"}
                                  </label>
                                  <select
                                    class="form-control form-select lastarea cust_ara"
                                    name="area_id"
                                    id="area_id"
                                    onChange={(e) =>
                                      setProfile({
                                        ...Profile,
                                        area: e.target.value,
                                      })
                                    }
                                    value={Profile?.area}
                                  >
                                    <option value="">Select Area</option>
                                    {AreaData &&
                                      AreaData.map((item) =>
                                        permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
                                          (items) => items === item.UNIQUE_ID
                                        ).length > 0 ||(item.CATEGORY0==Profile?.account&&item.CATEGORY1==Profile?.ga&&item.CATEGORY2==Profile?.circle&&item.CATEGORY3==Profile?.division&&item.CATEGORY4==Profile?.subdivision)||
                                        userInfo?.ROLE ===
                                          "ROLE1650614500708" ? (
                                          <option value={item.UNIQUE_ID}>
                                            {item.CATEGORY5_NAME}
                                          </option>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </select>
                                </div>
                              </div>
                            )}
                        </div>
                      </fieldset>
                      <fieldset class="customLegend">
                        <legend>Distributor Customer Details</legend>
                        <div
                          class="form-row p-3"
                          style={{
                            marginLeft: "10px",
                            borderColor: "#e3e6fc !important",
                            position: "relative",
                            marginTop: "5px",
                          }}
                        >
                          <div class="form-group col-md-3">
                            <label for="cust_lname">Customer ID</label>
                            <input
                              class="form-control"
                              id="cust_lname"
                              name="distriuter_customer_id"
                              type="text"
                              placeholder="Distributor Customer ID"
                              onChange={(e) =>
                                setConsumer({
                                  ...Consumer,
                                  cunsumerId: e.target.value.replace(/[\\"`\t\n]/g,""),
                                })
                              }
                              value={Consumer.cunsumerId}
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="installation_number">
                              Installation Number
                            </label>
                            <input
                              class="form-control"
                              id="installationo"
                              name="installation_number"
                              type="text"
                              placeholder="Installation Number"
                              onChange={(e) =>
                                setConsumer({
                                  ...Consumer,
                                  Installation: e.target.value.replace(/[\\"`\t\n]/g,""),
                                })
                              }
                              value={Consumer.Installation}
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="business_partner_number">
                              Business Partner Number
                            </label>
                            <input
                              class="form-control "
                              id="business_partner_number"
                              name="business_partner_number"
                              type="text"
                              placeholder="Business Partner Number"
                              onChange={(e) =>
                                setConsumer({
                                  ...Consumer,
                                  BPN: e.target.value.replace(/[\\"`\t\n]/g,""),
                                })
                              }
                              value={Consumer.BPN}
                            />
                            <span
                              class="text-danger"
                              id="business_partner_number_error"
                            ></span>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="business_partner_numbers">
                              Consumer Type
                            </label>
                            <select
                              class="form-control form-select modemtypename anothermodemname"
                              name="cust_sal"
                              id="filter_course"
                              required=""
                              onChange={(e) => {
                                setConsumerType({
                                  ...ConsumerType,
                                  CONSUMER_TYPE: e.target.value,
                                });
                              }}
                              value={ConsumerType.CONSUMER_TYPE}
                            >
                              <option value="">Select Consumer Type </option>
                              <option value="Residential">Residential</option>
                              <option value="Industrial">Industrial</option>
                              <option value="Commercial">Commercial</option>
                              <option value="DRS">DRS</option>
                            </select>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset class="customLegend">
                        <legend>Modem Details</legend>
                        <div
                          class="form-row p-3"
                          style={{
                            marginLeft: "10px",
                            borderColor: "#e3e6fc !important",
                            position: "relative",
                            marginTop: "5px",
                          }}
                        >
                          <div class="form-group col-md-3">
                            <label for="inputPassword4">Modem Profile</label>
                            <select
                              class="form-control form-select modemtypename anothermodemname"
                              name="cust_sal"
                              id="filter_course"
                              required=""
                              onChange={(e) => {
                                if (e.target.value == "") {
                                } else {
                                  setConsumerType({
                                    ...ConsumerType,
                                    ModemId: e.target.value,
                                  });
                                  onGetMeterDetails(e.target.value);
                                  onGetMechanicalDetails(e.target.value);
                                }
                                onGetModemTypeWiseIMEI(e.target.value);
                              }}
                              value={ConsumerType.ModemId}
                            >
                              <option value="">Select Modem Profile </option>
                              {ModemData &&
                                ModemData.map((item) => (
                                  <option value={item.MODEM_UNIQUE_ID}>
                                    {item.MODEM_PROFILE_NAME}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="inputPassword4">IMEI:</label>
                            <Select
                              value={
                                ConsumerType?.IMEI
                                  ? [
                                      {
                                        label: ConsumerType?.IMEI,
                                        value: ConsumerType?.IMEI,
                                      },
                                    ]
                                  : []
                              }
                              placeholder="Select IMEI..."
                              options={[
                                ...IMEIList.map((item, id) => ({
                                  label: item.IMEI,
                                  value: item.IMEI,
                                })),
                                { label: "+ Add IMEI", value: "open" },
                              ]}
                              onChange={(e) => {
                                console.log(e);
                                if (e.value === "") {
                                  setConsumerType({
                                    ...ConsumerType,
                                    IMEI: "",
                                  });
                                }
                                onGetIMEIWiseModemData(e.value);
                              }}
                            />
                            {/* <select
															class="form-control form-select modemtypename anothermodemname"
															name="cust_sal"
															required=""
															onChange={(e) => {
																console.log(e.target.value)
																if(e.target.value===''){
																	
																	setConsumerType({...ConsumerType,IMEI:''})
																
																}
																
																}}
															value={ConsumerType.IMEI}
														>
															<option value="">Select Modem IMEI </option>
															{IMEIList &&
																IMEIList.map((item) => (
																	<option value={item.IMEI}>{item.IMEI}</option>
																))}
																{ConsumerType.ModemId!=''?<option value="open" className="alert alert-secondary text-dark" role="alert" >+ Add IMEI</option>:''}
														</select> */}
                            {/* <input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="IMEI"
															maxlength="16"
															onChange={(e) => setConsumerType({ ...ConsumerType, IMEI: e.target.value })}
															value={ConsumerType.IMEI}
															style={{ textTransform: "lowercase" }}
														/> */}
                          </div>
                          <div class="form-group col-md-3">
                            <label for="inputPassword4">Modem Serial No:</label>
                            <input
                              class="form-control "
                              id="consmrid"
                              required=""
                              name="consmrid"
                              type="text"
                              placeholder="Modem Serial No"
                              onChange={(e) =>
                                setConsumerType({
                                  ...ConsumerType,
                                  MSRNO: e.target.value,
                                })
                              }
                              value={ConsumerType.MSRNO}
                              disabled={true}
                            />
                          </div>
                          <div class="form-group col-md-3">
                            <label for="inputPassword4">Panel Serial No:</label>
                            <input
                              class="form-control"
                              id="panelserialno"
                              name="panelserialno"
                              type="text"
                              placeholder="Panel Serial No"
                              onChange={(e) =>
                                setConsumerType({
                                  ...ConsumerType,
                                  PSNO: e.target.value,
                                })
                              }
                              value={ConsumerType.PSNO}
                              disabled={true}
                            />
                          </div>
                          
                          {Object.keys(ModemInputs).length ? (
                            <div
                              class="form-row meter_data_row"
                              style={{
                                marginLeft: "10px",
                                position: "relative",
                                borderColor: "rgb(227, 230, 252) !important",
                              }}
                            >
                              <div class="font-10">
                                <span>
                                  Modem Profile :{" "}
                                  <span id="modem_type">
                                    {ModemInputs?.MODEM_PROFILE_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Modem Make :{" "}
                                  <span id="modem_make">
                                    {ModemInputs?.MODEM_MAKE_NAME}
                                  </span>
                                </span>
                                &nbsp;&nbsp;||&nbsp;
                                <span>
                                  Modem Type :{" "}
                                  <span id="modem_make">
                                    {ModemInputs?.MODEM_TYPE}
                                  </span>
                                </span>
                                &nbsp;&nbsp;||&nbsp;
                                <span>
                                  Modem Model Version :{" "}
                                  <span id="modem_model">
                                    {ModemInputs?.MODEM_MODEL_NAME}
                                  </span>
                                </span>
                                &nbsp;&nbsp;||&nbsp;
                                <span>
                                  Modem Firmware :{" "}
                                  <span id="modem_firmware">
                                    {ModemInputs?.MODEM_FIRMWARE_NAME}
                                  </span>
                                </span>
                                &nbsp;&nbsp;||&nbsp;
                                <span>
                                  Modem SKU :{" "}
                                  <span id="modem_sku">
                                    {ModemInputs?.SKU_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;&nbsp;||&nbsp;
                                <span>
                                  Modem Hardware Version :{" "}
                                  <span id="modem_hardware_version">
                                    {ModemInputs?.MODEM_HARDWARE_NAME}
                                  </span>
                                </span>
                                &nbsp;&nbsp;||&nbsp;
                                <span>
                                  Script Code ID :{" "}
                                  <span id="SCRIPTDECODE_ID_V">
                                    {scripcodeid}
                                  </span>
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </fieldset>
                      <fieldset class="customLegend">
                        <legend>EVC Details</legend>
                        <div
                          class="form-row  rounded p-3 d-flex"
                          style={{
                            marginLeft: "10px",
                            borderColor: "#e3e6fc !important",
                            position: "relative",
                            marginTop: "5px",
                          }}
                        >
                          <div class="form-group col-md-3 ">
                            <label for="inputPassword4">EVC Profile</label>
                            <select
                              class="form-control form-select second_meter_type skjghudfjgnv"
                              onChange={(e) => {
                                setConsumerType({
                                  ...ConsumerType,
                                  MeterId: e.target.value,
                                });
                              }}
                              value={ConsumerType.MeterId}
                              required=""
                              name="meter_type"
                              disabled={
                                ConsumerType.ModemId &&
                                ConsumerType.IMEI &&
                                ConsumerType.MSRNO
                                  ? false
                                  : true
                              }
                            >
                              <option value="">Select EVC Profile</option>
                              {MeterData &&
                                MeterData.map((item) => (
                                  <option value={item.METER_UNIQUE_ID}>
                                    {item.METER_PROFILE_NAME}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {MeterInputs?.MECHANICAL_SRNO_ALLOW==1?<div class="form-group col-md-3">
														<label for="inputPassword4">Mechanical Meter Serial No:</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="Mechanical Meter Serial No"
															maxlength="16"
															onChange={(e) => setConsumerType({ ...ConsumerType, METERSRNO: e.target.value })}
															value={ConsumerType.METERSRNO}
														/>
														<span class="text-danger " style={{ fontSize: "10px" }} id="imeirepeterror">
															* Not Allow Duplicate Mechanical Meter Serial No
														</span>
													</div>:''}
                          {MeterInputs?.EVC_SRNO_ALLOW==1?<div class="form-group col-md-3">
														<label for="inputPassword4">EVC Meter Serial No:</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="EVC Meter Serial No"
															maxlength="16"
															onChange={(e) => setConsumerType({ ...ConsumerType, CONVSERNUM: e.target.value })}
															value={ConsumerType.CONVSERNUM}
														/>
														<span class="text-danger " style={{ fontSize: "10px" }} id="imeirepeterror">
															* Not Allow Duplicate EVC Meter Serial No
														</span>
													</div>:''}
                          {/* {MeterInputs?.Mechanical==1?<div class="form-group col-md-3">
														<label for="inputPassword4">Mechanical Meter Serial No:</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="Mechanical Meter Serial No"
															maxlength="16"
															onChange={(e) => setConsumerType({ ...ConsumerType, METERSRNO: e.target.value })}
															value={ConsumerType.METERSRNO}
														/>
														<span class="text-danger " style={{ fontSize: "10px" }} id="imeirepeterror">
															* Not Allow Duplicate Mechanical Meter Serial No
														</span>
													</div>:MeterInputs?.EVC==1?<div class="form-group col-md-3">
														<label for="">EVC Serial No:</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="EVC Serial No"
															maxlength="16"
															onChange={(e) => setConsumerType({ ...ConsumerType, CONVSERNUM: e.target.value })}
															value={ConsumerType.CONVSERNUM}
														/>
														<span class="text-danger " style={{ fontSize: "10px" }} id="imeirepeterror">
															* Not Allow Duplicate EVC Serial No
														</span>
													</div>:''} */}
                          {/* <div class="form-group col-md-3">
														<label for="inputPassword4">Multiplication Factor:</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="Machin Factor"
															maxlength="16"
															onChange={(e) => setConsumerType({ ...ConsumerType, MF: e.target.value })}
															value={ConsumerType.MF}
														/>
													</div>
													 */}
                          <input
                            type="hidden"
                            name="meter_unique_ids"
                            id="meter_unique_id"
                            placeholder="Enter Mechanical Serial Number"
                          />

                          <input
                            type="hidden"
                            name="evc_serial_number"
                            id="evc_serial_number"
                            placeholder="EVC Serial Number"
                          />
                          {Object.keys(MeterInputs).length ? (
                            <div
                              class="form-row meter_data_row d-flex"
                              style={{
                                marginLeft: "10px",
                                position: "relative",
                                borderColor: "rgb(227, 230, 252) !important",
                                flexDirection: "column",
                              }}
                            >
                              <div class="font-10">
                                <span>
                                  EVC Make :{" "}
                                  <span id="meter_make">
                                    {MeterInputs?.METER_MAKE_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;|| &nbsp;
                                <span>
                                  EVC Category :{" "}
                                  <span id="meter_category">
                                    {MeterInputs?.METER_CATEGORY_NAME}{" "}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  EVC Class :{" "}
                                  <span id="meter_meter_type">
                                    {MeterInputs?.METER_TYPE_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Meter Type :{" "}
                                  <span id="meter_meter_type">
                                    {MeterInputs?.METER_TYPE_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Modem Type :{" "}
                                  <span id="meter_meter_type">
                                    {MeterInputs?.MODEM_TYPE_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  EVC Model Number :{" "}
                                  <span id="meter_meter_type">
                                    {MeterInputs?.METER_MODEL_NUMBER_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  EVC Hardware Protocol :{" "}
                                  <span id="meter_hardware">
                                    {MeterInputs?.METER_HARDWARE_PROTOCOL_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  EVC Firmware :{" "}
                                  <span id="meter_firmware">
                                    {MeterInputs?.METER_FIRMWARE_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  EVC Software Protocol :{" "}
                                  <span id="meter_software">
                                    {MeterInputs?.METER_SOFTWARE_PROTOCOL_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  EVC Storage In Day :{" "}
                                  <span id="meter_firmware">
                                    {MeterInputs?.MEMORY_STORAGE_IN_DAYS}
                                  </span>
                                </span>
                              </div>
                              <a
                                className="btn btn-info btn-sm col-lg-1.1 col-md-2 col-xl-1.1 col-sm-2 col-xs-2"
                                onClick={onMeterAttachment}
                              >
                                EVC Driver
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </fieldset>
                      <fieldset class="customLegend">
                        <legend>Mechanical Details</legend>
                        <div
                          class="form-row  rounded p-3 d-flex"
                          style={{
                            marginLeft: "10px",
                            borderColor: "#e3e6fc !important",
                            position: "relative",
                            marginTop: "5px",
                          }}
                        >
                          <div class="form-group col-md-3 ">
                            <label for="inputPassword4">
                              Mechanical Make
                            </label>
                            <select
                              class="form-control form-select second_meter_type skjghudfjgnv"
                              onChange={(e) => {
                                setConsumerType({
                                  ...ConsumerType,
                                  mechanical_make: e.target.value,
                                  mechanicalId:''
                                });
                                setMechanicalFilterData(MechanicalData?.filter((item)=>item?.MECHANICAL_MANUFACTURER==e.target.value))
                              }}
                              value={ConsumerType.mc_make}
                              required=""
                              name="meter_type"
                              disabled={
                                ConsumerType.ModemId &&
                                ConsumerType.IMEI &&
                                ConsumerType.MSRNO
                                  ? false
                                  : true
                              }
                            >
                              <option value="">
                                Select Mechanical Make
                              </option>
                              {MCMAKEData &&
                                MCMAKEData.filter(make_info=>MechanicalData?.filter((item)=>make_info.MECHANICAL_METER_MF_UNIQUE_ID===item?.MECHANICAL_MANUFACTURER).length>0).map((item) => (
                                  <option value={item.MECHANICAL_METER_MF_UNIQUE_ID}>
                                    {item.MECHANICAL_METER_MF_NAME}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div class="form-group col-md-3 ">
                            <label for="inputPassword4">
                              Mechanical Profile
                            </label>
                            <select
                              class="form-control form-select second_meter_type skjghudfjgnv"
                              onChange={(e) => {
                                setConsumerType({
                                  ...ConsumerType,
                                  mechanicalId: e.target.value,
                                });
                              }}
                              value={ConsumerType.mechanicalId}
                              required=""
                              name="meter_type"
                              disabled={
                                ConsumerType.ModemId &&
                                ConsumerType.IMEI &&
                                ConsumerType.MSRNO
                                  ? false
                                  : true
                              }
                            >
                              <option value="">
                                Select Mechanical Profile
                              </option>
                              {MechanicalFilterData &&
                                MechanicalFilterData.map((item) => (
                                  <option value={item.MECHANICAL_UNIQUE_ID}>
                                    {item.MECHANICAL_PROFILE_NAME}
                                  </option>
                                ))}
                            </select>
                          </div>
                        
                          
                          <input
                            type="hidden"
                            name="evc_serial_number"
                            id="evc_serial_number"
                            placeholder="EVC Serial Number"
                          />
                          {Object.keys(MechanicalInputs).length ? (
                            <div
                              class="form-row meter_data_row d-flex"
                              style={{
                                marginLeft: "10px",
                                position: "relative",
                                borderColor: "rgb(227, 230, 252) !important",
                                flexDirection: "column",
                              }}
                            >
                              <div class="font-10">
                                <span>
                                  Mechanical Brand :{" "}
                                  <span id="meter_make">
                                    {
                                      MechanicalInputs?.MECHANICAL_METER_BRAND_NAME
                                    }
                                  </span>
                                </span>{" "}
                                &nbsp;|| &nbsp;
                                <span>
                                  Mechanical Category :{" "}
                                  <span id="meter_category">
                                    {
                                      MechanicalInputs?.MECHANICAL_METER_CATEGORY_NAME
                                    }{" "}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical Class :{" "}
                                  <span id="meter_meter_type">
                                    {
                                      MechanicalInputs?.MECHANICAL_METER_CLASS_NAME
                                    }
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical Manufacturer :{" "}
                                  <span id="meter_meter_type">
                                    {MechanicalInputs?.MECHANICAL_METER_MF_NAME}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Modem Model Number :{" "}
                                  <span id="meter_meter_type">
                                    {
                                      MechanicalInputs?.MECHANICAL_METER_MODAL_NAME
                                    }
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical Type :{" "}
                                  <span id="meter_meter_type">
                                    {
                                      MechanicalInputs?.MECHANICAL_METER_TYPE_NAME
                                    }
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical Maximum flow rate Qmax :{" "}
                                  <span id="meter_hardware">
                                    {MechanicalInputs?.MECHANICAL_MAX_RATE_QMAX}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical Minimum flow rate Qmin :{" "}
                                  <span id="meter_firmware">
                                    {MechanicalInputs?.MECHANICAL_MIN_RATE_QMIN}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical Nominal flow rate Q :{" "}
                                  <span id="meter_software">
                                    {
                                      MechanicalInputs?.MECHANICAL_NOMINAL_RATE_Q
                                    }
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical Max working pressure Pmax:{" "}
                                  <span id="meter_software">
                                    {MechanicalInputs?.MECHANICAL_MAX_PMAX}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical units:{" "}
                                  <span id="meter_software">
                                    {MechanicalInputs?.MECHANICAL_UNITS}
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                                <span>
                                  Mechanical Meter Capacity :{" "}
                                  <span id="meter_software">
                                    {
                                      MechanicalInputs?.MECHANICAL_METER_CAPACITY
                                    }
                                  </span>
                                </span>{" "}
                                &nbsp;||&nbsp;
                              </div>
                              <a
                                className="btn btn-info btn-sm col-lg-1.1 col-md-2 col-xl-1.1 col-sm-2 col-xs-2"
                                onClick={onMechanicalAttachment}
                              >
                                Mechanical Driver
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </fieldset>
                      <fieldset class="customLegend">
                        <legend>Other Details</legend>
                        <div
                          class="form-row  rounded p-3 d-flex"
                          style={{
                            marginLeft: "10px",
                            borderColor: "#e3e6fc !important",
                            position: "relative",
                            marginTop: "5px",
                          }}
                        >
                        <div class="form-group col-md-3">
														<label for="inputPassword4">Conversion Factor:</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="number"
															placeholder="Conversion Factor"
															maxlength="16"
                              step="0.0001"
															onChange={(e) =>{
                                const isValid=/^\d+(\.\d{0,4})?$/
                                 setConsumerType({ ...ConsumerType, CF: isValid.test(e.target.value)?e.target.value:ConsumerType?.CF })}}
															value={ConsumerType.CF}
														/>
													</div>
                          </div>
                        </fieldset>
                      <div class="col-lg-12" style={{ marginTop: "10px" }}>
                        <ul
                          class="nav nav-pills border"
                          id="myTab3"
                          role="tablist"
                        >
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              id="profile-tab3"
                              data-toggle="tab"
                              href="#profile3"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="true"
                            >
                              Address
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link "
                              id="contact-tab3"
                              data-toggle="tab"
                              href="#contact3"
                              role="tab"
                              aria-controls="contact"
                              aria-selected="false"
                            >
                              Contact Person
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="contact-tab3"
                              data-toggle="tab"
                              href="#installationdetails"
                              role="tab"
                              aria-controls="contact"
                              aria-selected="false"
                            >
                              Installation Details
                            </a>
                          </li>
                          {/* <li class="nav-item">
														<a
															class="nav-link"
															id="contact-tab3"
															data-toggle="tab"
															href="#remarks"
															role="tab"
															aria-controls="contact"
															aria-selected="false"
														>
															Remarks
														</a>
													</li> */}
                          {/* <li class="nav-item">
														<a
															class="nav-link"
															id="contact-tab3"
															data-toggle="tab"
															href="#attechments"
															role="tab"
															aria-controls="contact"
															aria-selected="false"
														>
															Attachments
														</a>
													</li> */}
                        </ul>
                        <div class="tab-content" id="myTabContent2">
                          <div
                            class="tab-pane fade  active show"
                            id="profile3"
                            role="tabpanel"
                            aria-labelledby="profile-tab3"
                            style={{ marginTop: "5px" }}
                          >
                            <div class="row">
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <label for="address_compony_name">
                                    Consumer Name:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control present_street_area"
                                    name="address_compony_name"
                                    id="address_compony_name"
                                    placeholder="Consumer Name"
                                    onChange={(e) =>
                                      serAccountInput({
                                        ...AccountInput,
                                        address_compony_name:
                                          e.target.value.replace(
                                            /[\/\\"`\t\n]/g,
                                            ""
                                          ),
                                      })
                                    }
                                    value={AccountInput.address_compony_name}
                                  />
                                  <span
                                    class="text-danger"
                                    id="imeirepeterror"
                                    style={{ fontSize: "10px" }}
                                  >
                                    * Special Characters like Quotation mark
                                    ("),Backslash (\),Slash (/),New
                                    line,Horizontal tab not allowed
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="address_building_no">
                                    Flat No, House No or Building No:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control present_home_no"
                                    name="address_building_no"
                                    id="address_building_no"
                                    placeholder="Flat No, House No or Building No"
                                    onChange={(e) =>
                                      serAccountInput({
                                        ...AccountInput,
                                        address_building_no:
                                          e.target.value.replace(
                                            /[\\"`\t\n]/g,
                                            ""
                                          ),
                                      })
                                    }
                                    value={AccountInput.address_building_no}
                                  />
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="address_street_area">
                                    Area, Colony, Street, Village:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control present_street_area"
                                    name="address_street_area"
                                    id="address_street_area"
                                    placeholder="Area, Colony, Street, Village"
                                    onChange={(e) =>
                                      serAccountInput({
                                        ...AccountInput,
                                        address_street_area:
                                          e.target.value.replace(
                                            /[\\"`\t\n]/g,
                                            ""
                                          ),
                                      })
                                    }
                                    value={AccountInput.address_street_area}
                                  />
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="address_landmark_colony">
                                    Landmark:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control present_landmark_col"
                                    name="address_landmark_colony"
                                    id="address_landmark_colony"
                                    placeholder="Landmark"
                                    onChange={(e) =>
                                      serAccountInput({
                                        ...AccountInput,
                                        address_landmark_colony:
                                          e.target.value.replace(/[\\"`\t\n]/g,""),
                                      })
                                    }
                                    value={AccountInput.address_landmark_colony}
                                  />
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="address_pin_code">
                                    Pin Code:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="address_pin_code"
                                    id="address_pin_code"
                                    placeholder="Pin Code"
                                    onChange={(e) =>
                                      serAccountInput({
                                        ...AccountInput,
                                        address_pin_code: e.target.value.replace(/[\\"`\t\n]/g,""),
                                      })
                                    }
                                    value={AccountInput.address_pin_code}
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="inputPassword4">Country</label>
                                  <select
                                    class="form-control second_meter_type form-select"
                                    onChange={(item) => {
                                      serAccountInput({
                                        ...AccountInput,
                                        address_country_id: item.target.value,
                                      });
                                      getState(item.target.value);
                                    }}
                                    value={AccountInput?.address_country_id}
                                    required=""
                                    name="meter_type"
                                  >
                                    <option value="">Select Country</option>
                                    {options &&
                                      options.map((item) => (
                                        <option value={item.id}>
                                          {item.country}
                                        </option>
                                      ))}
                                  </select>
                                  {/* <GeoLocation
                                                locationTitle="Country"
                                                isCountry
                                                onChange={(item) => (serAccountInput({ ...AccountInput, address_country_id: item }))}
                                                country={AccountInput?.address_country_id}
                                                disabled={!disable}
                                             /> */}
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="inputPassword4">State</label>
                                  <select
                                    class="form-control second_meter_type form-select"
                                    onChange={(item) => {
                                      serAccountInput({
                                        ...AccountInput,
                                        address_state_id: item.target.value.replace(/[\\"`\t\n]/g,""),
                                      });
                                      getCity(item.target.value);
                                    }}
                                    value={AccountInput?.address_state_id}
                                    required=""
                                    name="meter_type"
                                  >
                                    <option value="">Select State</option>
                                    {stateData &&
                                      stateData.map((item) => (
                                        <option value={item.id}>
                                          {item.state}
                                        </option>
                                      ))}
                                  </select>
                                  {/* <GeoLocation
                                                locationTitle="State"
                                                onChange={(item) => (serAccountInput({ ...AccountInput, address_state_id: item }))}
                                                geoId={AccountInput.address_country_id}
                                                state={AccountInput?.address_state_id}
                                                isState
                                                disabled={!disable}
                                             /> */}
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="inputPassword4">City</label>
                                  <select
                                    class="form-control second_meter_type form-select"
                                    onChange={(item) =>
                                      serAccountInput({
                                        ...AccountInput,
                                        address_city_id: item.target.value.replace(/[\\"`\t\n]/g,""),
                                      })
                                    }
                                    value={AccountInput?.address_city_id}
                                    required=""
                                    name="meter_type"
                                  >
                                    <option value="">Select City</option>
                                    {Citydata &&
                                      Citydata.map((item) => (
                                        <option value={item.id}>
                                          {item.city}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="inputPassword4">TimeZone</label>
                                  <select
                                    class="form-control second_meter_type form-select"
                                    onChange={(item) =>
                                      setConsumerType({
                                        ...ConsumerType,
                                        TIMEZONE: item.target.value,
                                      })
                                    }
                                    value={ConsumerType?.TIMEZONE}
                                    required=""
                                    name="meter_type"
                                  >
                                    <option value="">Select TimeZone</option>
                                    {TimeZonelist &&
                                      TimeZonelist.map((item) => (
                                        <option value={item.offset * 60}>
                                          {item.text}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="address_lattitude">
                                    Latitude:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="address_lattitude"
                                    id="loca_lat"
                                    placeholder="Lattitude"
                                    value={
                                      AccountInput?.address_lattitude || ""
                                    }
                                    onChange={(e) => {
                                      const result = e.target.value.replace(
                                        /[^0-9.]/gi,
                                        ""
                                      );
                                      serAccountInput({
                                        ...AccountInput,
                                        address_lattitude: result,
                                      });
                                    }}
                                  />
                                  <p
                                    className="text-danger"
                                    style={{ fontSize: "10px" }}
                                  >
                                    * Allowed Only Number (0-9) And Periods(.)
                                    And Format (D.D°)
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <label for="address_longitude">
                                    Longitude:
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="address_longitude"
                                    id="loca_long"
                                    placeholder="Longitude"
                                    value={
                                      AccountInput?.address_longitude || ""
                                    }
                                    onChange={(e) => {
                                      const result = e.target.value.replace(
                                        /[^0-9.]/gi,
                                        ""
                                      );
                                      serAccountInput({
                                        ...AccountInput,
                                        address_longitude: result,
                                      });
                                    }}
                                  />
                                  <p
                                    className="text-danger"
                                    style={{ fontSize: "10px" }}
                                  >
                                    * Allowed Only Number (0-9) And Periods(.)
                                    And Format (D.D°)
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <div class="form-group">
                                  <a
                                    class="btn btn-primary"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    style={{ marginTop: "30px" }}
                                    title=""
                                    onClick={() => setIsModalVisible(true)}
                                    id="maplink"
                                    data-original-title="Map"
                                  >
                                    <i class="fa fa-location-arrow"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="contact3"
                            role="tabpanel"
                            aria-labelledby="contact-tab3"
                            style={{ marginTop: "5px" }}
                          >
                            <div class="row">
                              <div class="col-12">
                                <div class="row" id="dynamic_field">
                                  {contactDetails.length > 0 &&
                                    contactDetails.map((item, id) => (
                                      <div class="col-6" id="coll1">
                                        <div class="card card-primary">
                                          <div class="card-header">
                                            <h4>{id + 1}.</h4>
                                            <div class="card-header-action">
                                              <button
                                                type="button"
                                                class="btn btn-danger text-white btn_removefdg"
                                                id="1"
                                                onClick={() => onDelete(id)}
                                              >
                                                <i
                                                  class="fa fa-times"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                            </div>
                                          </div>
                                          <div class="card-body">
                                            <div class="form-group">
                                              <div class="form-group row !tw-my-1">
                                                <label
                                                  for="salutation_other1"
                                                  class="col-sm-3 col-form-label"
                                                >
                                                  Salutation
                                                </label>
                                                <div class="col-sm-9">
                                                  <select
                                                    class="form-control"
                                                    name="salutation_other[]"
                                                    id="salutation_other1"
                                                    onChange={(value) =>
                                                      handleChangeTask({
                                                        target: {
                                                          name: "salutation",
                                                          value,
                                                          id,
                                                        },
                                                      })
                                                    }
                                                    value={
                                                      contactDetails[id]
                                                        .salutation
                                                    }
                                                  >
                                                    <option value=" ">
                                                      Select Salutation
                                                    </option>
                                                    <option value="Mr.">
                                                      Mr.
                                                    </option>
                                                    <option value="Ms.">
                                                      Ms.
                                                    </option>
                                                    <option value="Mrs.">
                                                      Mrs.
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                              <div class="form-group row !tw-my-1">
                                                <label
                                                  for="firstcontectname1"
                                                  class="col-sm-3 col-form-label"
                                                >
                                                  First Name
                                                </label>
                                                <div class="col-sm-9">
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="First Name"
                                                    id="firstcontectname1"
                                                    name="firstcontectname[]"
                                                    onChange={(value) =>
                                                      handleChangeTask({
                                                        target: {
                                                          name: "First_Name",
                                                          value,
                                                          id,
                                                        },
                                                      })
                                                    }
                                                    value={
                                                      contactDetails[id]
                                                        .First_Name
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div class="form-group row !tw-my-1">
                                                <label
                                                  for="lastcontectname1"
                                                  class="col-sm-3 col-form-label"
                                                >
                                                  Last Name
                                                </label>
                                                <div class="col-sm-9">
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Last Name"
                                                    id="lastcontectname1"
                                                    name="lastcontectname[]"
                                                    onChange={(value) =>
                                                      handleChangeTask({
                                                        target: {
                                                          name: "Last_Name",
                                                          value,
                                                          id,
                                                        },
                                                      })
                                                    }
                                                    value={
                                                      contactDetails[id]
                                                        .Last_Name
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div class="form-group row !tw-my-1">
                                                <label
                                                  for="emailcontectname1"
                                                  class="col-sm-3 col-form-label"
                                                >
                                                  Email
                                                </label>
                                                <div class="col-sm-9">
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Email"
                                                    id="emailcontectname1"
                                                    name="emailcontectname[]"
                                                    onChange={(value) =>
                                                      handleChangeTask({
                                                        target: {
                                                          name: "Email",
                                                          value,
                                                          id,
                                                        },
                                                      })
                                                    }
                                                    value={
                                                      contactDetails[id].Email
                                                    }
                                                  />{" "}
                                                </div>
                                              </div>
                                              <div class="form-group row !tw-my-1">
                                                <label
                                                  for="othercontectnumber1"
                                                  class="col-sm-3 col-form-label"
                                                >
                                                  Mobile Number
                                                </label>
                                                <div class="col-sm-3">
                                                  <select
                                                    class="form-select form-control"
                                                    aria-label="Default select example"
                                                    onChange={(e) =>
                                                      setContactconCode(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={ContactconCode}
                                                  >
                                                    {ContryCodeData &&
                                                      ContryCodeData.map(
                                                        (item) => (
                                                          <option
                                                            value={
                                                              item.dial_code
                                                            }
                                                          >
                                                            {item.dial_code}
                                                          </option>
                                                        )
                                                      )}
                                                  </select>
                                                </div>
                                                <div class="col-sm-6">
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Mobile Number"
                                                    id="othercontectnumber1"
                                                    name="othercontectnumber[]"
                                                    onChange={(value) =>
                                                      handleChangeTask({
                                                        target: {
                                                          name: "Mobile",
                                                          value,
                                                          id,
                                                        },
                                                      })
                                                    }
                                                    value={
                                                      contactDetails[id].Mobile
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div class="form-group row !tw-my-1">
                                                <label
                                                  for="otherworkcontectnumber1"
                                                  class="col-sm-3 col-form-label"
                                                >
                                                  Work Number
                                                </label>
                                                <div class="col-sm-3">
                                                  <select
                                                    class="form-select form-control"
                                                    aria-label="Default select example"
                                                    onChange={(e) =>
                                                      setContactconCode(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={ContactconCode}
                                                  >
                                                    {ContryCodeData &&
                                                      ContryCodeData.map(
                                                        (item) => (
                                                          <option
                                                            value={
                                                              item.dial_code
                                                            }
                                                          >
                                                            {item.dial_code}
                                                          </option>
                                                        )
                                                      )}
                                                  </select>
                                                </div>
                                                <div class="col-sm-6">
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Work Number"
                                                    id="otherworkcontectnumber1"
                                                    name="otherworkcontectnumber[]"
                                                    onChange={(value) =>
                                                      handleChangeTask({
                                                        target: {
                                                          name: "Work_no",
                                                          value,
                                                          id,
                                                        },
                                                      })
                                                    }
                                                    value={
                                                      contactDetails[id].Work_no
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div class="form-group row !tw-my-1">
                                                <label
                                                  for="desginationcontect1"
                                                  class="col-sm-3 col-form-label"
                                                >
                                                  Designation
                                                </label>
                                                <div class="col-sm-9">
                                                  <input
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Designation"
                                                    id="desginationcontect1"
                                                    name="desginationcontect[]"
                                                    onChange={(value) =>
                                                      handleChangeTask({
                                                        target: {
                                                          name: "Designation",
                                                          value,
                                                          id,
                                                        },
                                                      })
                                                    }
                                                    value={
                                                      contactDetails[id]
                                                        .Designation
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div class="form-group row !tw-my-1">
                                                <label
                                                  for="thisusertype1"
                                                  class="col-sm-3 col-form-label"
                                                >
                                                  User type
                                                </label>
                                                <div class="col-sm-9">
                                                  <select
                                                    class="form-control"
                                                    name="thisusertype[]"
                                                    id="thisusertype1"
                                                    onChange={(value) =>
                                                      handleChangeTask({
                                                        target: {
                                                          name: "UserType",
                                                          value,
                                                          id,
                                                        },
                                                      })
                                                    }
                                                    value={
                                                      contactDetails[id]
                                                        .UserType
                                                    }
                                                  >
                                                    <option value=" ">
                                                      Select User Type
                                                    </option>
                                                    <option value="primary">
                                                      Primary
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                            <div class="input-group">
                              <button
                                type="button"
                                class="btn btn-info"
                                id="add"
                                onClick={onAdd}
                              >
                                +Add New Contact
                              </button>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="installationdetails"
                            role="tabpanel"
                            aria-labelledby="contact-tab3"
                            style={{ marginTop: "5px" }}
                          >
                            <div class="row">
                              <div class="col-12">
                                <div class="row">
                                  <div class="col-6">
                                    <div class="card card-primary">
                                      <div class="card-header">
                                        <h4></h4>
                                      </div>
                                      <div class="card-body">
                                        <div class="form-group">
                                          <div class="form-group row !tw-my-1 ">
                                            <label
                                              for="firstcontectname0"
                                              class="col-sm-3 col-form-label"
                                            >
                                              First Name
                                            </label>
                                            <div class="col-sm-9">
                                              <input
                                                type="text"
                                                class="form-control"
                                                placeholder="First Name"
                                                id="installation_person_fname"
                                                name="installation_person_fname"
                                                onChange={(e) =>
                                                  setinstallDetails({
                                                    ...installDetails,
                                                    Installation_Person_First_Name:
                                                      e.target.value,
                                                  })
                                                }
                                                value={
                                                  installDetails.Installation_Person_First_Name
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row !tw-my-1">
                                            <label
                                              for="lastcontectname0"
                                              class="col-sm-3 col-form-label"
                                            >
                                              Last Name
                                            </label>
                                            <div class="col-sm-9">
                                              <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Last Name"
                                                id="installation_person_lname"
                                                name="installation_person_lname"
                                                onChange={(e) =>
                                                  setinstallDetails({
                                                    ...installDetails,
                                                    Installation_Person_Last_Name:
                                                      e.target.value,
                                                  })
                                                }
                                                value={
                                                  installDetails.Installation_Person_Last_Name
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row !tw-my-1">
                                            <label
                                              for="emailcontectname0"
                                              class="col-sm-3 col-form-label"
                                            >
                                              Email
                                            </label>
                                            <div class="col-sm-9">
                                              <input
                                                class="form-control"
                                                type="text"
                                                placeholder="Email"
                                                id="installation_person_email"
                                                name="installation_person_email"
                                                onChange={(e) =>
                                                  setinstallDetails({
                                                    ...installDetails,
                                                    Installation_Person_Email:
                                                      e.target.value,
                                                  })
                                                }
                                                value={
                                                  installDetails.Installation_Person_Email
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row !tw-my-1">
                                            <label
                                              for="othercontectnumber0"
                                              class="col-sm-3 col-form-label"
                                            >
                                              Mobile Number
                                            </label>
                                            <div class="col-sm-9">
                                              <div class="form-row">
                                                <div class="col-sm-3">
                                                  <select
                                                    class="form-select form-control"
                                                    aria-label="Default select example"
                                                    onChange={(e) =>
                                                      setConCode(e.target.value)
                                                    }
                                                    value={conCode}
                                                  >
                                                    {ContryCodeData &&
                                                      ContryCodeData.map(
                                                        (item) => (
                                                          <option
                                                            value={
                                                              item.dial_code
                                                            }
                                                          >
                                                            {item.dial_code}
                                                          </option>
                                                        )
                                                      )}
                                                  </select>
                                                </div>
                                                <div class="col-sm-9">
                                                  <input
                                                    class="form-control"
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    name="installation_person_mobile_number"
                                                    id="installation_person_mobile_number"
                                                    onChange={(e) =>
                                                      setinstallDetails({
                                                        ...installDetails,
                                                        Installation_Person_Mobile_Number:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value={
                                                      installDetails.Installation_Person_Mobile_Number
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row !tw-my-1">
                                            <label class="col-sm-3 col-form-label">
                                              Work Number
                                            </label>
                                            <div class="col-sm-9">
                                              <div class="form-row">
                                                <div class="col-sm-3">
                                                  <select
                                                    class="form-select form-control"
                                                    aria-label="Default select example"
                                                    onChange={(e) =>
                                                      setConCode(e.target.value)
                                                    }
                                                    value={conCode}
                                                  >
                                                    {ContryCodeData &&
                                                      ContryCodeData.map(
                                                        (item) => (
                                                          <option
                                                            value={
                                                              item.dial_code
                                                            }
                                                          >
                                                            {item.dial_code}
                                                          </option>
                                                        )
                                                      )}
                                                  </select>
                                                </div>
                                                <div class="col-sm-9">
                                                  <input
                                                    class="form-control"
                                                    type="text"
                                                    placeholder="Work Number"
                                                    name="installation_person_work_number"
                                                    id="installation_person_work_number"
                                                    onChange={(e) =>
                                                      setinstallDetails({
                                                        ...installDetails,
                                                        Installation_Person_Work_Number:
                                                          e.target.value,
                                                      })
                                                    }
                                                    value={
                                                      installDetails.Installation_Person_Work_Number
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group row !tw-my-1">
                                            <label
                                              for="position"
                                              class="col-sm-3 col-form-label"
                                            >
                                              Position
                                            </label>
                                            <div class="col-sm-9">
                                              <input
                                                class="form-control"
                                                type="text"
                                                placeholder="Position"
                                                name="Installation_Person_Position"
                                                id="Installation_Person_Position"
                                                onChange={(e) =>
                                                  setinstallDetails({
                                                    ...installDetails,
                                                    Installation_Person_Position:
                                                      e.target.value,
                                                  })
                                                }
                                                value={
                                                  installDetails.Installation_Person_Position
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div class="form-group row !tw-my-1">
                                            <label
                                              for="desginationcontect"
                                              class="col-sm-3 col-form-label"
                                            >
                                              Date And Time:
                                            </label>
                                            <div class="col-sm-9">
                                              <Space
                                                direction="vertical"
                                                size={12}
                                              >
                                                <DatePicker
                                                  format="DD-MM-YYYY HH:mm:ss"
                                                  showTime={{
                                                    defaultValue:
                                                      installDetails.Installation_Date_And_Time ===
                                                      ""
                                                        ? moment(
                                                            "00:00:00",
                                                            "HH:mm:ss"
                                                          )
                                                        : installDetails.Installation_Date_And_Time,
                                                  }}
                                                  onChange={(e) =>
                                                    setinstallDetails({
                                                      ...installDetails,
                                                      Installation_Date_And_Time:
                                                        e
                                                          ? moment(e).format(
                                                              "DD-MM-YYYY HH:mm:ss"
                                                            )
                                                          : "",
                                                    })
                                                  }
                                                />
                                              </Space>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="remarks"
                            role="tabpanel"
                            aria-labelledby="contact-tab3"
                            style={{ marginTop: "5px" }}
                          >
                            <div class="col-lg-6">
                              <div class="form-group">
                                <label>Remarks:</label>
                                <textarea
                                  class="form-control"
                                  name="cust_remarks"
                                  onChange={(e) => setRemark(e.target.value)}
                                  value={remark}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="attechments"
                            role="tabpanel"
                            aria-labelledby="contact-tab4"
                            style={{ marginTop: "5px" }}
                          >
                            <p
                              style={{ marginLeft: "10px" }}
                              class="text-danger font-10"
                            >
                              Note : * Max file Size 50MB
                            </p>
                            <div class="row">
                              <div class="col-12">
                                <div class="row" id="dynamic_attech">
                                  {Attachment &&
                                    Attachment.map((item, id) => (
                                      <>
                                        <div class="col-6" id="colls1">
                                          <div class="card card-primary">
                                            <div class="card-header">
                                              <h4>{id + 1}</h4>
                                              <div class="card-header-action">
                                                <button
                                                  type="button"
                                                  class="btn btn-danger text-white btn_removes"
                                                  id="1"
                                                  onClick={() =>
                                                    onDeleteAttachment(id)
                                                  }
                                                >
                                                  <i
                                                    class="fa fa-times"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              </div>
                                            </div>
                                            <div class="card-body">
                                              <div class="form-group">
                                                <div class="form-group row !tw-my-1">
                                                  <label
                                                    for="attechmenttitle1"
                                                    class="col-sm-3 col-form-label"
                                                  >
                                                    Title
                                                  </label>
                                                  <div class="col-sm-9">
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="Title"
                                                      onChange={(value) =>
                                                        handleChangeAttachment({
                                                          target: {
                                                            name: "title",
                                                            value,
                                                            id,
                                                          },
                                                        })
                                                      }
                                                      value={
                                                        Attachment[id].title
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div class="form-group row">
                                                  <label
                                                    for="lastcontectname1"
                                                    class="col-sm-3 col-form-label"
                                                  >
                                                    Description
                                                  </label>
                                                  <div class="col-sm-9">
                                                    <textarea
                                                      class="form-control"
                                                      id="attechmentdescr1"
                                                      onChange={(value) =>
                                                        handleChangeAttachment({
                                                          target: {
                                                            name: "discription",
                                                            value,
                                                            id,
                                                          },
                                                        })
                                                      }
                                                      value={
                                                        Attachment[id]
                                                          .discription
                                                      }
                                                    ></textarea>
                                                  </div>
                                                </div>
                                                <div class="form-group row">
                                                  <label
                                                    for="lastcontectname1"
                                                    class="col-sm-3 col-form-label"
                                                  >
                                                    Attachment
                                                  </label>
                                                  <div class="col-sm-9">
                                                    <input
                                                      type="file"
                                                      class="form-control"
                                                      accept="application/msword,image/gif,image/jpeg,application/pdf,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,.doc,.gif,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.zip"
                                                      onChange={(value) =>
                                                        handleChangeAttachment({
                                                          target: {
                                                            name: "files",
                                                            value,
                                                            id,
                                                          },
                                                        })
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12 p-4">
                              <button
                                type="button"
                                name="add"
                                id="addatt"
                                class="btn btn-info"
                                onClick={onAddAttachment}
                              >
                                +Add Attachment
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p
                        style={{ marginLeft: "25px" }}
                        class="text-danger font-10"
                      >
                        Note : * Indicates Mandatory Fields
                      </p>
                      <div class="text-left">
                        <input
                          type="submit"
                          name="submit"
                          class={`btn btn-danger ${
                            btnFlag ? "disabled btn-progress" : ""
                          } mr-4`}
                          value="Submit"
                          id="submitvn"
                        />

                        <Link class="btn btn-light text-dark" to="/All-GSM">
                          Cancel
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
