import chroma from 'chroma-js'
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import GSM_Deshboard from './GSM_Deshboard';
import GSM_Custom_Dashboard from './GSM_Custom_Dashboard';
import { DashboardCardFilterGetTypeWise, getAllCategoryTableData, getGsmCustomDashboadList, getRolesById, InsertGsmCustomDashboard, UpdateGsmCustomDashboard } from '../../../Commoncomponet/Utils';
import { BootstrapTooltip } from '../../../Commoncomponet/common';
import { Tab } from '../../ui/Tabs';
import { Popover } from 'antd';
import TabsPopover from '../components/TabsPopover';
import { CirclePlus } from 'lucide-react';
import DropdownSelecter from '../../ui/dropdownSelecter';
import CustomTabModal from '../components/customTabModal';
import { toast } from 'react-toastify';


// =================================================================================
// =================================================================================
// =================================================================================
// Sir I change in this file many things and merge coustome dashboard in this 
// follow GUMSOPS-T2795
// =================================================================================
// =================================================================================

export default function GSM_Main_Dashboard() {
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const [loader, setLoader] = useState(false);
	const [activeTab, setActiveTab] = useState("");
	const [SubactiveTab, setSubActiveTab] = useState("");
	const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });
	const [AllCategory, setAllCategory] = useState({});
	const [FilterDay, setFilterDay] = useState([])
	const [isRefDownload, setisRefDownload] = useState(false)
	const [isDownloadBtnShow, setisDownloadBtnShow] = useState(false)
	const [permision, setpermision] = useState({})
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const [isFilterShow, setIsFilterShow] = useState(true); // GUMSOPS-T2795 add 
	const [TreeSelection, setTreeSelection] = useState([]);  // GUMSOPS-T2795 add 
	const [categoryLoading, setCategoryLoading] = useState(true);  // GUMSOPS-T2795 add
	const [DashTabs, setDashTabs] = useState([]); // GUMSOPS-T2795 add
	const [tabModal, setTabModal] = useState({ isOpen: false, TabID: null, disabled: false, isLoading: false }) // GUMSOPS-T2795 add
	const [tabDetails, setTabDetails] = useState({ name: '', description: '' })  // GUMSOPS-T2795 add

	// GUMSOPS-T2795 add
	const staticTabsDataArrtay = [
		{
			title: "Device Dashboard",
			value: "Device Dashboard"
		},
		{
			title: "EVC/MC Meter Dashboard",
			value: "EVC/MC Meter Dashboard"
		}
	]

	// GUMSOPS-T2795 add
	useEffect(() => {
		setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] })
		setisDownloadBtnShow(false)
	}, [activeTab, SubactiveTab])
	// GUMSOPS-T2795 add
	const ongetAllCategoryTableData = async (info) => {
		const data = await getAllCategoryTableData({})
		if (data.success === true) {
			setCategoryLoading(false)  // GUMSOPS-T2795 add 
			setAllCategory(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}
	// GUMSOPS-T2795 add
	const onGetFilterDaywise = async () => {
		const data2 = await DashboardCardFilterGetTypeWise('GSM', userInfo?.UNIQUE_ID)
		if (data2.success == true) {
			setFilterDay(data2?.data?.data || [])
		}
	}
	// GUMSOPS-T2795 add
	const onHandaleRoles = async () => {
		setLoader(true)
		const data = await getRolesById(userInfo?.ROLE);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({
				...obj,
				ROLES_PERMISSIONS_CATEGORY0: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY0),
				ROLES_PERMISSIONS_CATEGORY5: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY5),
				ROLES_PERMISSIONS_CATEGORY2: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY2),
				ROLES_PERMISSIONS_CATEGORY3: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY3),
				ROLES_PERMISSIONS_CATEGORY1: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY1),
				ROLES_PERMISSIONS_CATEGORY4: JSON.parse(obj.ROLES_PERMISSIONS_CATEGORY4),
				ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS)
			});
			onLoadCosutomDashboardList(JSON.parse(obj.ROLES_OTHER_PERMISSIONS))
		} else {
			setLoader(false)
		}
	}
	// GUMSOPS-T2795 add
	const onLoadCosutomDashboardList = async (info) => {
		const data = await getGsmCustomDashboadList()
		if (data.success == true) {
			const dashtab = data?.data?.data?.filter((item) => (info?.CUSTOM_DASHBOARD?.dashboard_view?.includes(item?.unique_id) || userInfo?.ROLE == 'ROLE1650614500708'))?.map((item) => ({ title: item?.name, value: item?.unique_id, id: item?.unique_id, ...item }))
			setDashTabs(dashtab)
			setLoader(false)
		} else {
			console.log('something want wrong')
			setLoader(false)
		}
	}
	// GUMSOPS-T2795 add
	useEffect(() => {
		onHandaleRoles()
		ongetAllCategoryTableData()
		
	}, [])
	// GUMSOPS-T2795 add
	useEffect(() => {
		if (Object.keys(permision).length && (permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard') || userInfo?.ROLE === "ROLE1650614500708")) {
			setActiveTab("Custom Dashboard")
			onGetFilterDaywise()
		} else if (Object.keys(permision).length && (permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard') || userInfo?.ROLE === "ROLE1650614500708")) {
			setActiveTab("Static Dashboard")

		}
	}, [permision])
	// GUMSOPS-T2795 add
	useEffect(() => {
		onLoadCosutomDashboardList(permision?.ROLES_OTHER_PERMISSIONS)
	}, [activeTab])

	//  GUMSOPS-T2795 add handleCheck
	const handleCheck = (id, isChecked, flage) => {

		if (!!flage) {
			const updateAllChecked = (tree, isChecked) => {

				function updateNode(node) {
					node.checked = isChecked;
					if (node.children && node.children.length > 0) {
						node.children.forEach(updateNode);
					}
					return node;
				}

				return tree.map((e) => updateNode(e));
			}

			const finalData = updateAllChecked(TreeSelection, isChecked);

			setTreeSelection(finalData);
		}
		else {

			const updateNodeAndParents = (nodes, targetId) => {

				return nodes.map(node => {
					if (node.value === targetId) {
						if (node.children) {
							return recursivelyDeselectNode(node);
						}

						return { ...node, checked: isChecked };
					}

					if (node.children) {
						const updatedChildren = updateNodeAndParents(node.children, targetId);

						const anyChildChecked = updatedChildren.some(child => child.checked);

						return {
							...node,
							checked: anyChildChecked ? true : node.checked,
							children: updatedChildren
						};
					}

					return node;
				});
			};

			// Recursive function to deselect a node and all its children
			const recursivelyDeselectNode = (node) => {
				// Deselect the current node
				const deselectedNode = {
					...node,
					checked: isChecked
				};

				// If the node has children, recursively deselect them
				if (deselectedNode.children) {
					deselectedNode.children = deselectedNode.children.map(recursivelyDeselectNode);
				}

				return deselectedNode;
			};

			const finalData = updateNodeAndParents(TreeSelection, id);

			setTreeSelection(finalData);
		}
	};
	// GUMSOPS-T2795 add
	const onClickOfTab = (tab, subTab) => {
		setActiveTab(tab)
		if (subTab === SubactiveTab) {
			setSubActiveTab(null)
		} else {
			setSubActiveTab(subTab)
		}
	}
	// GUMSOPS-T2795 add
	const handleTabModel = (TabID = null) => {
		if (TabID) {
			const tabDetails = DashTabs.filter((item) => (item?.id == TabID))[0]
			setTabDetails({ name: tabDetails?.title, description: tabDetails?.description })
		}
		setTabModal({ ...tabModal, isOpen: true, disabled: !!TabID, TabID: TabID, })
	}

	// GUMSOPS-T2795 add
	const onSubmit = async () => {

		setTabModal({ ...tabModal, isLoading: true })
		if (tabDetails?.name !== "" && !(!!tabDetails?.name)) {
			toast.error("Require Enter Dashboard Name");
			setTabModal({ ...tabModal, isLoading: false })
			return
		}
		const data = tabModal?.TabID === null ? await InsertGsmCustomDashboard({ ...tabDetails, add_by: userInfo.UNIQUE_ID }) : await UpdateGsmCustomDashboard({ ...tabDetails, add_by: userInfo.UNIQUE_ID }, tabModal?.TabID);

		if (data.success === true) {
			toast.success(tabModal?.TabID === null ? "Add Dashboard Success" : "Update Dashboard Success");
			setTabModal({ ...tabModal, isOpen: false, disabled: false, TabID: null, isLoading: false })
			setTabDetails({ name: '', description: '' })
			onLoadCosutomDashboardList(permision?.ROLES_OTHER_PERMISSIONS)
		} else {
			toast.error("something want wrong");
			setTabModal({ ...tabModal, isLoading: false })
		}

	};

	return (
		<>

			<CustomTabModal
				TabID={tabModal?.TabID}
				onFiledEditHandler={() => setTabModal({ ...tabModal, disabled: false })}
				isOpen={tabModal?.isOpen}
				handleModelClose={() => setTabModal({ ...tabModal, isOpen: false, TabID: null, disabled: false })}
				TabDetails={tabDetails}
				setTabDetails={setTabDetails}
				disabled={tabModal?.disabled}
				isLoading={tabModal?.isLoading}
				onSubmit={onSubmit}
			/>

			{/* GUMSOPS-T3048 add className  !tw-pt-16 !tw-pr-4*/}
			<div className="main-content !tw-pt-16 !tw-pr-3">
				{loader ? (
					<div className="main-content">
						<div className="loader"></div>
					</div>
				) : (
					<div>
						{/* // className='tw-sticky tw-top-16 tw-left-0 tw-w-full tw-z-[9999]' */}
						<nav aria-label="breadcrumb"  >
							<ol className="breadcrumb tw-gap-x-1.5 !tw-mb-2">
								<li className="breadcrumb-data">
									<Link to="/all-dashboard ">
										<i className="fas fa-tachometer-alt "></i> Home /
									</Link>
								</li>
								<li className="breadcrumb-data ">
									<Link to='/gsm_dashboard'> GSM Dashboard</Link>
								</li>
							</ol>
						</nav>
						<section className="section">
							<div className="section-body">
								<div className="row">
									<div className="col-12 col-sm-12 col-lg-12 tw-p-0 tw-sticky tw-top-16 tw-left-0 tw-w-full tw-z-[9999]">
										<div className="card !tw-mb-4">
											<div className="card-header d-flex tw-border-b-0 " style={{ justifyContent: 'space-between' }}>
												<ul className="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>

													{(permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard') || userInfo?.ROLE === "ROLE1650614500708") &&
														(<DropdownSelecter
															name={'Custom Dashboard'}
															value={'Custom Dashboard'}
															activeTab={activeTab}
															selectedCard={DashTabs?.find(v => v?.value === SubactiveTab)?.title || null}
															popoverContent={<TabsPopover
																isEdit={true}
																handleTabModel={handleTabModel}
																dataArray={DashTabs}
																selectedCard={SubactiveTab}
																setSelectedCard={(value) => onClickOfTab('Custom Dashboard', value)}
																isEditPermission={userInfo?.ROLE === "ROLE1650614500708"}
																isAddPermission={userInfo?.ROLE === "ROLE1650614500708"}
															/>}
														/>)
													}

													{(permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard') || userInfo?.ROLE === "ROLE1650614500708") &&
														(<DropdownSelecter
															name={'Static Dashboard'}
															value={'Static Dashboard'}
															activeTab={activeTab}
															selectedCard={staticTabsDataArrtay?.find(v => v?.value === SubactiveTab)?.title || null}
															popoverContent={<TabsPopover dataArray={staticTabsDataArrtay} selectedCard={SubactiveTab} setSelectedCard={(value) => onClickOfTab('Static Dashboard', value)} />}
														/>)
													}

													{Object.keys(permision).length && !permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Custom_Dashboard') && !permision?.ROLES_OTHER_PERMISSIONS?.GSM_DASHBOARD?.includes('Static_Dashboard') && userInfo?.ROLE !== "ROLE1650614500708" ? <h4>Don't have permission any dashboard</h4> : ''}
												</ul>
											</div>
										</div>
									</div>

									{/* // GUMSOPS-T2795 pass isFilterShow, setIsFilterShow TreeSelection, setTreeSelection categoryLoading, setCategoryLoading  handleCheck*/}
									{(SubactiveTab != '' && !!SubactiveTab) && <GSM_Deshboard categoryLoading={categoryLoading} Profile={Profile} activeTab={activeTab === 'Custom Dashboard' ? activeTab : SubactiveTab} AllCategory={AllCategory} AccounData={AllCategory?.category0} GaData={AllCategory?.category1} CircleData={AllCategory?.category2} DivisionData={AllCategory?.category3} SubdevisionData={AllCategory?.category4} AreaData={AllCategory?.category5} AllCategoryJson={AllCategory} setisRefDownload={setisRefDownload} isRefDownload={isRefDownload} setisDownloadBtnShow={setisDownloadBtnShow} isDownloadBtnShow={isDownloadBtnShow} isFilterShow={isFilterShow} setIsFilterShow={setIsFilterShow} setProfile={setProfile} TreeSelection={TreeSelection} setTreeSelection={setTreeSelection} handleCheck={handleCheck} onLoadCosutomDashboardList={(info) => onLoadCosutomDashboardList(info)} dashboardId={SubactiveTab} DashTabDetailById={DashTabs?.find(v => v?.value === SubactiveTab) || {}} existPermision={permision} />}

									{/* GUMSOPS-T2795 remove this */}
									{/* // GUMSOPS-T2795 pass isFilterShow, setIsFilterShow TreeSelection, setTreeSelection categoryLoading, setCategoryLoading handleCheck*/}
									{/* {activeTab == 'Custom Dashboard' && <GSM_Custom_Dashboard categoryLoading={categoryLoading} Profile={Profile} AccounData={AllCategory?.category0} GaData={AllCategory?.category1} CircleData={AllCategory?.category2} DivisionData={AllCategory?.category3} SubdevisionData={AllCategory?.category4} AreaData={AllCategory?.category5} AllCategoryJson={AllCategory} FilterDay={FilterDay} onGetFilterDaywise={onGetFilterDaywise} setFilterDay={setFilterDay} setisRefDownload={setisRefDownload} isRefDownload={isRefDownload} setisDownloadBtnShow={setisDownloadBtnShow} setProfile={setProfile} isDownloadBtnShow={isDownloadBtnShow} isFilterShow={isFilterShow} setIsFilterShow={setIsFilterShow} TreeSelection={TreeSelection} setTreeSelection={setTreeSelection} handleCheck={handleCheck} SubactiveTab={SubactiveTab} />} */}
								</div>
							</div>
						</section>
					</div>
				)}

			</div>
		</>
	)
}
