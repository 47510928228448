import axios from 'axios'

const url = 'https://gumsops.memighty.com:7001/api'
// const url = 'http://localhost:7001/api'
const token = window.localStorage.getItem('token')

const config = () => ({
    headers: {
        Authorization: window.localStorage.getItem('token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export const LoginUesr = async (info) => {
    try {
        const res = await axios.post(`https://gumsops.memighty.com:7001/loginuser`, info)
        console.log(res);
        return { success: true, data: res.data }
    } catch (e) {
        console.log(e);
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LoginUserWithMobileNo = async (info) => {
    try {
        const res = await axios.post(`http://localhost:7001/LoginUserWithMobileNo`, info)
        console.log(res);
        return { success: true, data: res.data }
    } catch (e) {
        console.log(e);
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const FindURL=async(info)=>{
    try {
const res=await axios.post(`${url}/FindURL`,info)
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const VaildToken = async (token) => {
    try {
        const res = await axios.get(`${url}/VaildToken`,{
            headers: {
                Authorization: token,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const sendOtp = async (info) => {
    try {
        const res = await axios.post(`${url}/sendOtp`, info)
        return { success: true, data: res.data }
    } catch (e) {
        console.log(e);
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const CheckValidUser = async (info) => {
    try {
        const res = await axios.post(`${url}/CheckValidUser`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const SocketBaseValidUserCheck = async (info) => {
    try {
        const res = await axios.post(`${url}/SocketBaseValidUserCheck`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const addUsersLogin = async (info) => {
    try {
        const res = await axios.post(`${url}/addUsersLogin`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsers = async (isfilter,roles,user_id) => {
    try {
        const res = await axios.get(`${url}/getUsers?isfilter=${isfilter}&roles=${roles}&user_id=${user_id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateUser = async (userId, info) => {
    try {
        const res = await axios.put(`${url}/updateUser/${userId}`, info, config())
        return { success: true, msg: "Successfully Added" }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAccount = async (info) => {
    try {
        const res = await axios.get(`${url}/getAccount?status_filter=${info}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getArea = async (info) => {
    try {
        const res = await axios.get(`${url}/getArea?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&CATEGORY3=${info?.CATEGORY3}&CATEGORY4=${info?.CATEGORY4}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCircle = async (info) => {
    try {
        const res = await axios.get(`${url}/getCircle?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDivision = async (info) => {
    try {
        const res = await axios.get(`${url}/getDivision?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGa = async (info) => {
    try {
        const res = await axios.get(`${url}/getGa?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSubdevision = async (info) => {
    try {
        const res = await axios.get(`${url}/getSubdevision?search=${info?.search}&CATEGORY0=${info?.CATEGORY0}&CATEGORY1=${info?.CATEGORY1}&CATEGORY2=${info?.CATEGORY2}&CATEGORY3=${info?.CATEGORY3}&roleSerach=${info?.roleSerach}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertAccount = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertAccount`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateAccount = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateAccount/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGa = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertGa`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGa = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateGa/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertCircle = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertCircle`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateCircle = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateCircle/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertDivision = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertDivision`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateDivision = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateDivision/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertSubDevision = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertSubDevision`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateSubDivision = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateSubDivision/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertArea = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertArea`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateArea = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateArea/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllModem = async (status) => {
    try {
        const res = await axios.get(`${url}/getAllModem?filterStatus=${status}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemFirmware = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemFirmware`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemHardwareVersion = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemHardwareVersion`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemMake = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemMake`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemModelNumber = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemModelNumber`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemProductCode = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemProductCode`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemServerCommunication = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemServerCommunication`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemType = async (info) => {
    try {
        const res = await axios.get(`${url}/getModemType`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertFirmware = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertFirmware`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateFirmware = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateFirmware/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_hardware_version = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_hardware_version`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_hardware_version = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_hardware_version/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_model_number = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_model_number`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_model_number = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_model_number/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_make = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_make`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_make = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_make/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const Insertmodem_product_code = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_product_code`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_product_code = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_product_code/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_Sever_communication = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_Sever_communication`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_Sever_communicatione = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_Sever_communicatione/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_type = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_type`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_type = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_type/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmodem_index = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmodem_index`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemodem_index = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemodem_index/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMeter = async (status) => {
    try {
        const res = await axios.get(`${url}/getAllMeter?filterStatus=${status}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_category = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_category`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_class = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_class`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getmeter_firmware = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_firmware`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_hardware_protocol = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_hardware_protocol`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_make = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_make`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_model_number = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_model_number`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_software_protocol = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_software_protocol`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_type = async (info) => {
    try {
        const res = await axios.get(`${url}/getmeter_type`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_category = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_category`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_category = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_category/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_class = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_class`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemmeter_class = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemmeter_class/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const Insertmeter_make = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_make`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_make = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_make/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_model_number = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_model_number`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_model_number = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_model_number/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_firmware = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_firmware`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_firmware = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_firmware/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_hardware_protocol = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_hardware_protocol`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_hardware_protocol = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_hardware_protocol/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_software_protocol = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_software_protocol`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_software_protocol = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_software_protocol/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_type = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_type`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemmeter_type = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemmeter_type/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmeter_index = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmeter_index`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_index = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_index/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemeter_index_attachments = async (id, info) => {
    try {
        const res = await axios.put(`${url}/Updatemeter_index_attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemechanical_index_attachments = async (id, info) => {
    try {
        const res = await axios.put(`${url}/Updatemechanical_index_attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmeter_update_data = async (id, info) => {
    try {
        const res = await axios.get(`${url}/getmeter_update_data/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmechanical_update_data = async (id, info) => {
    try {
        const res = await axios.get(`${url}/getmechanical_update_data/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertScriptdecodeId = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertScriptdecodeId`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdatScriptdecodeId = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdatScriptdecodeId/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScriptdecodeId = async (info) => {
    try {
        const res = await axios.get(`${url}/getScriptdecodeId`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllRoles = async (isfilter,roles,user_id) => {
    try {
        const res = await axios.get(`${url}/getAllRoles?isfilter=${isfilter}&roles=${roles}&user_id=${user_id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemByID = async (id) => {
    try {
        const res = await axios.get(`${url}/getModemByID/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterByID = async (id) => {
    try {
        const res = await axios.get(`${url}/getMeterByID/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmData = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertGsmData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertLoraData = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertLoraData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AddModemDataINLORA = async (id, info) => {
    try {
        const res = await axios.post(`${url}/AddModemDataINLORA/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getGSMData = async (info, data) => {
    try {
        console.log(data);
        const res = await axios.get(`${url}/getGSMData?start=${info?.start}&end=${info.end}`, {
            method: 'GET',
            params: data,
            headers: {
                Authorization: window.localStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMDataById = async (id) => {
    try {
        const res = await axios.get(`${url}/getGSMDataById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const UpdateInGSMmodem = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateInGSMmodem/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Update_Gsm_consumer_Attachments = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Update_Gsm_consumer_Attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Update_Lorawan_consumer_Attachments = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Update_Lorawan_consumer_Attachments/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInLoramodem = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateInLoramodem/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAreaById = async (id) => {
    try {
        const res = await axios.get(`${url}/getAreaById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCircleById = async (id) => {
    try {
        const res = await axios.get(`${url}/getCircleById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDivisionById = async (id) => {
    try {
        const res = await axios.get(`${url}/getDivisionById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGaById = async (id) => {
    try {
        const res = await axios.get(`${url}/getGaById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSubdevisionById = async (id) => {
    try {
        const res = await axios.get(`${url}/getSubdevisionById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertsRoles = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertsRoles`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateRoles = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateRoles/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getRolesById = async (id) => {
    try {
        const res = await axios.get(`${url}/getRolesById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const activeandinactive = async (info, id) => {
    try {
        const res = await axios.post(`${url}/activeandinactive/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const CheckUseConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/CheckUseConsumer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLorawanData = async (info, data) => {
    try {
        const res = await axios.get(`${url}/getLorawanData?start=${info?.start}&end=${info.end}`, {
            method: 'GET',
            params: data,
            headers: {
                Authorization: window.localStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
        return { success: true, data: res.data }

    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertservice_provider_index = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertservice_provider_index`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updateservice_provider_index = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updateservice_provider_index/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getServiceProfile = async (id) => {
    try {
        const res = await axios.get(`${url}/getServiceProfile`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertLorawanData = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertLorawanData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInLorawanmodem = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateInLorawanmodem/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getLorawanDataById = async (id) => {
    try {
        const res = await axios.get(`${url}/getLorawanDataById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmModemSerchData = async (info) => {
    try {
        const res = await axios.post(`${url}/gsmModemSerchData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemSearchTextConsumerDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemSearchTextConsumerDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LoraModemSerchData = async (info) => {
    try {
        const res = await axios.post(`${url}/LoraModemSerchData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getHeardbeatData = async (info, data) => {
    try {
        const res = await axios.post(`${url}/getHeardbeatData?start=${info?.start}&end=${info.end}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraTableWiseData = async (info, data) => {
    try {
        const res = await axios.post(`${url}/getLoraTableWiseData?start=${info?.start}&end=${info.end}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMChartByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMChartByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGroupWiseConsumerStatusCount = async (info) => {
    try {
        const res = await axios.post(`${url}/getGroupWiseConsumerStatusCount`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllLorawanChartByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllLorawanChartByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterDashGSMChart = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterDashGSMChart`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterDashGSMInstantaneousChart = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterDashGSMInstantaneousChart`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterDashLoraChart = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterDashLoraChart`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCountStatusByGa = async (info) => {
    try {
        const res = await axios.post(`${url}/getCountStatusByGa`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraCountStatusByGa = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraCountStatusByGa`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const uploadsFiles = async (info, id) => {
    try {
        const res = await axios.post(`${url}/uploads/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Taskuploads = async (info) => {
    try {
        const res = await axios.post(`${url}/Taskuploads`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const uploadsDeleteFile = async (info) => {
    try {
        const res = await axios.post(`${url}/uploadsDeleteFile`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UserPhotouploads = async (info) => {
    try {
        const res = await axios.post(`${url}/UserPhotouploads`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getBlobImages = async (info) => {
    try {
        const res = await axios.post(`${url}/getBlobImages`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGenretedAllGSMReport = async (info, data) => {
    try {
        const res = await axios(`${url}/getGenretedAllGSMReport`, {
            method: 'post',
            headers: {
                Authorization: window.localStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: { ...info, CATEGORY1_view: data }
        })
        return { success: true, data: res.data }

    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getReportBytypeName = async (UserId, roleId, type, info) => {
    try {
        const res = await axios.get(`${url}/getReportBytypeName/${UserId}?type=${type}&roleId=${roleId}&start=${info?.start}&end=${info?.end}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmModemFilterData = async (date, info) => {
    try {
        const res = await axios.post(`${url}/gsmModemFilterData?start=${date?.start}&end=${date.end}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LoraModemFilterData = async (date, info) => {
    try {
        const res = await axios.post(`${url}/LoraModemFilterData?start=${date?.start}&end=${date.end}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getUsersByID = async (id) => {
    try {
        const res = await axios.get(`${url}/getUsersByID/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateUserPassword = async (id, info) => {
    try {
        const res = await axios.post(`${url}/updateUserPassword/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MeterFileuploads = async (info) => {
    try {
        const res = await axios.post(`${url}/MeterFileuploads`, info, {
            headers: {
                Authorization: window.localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
            },
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GSM_Smart_Meter_Driver_Attachments = async (info) => {
    try {
        const res = await axios.post(`${url}/GSMSMartMeterFileuploads`, info, {
            headers: {
                Authorization: window.localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
            },
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MechanicalFileuploads = async (info) => {
    try {
        const res = await axios.post(`${url}/MechanicalFileuploads`, info, {
            headers: {
                Authorization: window.localStorage.getItem('token'),
                'Content-Type': 'multipart/form-data',
            },
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateComentAndHistory = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateComentAndHistory/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCommentAndHistoryById = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getCommentAndHistoryById`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAttachmentBymeterid = async (id) => {
    try {
        const res = await axios.get(`${url}/getAttachmentBymeterid/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAttachmentBymechanicalid = async (id) => {
    try {
        const res = await axios.get(`${url}/getAttachmentBymechanicalid/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetTableNameWiseChartDataByDate = async (info, id) => {
    try {
        const res = await axios.post(`${url}/GetTableNameWiseChartDataByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetLoraTableNameWiseChartDataByDate = async (info, id) => {
    try {
        const res = await axios.post(`${url}/GetLoraTableNameWiseChartDataByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LetestCountAndLastDateByTableName = async (info, id) => {
    try {
        const res = await axios.post(`${url}/LetestCountAndLastDateByTableName`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LetestCountAndLastDateByLoraTableName = async (info, id) => {
    try {
        const res = await axios.post(`${url}/LetestCountAndLastDateByLoraTableName`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertsTags = async (info, id) => {
    try {
        const res = await axios.post(`${url}/InsertsTags`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTags = async (id) => {
    try {
        const res = await axios.get(`${url}/getTags`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateTags = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateTags/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTagsInGsm = async (info) => {
    try {
        const res = await axios.post(`${url}/getTagsInGsm`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateTagsInGsm = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateTagsInGsm/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateTagsInLorawan = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateTagsInLorawan/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllModemTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllModemTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMeterTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllMeterTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraAllModemTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraAllModemTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraAllMeterTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraAllMeterTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmodemFirmwarTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getmodemFirmwarTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmodemFirmwarTotalLORA = async (info) => {
    try {
        const res = await axios.post(`${url}/getmodemFirmwarTotalLORA`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMapDataInGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllMapDataInGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMapDataInLorawan = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllMapDataInLorawan`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMapDataInGSMmapView = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllMapDataInGSMmapView`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMapDataInLorawanmapView = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllMapDataInLorawanmapView`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDataInLorawanGatewayList = async (info) => {
    try {
        const res = await axios.post(`${url}/getDataInLorawanGatewayList`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetConsumerDetailsByColumn = async (info) => {
    try {
        const res = await axios.post(`${url}/onGetConsumerDetailsByColumn`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetLoraConsumerDetailsByColumn = async (info) => {
    try {
        const res = await axios.post(`${url}/onGetLoraConsumerDetailsByColumn`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScriptCodeIdWiseColumnName = async (id) => {
    try {
        const res = await axios.get(`${url}/getScriptCodeIdWiseColumnName/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMBufferData = async (info, data) => {
    try {
        const res = await axios.get(`${url}/getGSMBufferData?start=${info?.start}&end=${info.end}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const RetryGSMBuffer = async (id, tableName) => {
    try {
        const res = await axios.post(`${url}/RetryGSMBuffer/${id}/${tableName}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const cancalGSMBuffer = async (id, tableName) => {
    try {
        const res = await axios.post(`${url}/cancalGSMBuffer/${id}/${tableName}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMBufferDataAllCountByStatus = async (tableName) => {
    try {
        const res = await axios.get(`${url}/getGSMBufferDataAllCountByStatus/${tableName}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetBuferCountInByStatus = async (id, tableName) => {
    try {
        const res = await axios.get(`${url}/GetBuferCountInByStatus/${id}/${tableName}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScriptCodeIdWiseConfigretionData = async (id, tableName) => {
    try {
        const res = await axios.post(`${url}/getScriptCodeIdWiseConfigretionData/${id}`, tableName, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionStatus = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionStatus`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMRSSIInDeshboardAllCountByStatus = async (info) => {
    try {
        const res = await axios.post(`${url}/getGSMRSSIInDeshboardAllCountByStatus`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraRSSIInDeshboardAllCountByStatus = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraRSSIInDeshboardAllCountByStatus`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getBateeryPercentageAllCountByStatus = async (info) => {
    try {
        const res = await axios.post(`${url}/getBateeryPercentageAllCountByStatus`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraSNRAllCountByStatus = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraSNRAllCountByStatus`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraBateeryPercentageAllCountByStatus = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraBateeryPercentageAllCountByStatus`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMBufferInDeshboardAllCountByStatus = async (id, tableName) => {
    try {
        const res = await axios.get(`${url}/getGSMBufferInDeshboardAllCountByStatus/${id}/${tableName}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraBufferInDeshboardAllCountByStatus = async (id, tableName) => {
    try {
        const res = await axios.get(`${url}/getLoraBufferInDeshboardAllCountByStatus/${id}/${tableName}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetConsumerDetailsByColumnBetween = async (info) => {
    try {
        const res = await axios.post(`${url}/onGetConsumerDetailsByColumnBetween`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetLoraConsumerDetailsByColumnBetween = async (info) => {
    try {
        const res = await axios.post(`${url}/onGetLoraConsumerDetailsByColumnBetween`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMBufferDataByGSMID = async (info, id) => {
    try {
        const res = await axios.get(`${url}/getGSMBufferDataByGSMID/${id}?start=${info?.start}&end=${info.end}&tableName=${info.tableName}&info=${info?.info}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getStartAndEndDateByTable = async (info) => {
    try {
        const res = await axios.post(`${url}/getStartAndEndDateByTable`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetTableNameWiseChartDataByStartDateEndDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetTableNameWiseChartDataByStartDateEndDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getRSSITotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getRSSITotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getRSSITotalLora = async (info) => {
    try {
        const res = await axios.post(`${url}/getRSSITotalLora`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMTotalCountByTS = async (info) => {
    try {
        const res = await axios.post(`${url}/getGSMTotalCountByTS`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getLoraTotalCountByTS = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraTotalCountByTS`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const createTableByNameAndCol = async (info) => {
    try {
        const res = await axios.post(`${url}/createTableByNameAndCol`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const onGetTableName = async (id) => {
    try {
        const res = await axios.get(`${url}/onGetTableName`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsm_instantaneous_day_wise_graph_details = async (info) => {
    try {
        const res = await axios.post(`${url}/gsm_instantaneous_day_wise_graph_details`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const lora_instantaneous_day_wise_graph_details = async (info) => {
    try {
        const res = await axios.post(`${url}/lora_instantaneous_day_wise_graph_details`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsm_monitor_day_wise_graph_details = async (info) => {
    try {
        const res = await axios.post(`${url}/gsm_monitor_day_wise_graph_details`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Lora_monitor_day_wise_graph_details = async (info) => {
    try {
        const res = await axios.post(`${url}/Lora_monitor_day_wise_graph_details`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onModemConfigByGSMConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/onModemConfigByGSMConsumer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onModemConfigByLoraConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/onModemConfigByLoraConsumer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConsumerWiseDataInScalerUnitBystatus = async (id) => {
    try {
        const res = await axios.get(`${url}/getConsumerWiseDataInScalerUnitBystatus/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionCMD_TYPEWiseData = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionCMD_TYPEWiseData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetConsumerIncolumeName = async (info) => {
    try {
        const res = await axios.post(`${url}/onGetConsumerIncolumeName`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetLoraConsumerIncolumeName = async (info) => {
    try {
        const res = await axios.post(`${url}/onGetLoraConsumerIncolumeName`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmodemServiceProviderTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getmodemServiceProviderTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraLDR = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraLDR`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTagsWiseConsumerData = async (info) => {
    try {
        const res = await axios.post(`${url}/getTagsWiseConsumerData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraTagsWiseConsumerData = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraTagsWiseConsumerData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionLetestData = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionLetestData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMRejectedData = async (info) => {
    try {
        const res = await axios.post(`${url}/getGSMRejectedData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmodemMeterConnectivityTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getmodemMeterConnectivityTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetTableNameWiseChartRSSIDataByStartDateEndDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetTableNameWiseChartRSSIDataByStartDateEndDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getmodemConnectivityTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getmodemConnectivityTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSM_BANDTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getGSM_BANDTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLorawanSF = async (info) => {
    try {
        const res = await axios.post(`${url}/getLorawanSF`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAPMTotalGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getAPMTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetInstallationDateWiseGraphData = async (info) => {
    try {
        const res = await axios.post(`${url}/GetInstallationDateWiseGraphData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetLoraInstallationDateWiseGraphData = async (info) => {
    try {
        const res = await axios.post(`${url}/GetLoraInstallationDateWiseGraphData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMEventChartByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMEventChartByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMMonthlyChartByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMMonthlyChartByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemStatusByTable = async (info) => {
    try {
        const res = await axios.post(`${url}/GetModemStatusByTable`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemStatusDurationByTable = async (info) => {
    try {
        const res = await axios.post(`${url}/GetModemStatusDurationByTable`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemStatusDurationByTableAndDATE = async (info) => {
    try {
        const res = await axios.post(`${url}/GetModemStatusDurationByTableAndDATE`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemStatusByTableAndDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetModemStatusByTableAndDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScriptCodeIdWiseConfigretionQuery = async (info) => {
    try {
        const res = await axios.post(`${url}/getScriptCodeIdWiseConfigretionQuery`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMChartByDateSlection = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMChartByDateSlection`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllLoraChartByDateSlection = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllLoraChartByDateSlection`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetInstallationDateWiseGraphDataBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetInstallationDateWiseGraphDataBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetLoraInstallationDateWiseGraphDataBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetLoraInstallationDateWiseGraphDataBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getIPVTotalINGSM = async (info) => {
    try {
        const res = await axios.post(`${url}/getIPVTotalINGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMMonthlyChartBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMMonthlyChartBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getAllGSMEventChartBySelectedDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMEventChartBySelectedDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const reportCreacteInTableName = async (info) => {
    try {
        const res = await axios.post(`${url}/reportCreacteInTableName`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionCMD_TYPEWisePendding_Data_buffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionCMD_TYPEWisePendding_Data_buffer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionCMD_TYPEWisePendding_Data_Lora_buffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionCMD_TYPEWisePendding_Data_Lora_buffer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsersList = async (info,user_id,role,type) => {
    try {
        const res = await axios.get(`${url}/getUsersList?user_id=${user_id}&role=${role}&type=${type}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMConsumerList = async (info) => {
    try {
        const res = await axios.post(`${url}/getGSMConsumerList`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const InsertsTaskDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertsTaskDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllTaskDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskDetailsByUniqueId = async (id) => {
    try {
        const res = await axios.get(`${url}/getAllTaskDetailsByUniqueId/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateTaskDetails = async (info, id) => {
    try {
        const res = await axios.put(`${url}/UpdateTaskDetails/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateTaskDetailesComentAndHistory = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateTaskDetailesComentAndHistory/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskCommentAndHistoryById = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getTaskCommentAndHistoryById`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getEventCountList = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getEventCountList`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getErrorbinAndDuplicatbinCountList = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getErrorbinAndDuplicatbinCountList`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraErrorbinAndDuplicatbinCountList = async (info, id) => {
    try {
        const res = await axios.post(`${url}/getLoraErrorbinAndDuplicatbinCountList`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCategoryNameByUniqId = async (info) => {
    try {
        const res = await axios.post(`${url}/getCategoryNameByUniqId`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const getProfilNameByAccountId = async (info) => {
    try {
        const res = await axios.post(`${url}/getProfilNameByAccountId`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const FilterIdWiseNewFilterGenerate = async (info) => {
    try {
        const res = await axios.post(`${url}/FilterIdWiseNewFilterGenerate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmModemColumanWiseSerchData = async (info) => {
    try {
        const res = await axios.post(`${url}/gsmModemColumanWiseSerchData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskDetailsByConsumerId = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllTaskDetailsByConsumerId`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionCMD_TYPEWisePendding_Count_buffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionCMD_TYPEWisePendding_Count_buffer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getHeatbeatStatusWiseCount = async (info) => {
    try {
        const res = await axios.post(`${url}/getHeatbeatStatusWiseCount`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraHeatbeatStatusWiseCount = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraHeatbeatStatusWiseCount`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemConsumerDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemConsumerDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemConsumerDetailsByFilter = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemConsumerDetailsByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const DownloadModemConsumerDetailsByFilter = async (info) => {
    try {
        const res = await axios.post(`${url}/DownloadModemConsumerDetailsByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertModemDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertModemDetails`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateModemDetails = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateModemDetails/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemTypeWiseIMEI = async (id) => {
    try {
        const res = await axios.get(`${url}/GetModemTypeWiseIMEI/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetIMEIWiseModemData = async (id) => {
    try {
        const res = await axios.get(`${url}/GetIMEIWiseModemData/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInGSMModemDataAndModemTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateInGSMModemDataAndModemTable/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInGSMmeterDataAndmeterTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateInGSMmeterDataAndmeterTable/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInLoraModemDataAndModemTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateInLoraModemDataAndModemTable/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInLorameterDataAndmeterTable = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateInLorameterDataAndmeterTable/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCountUseToConsumerInIMEI = async (id, update) => {
    try {
        const res = await axios.get(`${url}/getCountUseToConsumerInIMEI/${id}?update=${update?.update}&ID=${update?.ID}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateConsumerStatusInMaintanace = async (id) => {
    try {
        const res = await axios.get(`${url}/UpdateConsumerStatusInMaintanace/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getHeatbeatStatusWiseCountInGroupDashboard = async (info) => {
    try {
        const res = await axios.post(`${url}/getHeatbeatStatusWiseCountInGroupDashboard`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getHeatbeatStatusWiseCountInLoraGroupDashboard = async (info) => {
    try {
        const res = await axios.post(`${url}/getHeatbeatStatusWiseCountInLoraGroupDashboard`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const AddMeterDataINGSM = async (id, info) => {
    try {
        const res = await axios.post(`${url}/AddMeterDataINGSM/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AddLoraMeterDataINGSM = async (id, info) => {
    try {
        const res = await axios.post(`${url}/AddLoraMeterDataINGSM/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AddModemDataINGSM = async (id, info) => {
    try {
        const res = await axios.post(`${url}/AddModemDataINGSM/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const SyncScriptIdWiseTableColumn = async (info) => {
    try {
        const res = await axios.post(`${url}/SyncScriptIdWiseTableColumn`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MeterConfigTableCreate = async (info) => {
    try {
        const res = await axios.post(`${url}/MeterConfigTableCreate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LoraMeterConfigTableCreate = async (info) => {
    try {
        const res = await axios.post(`${url}/LoraMeterConfigTableCreate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const geModemIdWiseColumnName = async (id) => {
    try {
        const res = await axios.post(`${url}/geModemIdWiseColumnName/${id}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConsumerOldHistoryTable = async (id) => {
    try {
        const res = await axios.get(`${url}/getConsumerOldHistoryTable/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetTableNameWiseChartInRelayonTime = async (info) => {
    try {
        const res = await axios.post(`${url}/GetTableNameWiseChartInRelayonTime`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetTableNameWiseChartInRelayonTimeByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetTableNameWiseChartInRelayonTimeByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionCMD_IDANDTYPEWiseData = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionCMD_IDANDTYPEWiseData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getConfigretionLetestDataByCmd_id = async (info) => {
    try {
        const res = await axios.post(`${url}/getConfigretionLetestDataByCmd_id`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getIMEIWiseModemColumnName = async (IMEI) => {
    try {
        const res = await axios.get(`${url}/getIMEIWiseModemColumnName/${IMEI}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const geScriptSRNOWiseColumnName = async (ID) => {
    try {
        const res = await axios.get(`${url}/geScriptSRNOWiseColumnName/${ID}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetReportType = async (info) => {
    try {
        const res = await axios.post(`${url}/GetReportType`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const SAP_GSM_Billing_Report = async (info) => {
    try {
        const res = await axios.post(`${url}/report/SAP_GSM_Billing_Report`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GET_GSM_Hourly_Report = async (info) => {
    try {
        const res = await axios.post(`${url}/report/GET_GSM_Hourly_Report`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Generate_All_GSM_Consumer_Report = async (info) => {
    try {
        const res = await axios.post(`${url}/report/Generate_All_GSM_Consumer_Report`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Generate_All_Lorawan_Consumer_Report = async (info) => {
    try {
        const res = await axios.post(`${url}/Generate_All_Lorawan_Consumer_Report`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GET_SAP_Lora_Billing_Report = async (info) => {
    try {
        const res = await axios.post(`${url}/report/SAP_LORA_Billing_Report`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GET_Lora_Hourly_Report = async (info) => {
    try {
        const res = await axios.post(`${url}/report/GET_Lora_Hourly_Report`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getLoraReportBytypeName = async (UserId, roleId, type, info) => {
    try {
        const res = await axios.get(`${url}/getLoraReportBytypeName/${UserId}?type=${type}&roleId=${roleId}&start=${info?.start}&end=${info?.end}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const geturls3 = async (info) => {
    try {
        const res = await axios.post(`${url}/geturls3`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMSSQLlogs = async (info) => {
    try {
        const res = await axios.post(`${url}/getMSSQLlogs`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetMssqlCardDetails = async () => {
    try {
        const res = await axios.get(`${url}/GetMssqlCardDetails`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Get_consumer_data = async (info) => {
    try {
        const res = await axios.post(`${url}/Get_consumer_data`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetMssqlCardDetailsById = async (id) => {
    try {
        const res = await axios.get(`${url}/GetMssqlCardDetailsById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMDataFilterDataIntegration = async (info) => {
    try {
        const res = await axios.post(`${url}/getGSMDataFilterDataIntegration`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insert_consumer_data = async (info) => {
    try {
        const res = await axios.post(`${url}/Insert_consumer_data`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterGSMChartByDateSlection = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterGSMChartByDateSlection`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterDashGSMInstantaneousChartBySelectDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterDashGSMInstantaneousChartBySelectDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getMeterLoraChartByDateSlection = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterLoraChartByDateSlection`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_Country = async (info) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_Country`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_State_by_Country = async (id) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_State_by_Country/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Table_Get_All_City_by_State = async (id) => {
    try {
        const res = await axios.get(`${url}/Table_Get_All_City_by_State/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MeterDailyDataScripUpdate = async (id) => {
    try {
        const res = await axios.get(`${url}/MeterDailyDataScripUpdate`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MonitoringScriptStatus = async (id) => {
    try {
        const res = await axios.get(`${url}/MonitoringScriptStatus`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetLetestTime = async (id) => {
    try {
        const res = await axios.get(`${url}/onGetLetestTime`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LoraMeterDailyDataScripUpdate = async (id) => {
    try {
        const res = await axios.get(`${url}/LoraMeterDailyDataScripUpdate`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LoraMonitoringScriptStatus = async (id) => {
    try {
        const res = await axios.get(`${url}/LoraMonitoringScriptStatus`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetLoradashboardLetestTime = async (id) => {
    try {
        const res = await axios.get(`${url}/onGetLoradashboardLetestTime`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const RegisterApiRequest = async (Apidata) => {
    console.log("apidata=", Apidata)
    try {
        const res = await axios.post(`${url}/registerAPI`, Apidata, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}
export const GetRegisteredApi = async (userId) => {
    // console.log("apidata=", Apidata)
    try {
        const res = await axios.post(`${url}/GetRegisteredApi`, userId, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const DeleteTable = async (userId) => {
    // console.log("apidata=", Apidata)
    try {
        const res = await axios.post(`${url}/DeleteTable`, userId, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetRegisteredApiByUNIQUE_ID = async (id) => {
    try {
        const res = await axios.get(`${url}/GetRegisteredApiByUNIQUE_ID/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getApiHistory = async (info) => {
    // console.log("apidata=", Apidata)
    try {
        const res = await axios.post(`${url}/getApiHistory`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetHourlyDataByEpoch = async (info) => {
    // console.log("apidata=", Apidata)
    try {
        const res = await axios.post(`${url}/onGetHourlyDataByEpoch`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getLetestHeatbeatData = async (info) => {
    // console.log("apidata=", Apidata)
    try {
        const res = await axios.post(`${url}/getLetestHeatbeatData`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTableInfo = async () => {
    try {
        const res = await axios.get(`${url}/getAllTableInfo`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInGSMMeterData = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateInGSMMeterData/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFilteredTaskDetails = async (filter) => {
    console.log("Filters Util = ", filter)
    try {
        const res = await axios.post(`${url}/getFilteredTaskDetails`, filter, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsersListForTask = async () => {
    try {
        const res = await axios.get(`${url}/getUsersListForTask`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskTag = async (status) => {
    try {
        const res = await axios.get(`${url}/getTaskTag?FilterStatus=${status}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const insertTaskTag = async (data) => {
    try {
        const res = await axios.post(`${url}/insertTaskTag`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateTaskTag = async (data) => {
    try {
        const res = await axios.post(`${url}/updateTaskTag`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFilteredConsumerTaskDetails = async (filter) => {
    console.log("Consumer Filters Util = ", filter)
    try {
        const res = await axios.post(`${url}/getFilteredConsumerTaskDetails`, filter, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getAttachmentList = async (data) => {
    try {
        const res = await axios.post(`${url}/getAttachmentList`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const syncFilesToDatabase = async (data) => {
    try {
        const res = await axios.post(`${url}/syncFilesToDatabase`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMechanicalMeterClass = async () => {
    try {
        const res = await axios.get(`${url}/getMechanicalMeterClass`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMechanicalMeter = async () => {
    try {
        const res = await axios.get(`${url}/getAllMechanicalMeter`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMechanicalMeterType = async () => {
    try {
        const res = await axios.get(`${url}/getMechanicalMeterType`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMechanicalMeterBrand = async () => {
    try {
        const res = await axios.get(`${url}/getMechanicalMeterBrand`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMechanicalMeterManufacturer = async () => {
    try {
        const res = await axios.get(`${url}/getMechanicalMeterManufacturer`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMechanicalMeterModalNo = async () => {
    try {
        const res = await axios.get(`${url}/getMechanicalMeterModalNo`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMechanicalMeterCategory = async () => {
    try {
        const res = await axios.get(`${url}/getMechanicalMeterCategory`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmechanical_meter_class = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmechanical_meter_class`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemechanical_mmeter_class = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemechanical_mmeter_class/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmechanical_meter_type = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmechanical_meter_type`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemechanical_meter_type = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemechanical_meter_type/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmechanical_meter_brand = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmechanical_meter_brand`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemechanical_meter_brand = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemechanical_meter_brand/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmechanical_meter_category = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmechanical_meter_category`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemechanical_meter_category = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemechanical_meter_category/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmechanical_meter_modalNumber = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmechanical_meter_modalNumber`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemechanical_meter_modalNumber = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemechanical_meter_modalNumber/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertmechanical_meter_manufacturer = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmechanical_meter_manufacturer`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemechanical_meter_manufacturer = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemechanical_meter_manufacturer/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const Insertmechanical_meter_index = async (info) => {
    try {
        const res = await axios.post(`${url}/Insertmechanical_meter_index`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Updatemechanical_meter_index = async (info, id) => {
    try {
        const res = await axios.put(`${url}/Updatemechanical_meter_index/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterByMultipalID = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterByMultipalID`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMechanicalByMultipalID = async (info) => {
    try {
        const res = await axios.post(`${url}/getMechanicalByMultipalID`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMechanicalByID = async (id) => {
    try {
        const res = await axios.get(`${url}/getMechanicalByID/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AddMechanicalDataINGSM = async (info,id) => {
    try {
        const res = await axios.post(`${url}/AddMechanicalDataINGSM/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateLorawanConsumerStatusInMaintanace = async (id) => {
    try {
        const res = await axios.get(`${url}/UpdateLorawanConsumerStatusInMaintanace/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const CommandGenerate = async (info) => {
    try {
        const res = await axios.post(`${url}/CommandGenerate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const LoraCommandGenerate = async (info) => {
    try {
        const res = await axios.post(`${url}/LoraCommandGenerate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getReportDataInConsumerDetails = async (info) => {
    try {
        const res = await axios.post(`${url}/getReportDataInConsumerDetails`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterDashGSMBrtRtcDriffChart = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterDashGSMBrtRtcDriffChart`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterDashGSMBrtRtcDriffChartSelectByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterDashGSMBrtRtcDriffChartSelectByDate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getPenddingqueueCommandInBuffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getPenddingqueueCommandInBuffer`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getPenddingqueueCommandInLoraBuffer = async (info) => {
    try {
        const res = await axios.post(`${url}/getPenddingqueueCommandInLoraBuffer`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const saveFilterToDb = async (info) => {
    try {
        const res = await axios.post(`${url}/saveFilterToDb`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFavoriteFilters = async (userId) => {
    console.log("userId", userId)
    try {
        const getFilters = await axios.get(`${url}/getFavoriteFilters/${userId}`, config())
        return { success: true, data: getFilters.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertApiSchedule = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertApiSchedule`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateApiSchedule = async (info,ID) => {
    try {
        const res = await axios.post(`${url}/UpdateApiSchedule/${ID}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScheduleList = async (info) => {
    try {
        const res = await axios.post(`${url}/getScheduleList`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetRSSIValue = async (info) => {
    try {
        const res = await axios.get(`${url}/GetRSSIValue`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateApiScheduleStatus = async (info,ID) => {
    try {
        const res = await axios.post(`${url}/UpdateApiScheduleStatus/${ID}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InstanceScheduleInPush = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/InstanceScheduleInPush/${tablenme}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterDashGSMTemperCount = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/getMeterDashGSMTemperCount`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMapDataInGSMmapViewByFilter = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/getAllMapDataInGSMmapViewByFilter`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMConsumptionChartByDate = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/getAllGSMConsumptionChartByDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMConsumptionChartBySelectDate = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/getAllGSMConsumptionChartBySelectDate`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMechanicalMeterTotalGSM = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/getAllMechanicalMeterTotalGSM`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Insertgsm_offline_otp = async (data) => {
    try {
        const res = await axios.post(`${url}/Insertgsm_offline_otp`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getgsm_offline_otp = async (info) => {
    try {
        const res = await axios.get(`${url}/getgsm_offline_otp/${info}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateConsumerStatusHistory = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateConsumerStatusHistory/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetConsumerStatusHistoryByConsumerId = async (id) => {
    try {
        const res = await axios.get(`${url}/GetConsumerStatusHistoryByConsumerId/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateunlockConfig = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateunlockConfig/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getgsm_unlock_activity = async (info) => {
    try {
        const res = await axios.get(`${url}/getgsm_unlock_activity/${info}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getUnlockGSMConsumer = async (info,id) => {
    try {
        const res = await axios.post(`${url}/getUnlockGSMConsumer`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getHeartbeatStatusByDate = async (info,id) => {
    try {
        const res = await axios.post(`${url}/getHeartbeatStatusByDate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getlora_unlock_activity = async (info,id) => {
    try {
        const res = await axios.get(`${url}/getlora_unlock_activity/${info}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateLoraunlockConfig = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateLoraunlockConfig/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const ChangeStatusToPendding = async (id) => {
    try {
        const res = await axios.get(`${url}/ChangeStatusToPendding/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getlogsDataByColumnName = async (info,id) => {
    try {
        const res = await axios.post(`${url}/getlogsDataByColumnName`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmWebConfigDetails = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateGsmWebConfigDetails/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const ActivityLogMaintainOnFrontend = async (info,id) => {
    try {
        const res = await axios.post(`${url}/ActivityLogMaintainOnFrontend`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getActivityLogs = async (info,id) => {
    try {
        const res = await axios.post(`${url}/getActivityLogs`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUnlockLORAConsumer = async (info,id) => {
    try {
        const res = await axios.post(`${url}/getUnlockLORAConsumer`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMapDataInLoramapViewByFilter = async (info,id) => {
    try {
        const res = await axios.post(`${url}/getAllMapDataInLoramapViewByFilter`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGroups = async (info,user_id,role,cat0_id) => {
    try {
        const res = await axios.post(`${url}/getAllGroups?user_id=${user_id}&role=${role}&cat0_id=${cat0_id}`,{}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const createGroup = async (info) => {
    try {
        const res = await axios.post(`${url}/createGroup`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateGroup = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateGroup/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateUserGroupJunction = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateUserGroupJunction/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsersByGroups = async (id) => {
    try {
        const res = await axios.post(`${url}/getUsersByGroups/${id}`,{}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getNotificationList=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getNotificationList?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getnotification_receiver=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getnotification_receiver?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getalarm_template=async(info,data)=>{
    try {
const res=await axios.post(`${url}/getalarm_template?start=${info?.start}&end=${info.end}`,{},config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const getAll_Active_alarm_template=async(info,data)=>{
    try {
const res=await axios.get(`${url}/getAll_Active_alarm_template`,config())
return { success: true, data: res.data }
    }catch (e) {
        return { success: false, msg: "Something went wrong",err:e};
    }
}

export const InsertNotificationReceiver = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertNotificationReceiver`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const UpdateNotificationReceiver = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateNotificationReceiver/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onTrrigerCron = async (data,id) => {
    try {
        const res = await axios.get(`${url}/onTrrigerCron`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScheduleLogHistory = async (data) => {
    try {
        const res = await axios.post(`${url}/getScheduleLogHistory`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const get_Instantaneous_Data_Refresh = async (data) => {
    try {
        const res = await axios.get(`${url}/get_Instantaneous_Data_Refresh`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateLoraWebConfigDetails = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateLoraWebConfigDetails/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const get_notification_script = async (data,id) => {
    try {
        const res = await axios.post(`${url}/get_notification_script`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllLoraConsumptionChartByDate = async (data,id) => {
    try {
        const res = await axios.post(`${url}/getAllLoraConsumptionChartByDate`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const lora_monitor_day_wise_graph_details = async (data,id) => {
    try {
        const res = await axios.post(`${url}/lora_monitor_day_wise_graph_details`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemConsumerDetailsLora = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemConsumerDetailsLora`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemConsumerDetailsByFilterLora = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemConsumerDetailsByFilterLora`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const DownloadModemConsumerDetailsByFilterLora = async (info) => {
    try {
        const res = await axios.post(`${url}/DownloadModemConsumerDetailsByFilterLora`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertModemDetailsLora = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertModemDetailsLora`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateModemDetailsLora = async (info, id) => {
    try {
        const res = await axios.post(`${url}/UpdateModemDetailsLora/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetModemTypeWiseIMEILora = async (id) => {
    try {
        const res = await axios.get(`${url}/GetModemTypeWiseIMEILora/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetIMEIWiseModemDataLora = async (id) => {
    try {
        const res = await axios.get(`${url}/GetIMEIWiseModemDataLora/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getModemSearchTextConsumerDetailsLora = async (info) => {
    try {
        const res = await axios.post(`${url}/getModemSearchTextConsumerDetailsLora`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraCountUseToConsumerInEUI = async (id, update) => {
    try {
        const res = await axios.get(`${url}/getLoraCountUseToConsumerInEUI/${id}?update=${update?.update}&ID=${update?.ID}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertLora_offline_otp = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertLora_offline_otp`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLora_offline_otp = async (info) => {
    try {
        const res = await axios.get(`${url}/getLora_offline_otp/${info}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllLoraConsumptionChartSelectedDate = async (data) => {
    try {
        const res = await axios.post(`${url}/getAllLoraConsumptionChartSelectedDate`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const createWebTab = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/createWebTab`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllWebTabs = async (info,tablenme) => {
    try {
        const res = await axios.post(`${url}/getAllWebTabs`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateWebTabs = async (info,id) => {
    try {
        const res = await axios.post(`${url}/updateWebTabs/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllTaskAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllTaskAnalytics`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const rebrandingCompanyPhotos = async (info) => {
    try {
        const res = await axios.post(`${url}/rebrandingCompanyPhotos`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateLoraConsumerStatusHistory = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateLoraConsumerStatusHistory/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetLoraConsumerStatusHistoryByConsumerId = async (id) => {
    try {
        const res = await axios.get(`${url}/GetLoraConsumerStatusHistoryByConsumerId/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScheduleActivityLogs = async (data) => {
    try {
        const res = await axios.post(`${url}/getScheduleActivityLogs`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const badDataAnalytics = async (data) => {
    try {
        const res = await axios.post(`${url}/badDataAnalytics`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllCategoryTableData = async (data) => {
    try {
        const res = await axios.post(`${url}/getAllCategoryTableData`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const TaskActivityLogMaintainOnFrontend = async (data) => {
    try {
        const res = await axios.post(`${url}/TaskActivityLogMaintainOnFrontend`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskActivityLogs = async (data) => {
    try {
        const res = await axios.post(`${url}/getTaskActivityLogs`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllEmployees = async () => {
    try {
        const res = await axios.get(`${url}/getAllEmployees`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getFiles = async () => {
    try {
        const res = await axios.get(`${url}/getFiles`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getResourceFileUrl = async (data) => {
    try {
        const res = await axios.post(`${url}/getResourceFileUrl`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const uploadFile = async (data) => {
    try {
        const res = await axios.post(`${url}/uploadFile`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateFileVersion = async (data) => {
    try {
        const res = await axios.put(`${url}/updateFileVersion`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateFile = async (data) => {
    try {
        const res = await axios.put(`${url}/updateFile`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLatestFile = async () => {
    try {
        const res = await axios.get(`${url}/getLatestFile`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getResourcesByUserAndPermission = async (status) => {
    try {
        const res = await axios.get(`${url}/getResourcesByUserAndPermission/${status}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getResourcesByTab = async () => {
    try {
        const res = await axios.get(`${url}/getResourcesByTab`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDepartmentUsers = async () => {
    try {
        const res = await axios.get(`${url}/getDepartmentUsers`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getActiveResourceTags = async () => {
    try {
        const res = await axios.get(`${url}/getActiveResourceTags`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const createResource = async (data) => {
    try {
        const res = await axios.post(`${url}/createResource`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}


export const getResourcesByTags = async (data) => {
    try {
        const res = await axios.post(`${url}/getResourcesByTags`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDepartmentsForTask = async () => {
    try {
        const res = await axios.get(`${url}/getDepartmentsForTask`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const createResourceTag = async (data) => {
    try {
        const res = await axios.post(`${url}/createResourceTag`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateResource = async (data) => {
    try {
        const res = await axios.post(`${url}/updateResource`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllResourceTags = async () => {
    try {
        const res = await axios.get(`${url}/getResourceTags`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const updateResourceTag = async (data) => {
    try {
        const res = await axios.post(`${url}/updateResourceTag`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetConsumerIncolumeNameWithFilter = async (data) => {
    try {
        const res = await axios.post(`${url}/onGetConsumerIncolumeNameWithFilter`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetLoraConsumerIncolumeNameWithFilter = async (data) => {
    try {
        const res = await axios.post(`${url}/onGetLoraConsumerIncolumeNameWithFilter`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMetertype = async (info) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMetertype`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmSmartMetertype = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGsmSmartMetertype`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmSmartMetertype = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdateGsmSmartMetertype/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMeterfirmware = async (info) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMeterfirmware`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmSmartMeterfirmware = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGsmSmartMeterfirmware`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmSmartMeterfirmware = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdateGsmSmartMeterfirmware/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMetercategory = async (info) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMetercategory`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmSmartMetercategory = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGsmSmartMetercategory`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmSmartMetercategory = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdateGsmSmartMetercategory/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMeterclass = async (info) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMeterclass`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmSmartMeterclass = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGsmSmartMeterclass`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmSmartMeterclass = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdateGsmSmartMeterclass/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMetershoftwareProtocol = async (info) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMetershoftwareProtocol`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmSmartMetershoftwareProtocol = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGsmSmartMetershoftwareProtocol`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmSmartMetershoftwareProtocol = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdateGsmSmartMetershoftwareProtocol/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMeterHardwareVersion = async (info) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMeterHardwareVersion`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmSmartMeterHardwareVersion = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGsmSmartMeterHardwareVersion`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmSmartMeterHardwareVersion = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdateGsmSmartMeterHardwareVersion/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMeterMake = async (info) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMeterMake`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmSmartMeterMake = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGsmSmartMeterMake`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmSmartMeterMake = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdateGsmSmartMeterMake/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMeterModelNumber = async (info) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMeterModelNumber`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmSmartMeterModelNumber = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGsmSmartMeterModelNumber`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmSmartMeterModelNumber = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdateGsmSmartMeterModelNumber/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMSmartMeter = async () => {
    try {
        const res = await axios.get(`${url}/getGSMSmartMeter`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGSMSmartMeterIndex = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGSMSmartMeterIndex`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdatGSMSmartMeterIndex = async (data,id) => {
    try {
        const res = await axios.put(`${url}/UpdatGSMSmartMeterIndex/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGSMSmartMeterDriverAttachment = async (id,data) => {
    try {
        const res = await axios.put(`${url}/UpdateGSMSmartMeterDriverAttachment/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const get_gsm_smart_meter_driver_index = async (id, info) => {
    try {
        const res = await axios.get(`${url}/get_gsm_smart_meter_driver_index/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMSmartMeterData = async (info, data) => {
    try {
        console.log(data);
        const res = await axios.get(`${url}/getGSMSmartMeterData?start=${info?.start}&end=${info.end}`, {
            method: 'GET',
            params: data,
            headers: {
                Authorization: window.localStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGSMSmartMeterData = async (data) => {
    try {
        const res = await axios.post(`${url}/InsertGSMSmartMeterData`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmGSMSmartMeterFilterData = async (info,data) => {
    try {
        const res = await axios.post(`${url}/gsmGSMSmartMeterFilterData?start=${info?.start}&end=${info.end}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMSmartMeterFilterDataById = async (id) => {
    try {
        const res = await axios.get(`${url}/getGSMSmartMeterFilterDataById/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateInGSMSmartMetermodem = async (data,id) => {
    try {
        const res = await axios.post(`${url}/UpdateInGSMSmartMetermodem/${id}`,data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMSmartMeterByID = async (id) => {
    try {
        const res = await axios.get(`${url}/getGSMSmartMeterByID/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMeterWiseColumnName = async (id) => {
    try {
        const res = await axios.get(`${url}/getGsmSmartMeterWiseColumnName/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmSmartMeterTableData = async (info, data) => {
    try {
        const res = await axios.post(`${url}/getGsmSmartMeterTableData?start=${info?.start}&end=${info.end}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AddMechanicalDataINLora = async (data, id) => {
    try {
        const res = await axios.post(`${url}/AddMechanicalDataINLora/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMechanicalMeterTotalLora = async (data, id) => {
    try {
        const res = await axios.post(`${url}/getAllMechanicalMeterTotalLora`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const gsmSmartMeterSerchData = async (data, id) => {
    try {
        const res = await axios.post(`${url}/gsmSmartMeterSerchData`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMSmartMeterCountStatusByGa = async (data, id) => {
    try {
        const res = await axios.post(`${url}/getGSMSmartMeterCountStatusByGa`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllMeterTotalGSMSmartMeter = async (data, id) => {
    try {
        const res = await axios.post(`${url}/getAllMeterTotalGSMSmartMeter`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMSmartMeterTagsWiseConsumerData = async (data, id) => {
    try {
        const res = await axios.post(`${url}/getGSMSmartMeterTagsWiseConsumerData`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetGSMSmartMeterConsumerIncolumeName = async (data, id) => {
    try {
        const res = await axios.post(`${url}/onGetGSMSmartMeterConsumerIncolumeName`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetGSMSmartMeterConsumerDetailsByColumn = async (data, id) => {
    try {
        const res = await axios.post(`${url}/onGetGSMSmartMeterConsumerDetailsByColumn`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTagsInGsmSmartMeter = async (data, id) => {
    try {
        const res = await axios.post(`${url}/getTagsInGsmSmartMeter`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateTagsInGsmSmartMeter = async (data, id) => {
    try {
        const res = await axios.post(`${url}/UpdateTagsInGsmSmartMeter/${id}`, data, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDashboardDBTableInfo = async () => {
    try {
        const res = await axios.get(`${url}/getDashboardDBTableInfo`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMyStatusAnalytics = async (id) => {
    try {
        const res = await axios.post(`${url}/getMyStatusAnalytics`,id, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGsmCustomDashboard = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertGsmCustomDashboard`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGsmCustomDashboard = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateGsmCustomDashboard/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGsmCustomDashboadList = async () => {
    try {
        const res = await axios.post(`${url}/getGsmCustomDashboadList`,{}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateDashboardAddCustomCard = async (info) => {
    try {
        const res = await axios.post(`${url}/UpdateDashboardAddCustomCard`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSaleSummaryTableData = async (info) => {
    try {
        const res = await axios.post(`${url}/getSaleSummaryTableData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMHourlyConsumptionChartByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMHourlyConsumptionChartByDate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLorawanDataAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getLorawanDataAnalytics`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MeterHourlyDataScripUpdate = async (info) => {
    try {
        const res = await axios.post(`${url}/MeterHourlyDataScripUpdate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const HourlyMonitoringScriptStatus = async (info) => {
    try {
        const res = await axios.get(`${url}/HourlyMonitoringScriptStatus`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetHourlyLetestTime = async (info) => {
    try {
        const res = await axios.get(`${url}/onGetHourlyLetestTime`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getColumnWiseMeterDataInConsumer = async (info) => {
    try {
        const res = await axios.post(`${url}/getColumnWiseMeterDataInConsumer`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertLoraCustomDashboard = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertLoraCustomDashboard`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateLoraCustomDashboard = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateLoraCustomDashboard/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraCustomDashboadList = async () => {
    try {
        const res = await axios.post(`${url}/getLoraCustomDashboadList`,{}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateLoraDashboardAddCustomCard = async (info) => {
    try {
        const res = await axios.post(`${url}/UpdateLoraDashboardAddCustomCard`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLorawanSaleSummaryTableData = async (info) => {
    try {
        const res = await axios.post(`${url}/getLorawanSaleSummaryTableData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getSalesSummaryTableData = async (info) => {
    try {
        const res = await axios.post(`${url}/getSalesSummaryTableData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllLoraHourlyConsumptionChartByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllLoraHourlyConsumptionChartByDate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const MeterLoraHourlyDataScripUpdate = async (info) => {
    try {
        const res = await axios.post(`${url}/MeterLoraHourlyDataScripUpdate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const HourlyLoraMonitoringScriptStatus = async (info) => {
    try {
        const res = await axios.post(`${url}/HourlyLoraMonitoringScriptStatus`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const onGetLoraHourlyLetestTime = async (info) => {
    try {
        const res = await axios.post(`${url}/onGetLoraHourlyLetestTime`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterGSMDataReceptionDateWise = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterGSMDataReceptionDateWise`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getMeterLoraDataReceptionDateWise = async (info) => {
    try {
        const res = await axios.post(`${url}/getMeterLoraDataReceptionDateWise`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AllConsumerDetailList = async (info, data) => {
    try {
        console.log(data);
        const res = await axios.get(`${url}/AllConsumerDetailList?start=${info?.start}&end=${info.end}`, {
            method: 'GET',
            params: data,
            headers: {
                Authorization: window.localStorage.getItem('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        })
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UserDashboardCardFilterStore = async (info) => {
    try {
        const res = await axios.post(`${url}/UserDashboardCardFilterStore`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const DashboardCardFilterGetTypeWise = async (type,id) => {
    try {
        const res = await axios.get(`${url}/DashboardCardFilterGetTypeWise/${type}/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AllConsumerSearchList = async (info) => {
    try {
        const res = await axios.post(`${url}/AllConsumerSearchList`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCommandGenrateCountInNotification = async (info) => {
    try {
        const res = await axios.get(`${url}/getCommandGenrateCountInNotification`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGeneretDiagnosticReport = async (info) => {
    try {
        const res = await axios.post(`${url}/getGeneretDiagnosticReport`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraGeneretDiagnosticReport = async (info) => {
    try {
        const res = await axios.post(`${url}/getLoraGeneretDiagnosticReport`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateBulkGsmColumn = async (info) => {
    try {
        const res = await axios.post(`${url}/UpdateBulkGsmColumn`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLoraConsumerOldHistoryTable = async (id) => {
    try {
        const res = await axios.get(`${url}/getLoraConsumerOldHistoryTable/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getEUIWiseModemColumnName = async (id) => {
    try {
        const res = await axios.get(`${url}/getEUIWiseModemColumnName/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateBulkLoraColumn = async (info) => {
    try {
        const res = await axios.post(`${url}/UpdateBulkLoraColumn`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAssignedToMeTodoAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getAssignedToMeTodoAnalytics`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getDueDateWiseAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getDueDateWiseAnalytics`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetGSMStatusUndermaintainaceGraphData = async (info) => {
    try {
        const res = await axios.post(`${url}/GetGSMStatusUndermaintainaceGraphData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScriptList = async () => {
    try {
        const res = await axios.get(`${url}/getScriptList`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScriptRunHistory = async (info) => {
    try {
        const res = await axios.post(`${url}/getScriptRunHistory`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetLoraStatusUndermaintainaceGraphData = async (info) => {
    try {
        const res = await axios.post(`${url}/GetLoraStatusUndermaintainaceGraphData`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getHeatbeatLatestVDID = async (info) => {
    try {
        const res = await axios.post(`${url}/getHeatbeatLatestVDID`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Schedule_Base_Selles_statistics_Notification = async () => {
    try {
        const res = await axios.get(`${url}/Schedule_Base_Selles_statistics_Notification`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const InsertGSMReportScheduler = async (info) => {
    try {
        const res = await axios.post(`${url}/InsertGSMReportScheduler`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateGSMReportScheduler = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateGSMReportScheduler/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getgsmReportSchedule = async (range,info) => {
    try {
        const res = await axios.post(`${url}/getgsmReportSchedule?start=${range?.start}&end=${range?.end}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const Update_Gsm_Smart_consumer_Attachments = async (info,id) => {
    try {
        const res = await axios.post(`${url}/Update_Gsm_Smart_consumer_Attachments/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetCountForMissingDataUserAndRoles = async (info) => {
    try {
        const res = await axios.post(`${url}/GetCountForMissingDataUserAndRoles`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetCountUseAndRolesDataAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/GetCountUseAndRolesDataAnalytics`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GetSmartMeterConsumptionWiseChartDataByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/GetSmartMeterConsumptionWiseChartDataByDate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScheduleAnalyticsList = async (info) => {
    try {
        const res = await axios.post(`${url}/getScheduleAnalyticsList`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateApiScheduleReportStatus = async (info,id) => {
    try {
        const res = await axios.post(`${url}/UpdateApiScheduleReportStatus/${id}`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getgsmReportActivityLogs = async (info) => {
    try {
        const res = await axios.post(`${url}/getgsmReportActivityLogs`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getReportScheduleCount = async (UserId, roleId,SCHEDULE_ID) => {
    try {
        const res = await axios.get(`${url}/getReportScheduleCount/${UserId}?roleId=${roleId}&SCHEDULE_ID=${SCHEDULE_ID}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getGSMSmartMeterReportBytypeName = async (UserId, roleId, type, info) => {
    try {
        const res = await axios.get(`${url}/getGSMSmartMeterReportBytypeName/${UserId}?type=${type}&roleId=${roleId}&start=${info?.start}&end=${info?.end}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const GET_GSM_Smart_Meter_Report = async (info) => {
    try {
        const res = await axios.post(`${url}/GET_GSM_Smart_Meter_Report`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTaskAssignedToMeAnalytics = async (info) => {
    try {
        const res = await axios.post(`${url}/getTaskAssignedToMeAnalytics`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getTasAnalyticsGroupWise = async (info) => {
    try {
        const res = await axios.post(`${url}/getTasAnalyticsGroupWise`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const TaskReadStatusChange = async (info, id) => {
    try {
        const res = await axios.post(`${url}/TaskReadStatusChange/${id}`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGroupsOfUser = async (id) => {
    try {
        const res = await axios.post(`${url}/getAllGroupsOfUser/${id}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllUsersOfGroup = async (id) => {
    try {
        const res = await axios.post(`${url}/getAllUsersOfGroup/${id}`, {}, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const sentReportFilestoMail = async (info) => {
    try {
        const res = await axios.post(`${url}/sentReportFilestoMail`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScheduleAnalyticsByDate = async (info) => {
    try {
        const res = await axios.post(`${url}/getScheduleAnalyticsByDate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getScheduleAnalyticsOnClick = async (info) => {
    try {
        const res = await axios.post(`${url}/getScheduleAnalyticsOnClick`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const RunScheduleMonitoringscript = async () => {
    try {
        const res = await axios.get(`${url}/RunScheduleMonitoringscript`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const scheduleApiPushmonitoringScriptStatus = async () => {
    try {
        const res = await axios.get(`${url}/scheduleApiPushmonitoringScriptStatus`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const ValidateReportToken = async (token) => {
    try {
        const res = await axios.get(`${url}/ValidateReportToken/${token}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const triggerManualReportGenerate = async (info) => {
    try {
        const res = await axios.post(`${url}/triggerManualReportGenerate`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUsersListByCategory0 = async (info,user_id,role,type,cat0_id) => {
    try {
        const res = await axios.get(`${url}/getUsersListByCategory0?user_id=${user_id}&role=${role}&type=${type}&cat0_id=${cat0_id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const AllScriptRestart = async () => {
    try {
        const res = await axios.get(`${url}/AllScriptRestart`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const UpdateModemFirmwarOTA = async (info) => {
    try {
        const res = await axios.post(`${url}/UpdateModemFirmwarOTA`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getLastGSMOTAData = async (id) => {
    try {
        const res = await axios.get(`${url}/getLastGSMOTAData/${id}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getCountByLastHeartBeatts = async (info) => {
    try {
        const res = await axios.post(`${url}/getCountByLastHeartBeatts`,info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getUserModuleSettings = async (info) => {
    try {
        const res = await axios.get(`${url}/getUserModuleSettings?moduleName=${info?.moduleName}&settingName=${info?.settingName}`, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const insertUserModuleSettings = async (info) => {
    try {
        const res = await axios.post(`${url}/insertUserModuleSettings`, info, config())
        return { success: true, data: res.data, msg: res?.data?.message }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}

export const getAllGSMConsumerCount = async (info) => {
    try {
        const res = await axios.post(`${url}/getAllGSMConsumerCount`, info, config())
        return { success: true, data: res.data }
    } catch (e) {
        return { success: false, msg: "Something went wrong", err: e };
    }
}